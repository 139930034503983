import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import toast from "react-hot-toast";
import { AutocompleteField, DatePickerField } from "components/UI/Form";
import * as casesApi from "../api/casesApi";
import dayjs from "dayjs";
import * as Yup from "yup";
import { FiCopy } from "react-icons/fi";

import styles from "./index.module.css";
import { WorkPacketDisputesInfo, WorkPacketFilters } from "src/types/work-packets";
import { CaseType, CaseTypeLabelMap } from "../../WorkPackets/Cases/case-types";
import { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType.ts";
import { useQueryClient } from "@tanstack/react-query";
import CaseTextInfo from "src/pages/UserDashboard/Cases/CreateCasePopup/CaseTextInfo.tsx";

interface CreateCasePopupProps {
  open: boolean;
  onClose: (success?: boolean) => void;
  selectedWorkPacketsIds: string[];
  excludedWorkPacketsIds: string[];
  filters: WorkPacketFilters;
  prepopulatedCaseType?: CaseType;
  workPacketType: WorkPacketType;
}

const validationSchema = Yup.object().shape({
  caseId: Yup.string().required("Case Id is required"),
  caseType: Yup.string().required("Case type is required"),
  caseCreatedAt: Yup.object().required("Case Created Date is required"),
});

export const CreateCasePopup: FC<CreateCasePopupProps> = ({
  open,
  onClose,
  selectedWorkPacketsIds,
  excludedWorkPacketsIds,
  filters,
  prepopulatedCaseType,
  workPacketType,
}) => {
  const queryClient = useQueryClient();

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDisputes, setLoadingDisputes] = useState<boolean>(false);
  const [workPacketDisputesInfo, setWorkPacketDisputesInfo] = useState<WorkPacketDisputesInfo | null>(null);
  const initialValues = {
    caseId: "",
    caseType:
      workPacketType === WorkPacketType.CHARGEBACKS
        ? CaseType.CHARGEBACK_ESCALATION
        : (prepopulatedCaseType ?? CaseType.SHORTAGE_ESCALATION),
    caseCreatedAt: dayjs(),
    caseTextTitle: "",
    caseTextBody: "",
  };
  const handleSubmit = async (values: {
    caseId: string;
    caseType: string;
    caseCreatedAt: dayjs.Dayjs | null;
    caseTextTitle: string;
    caseTextBody: string;
  }) => {
    setLoading(true);
    casesApi
      .createCase({
        caseId: values.caseId,
        caseType: values.caseType,
        caseCreatedAt: values.caseCreatedAt ? values.caseCreatedAt.format("YYYY-MM-DD") : "",
        workPacketIds: selectedWorkPacketsIds,
        excludedWorkPacketIds: excludedWorkPacketsIds,
        filters,
        workPacketType,
        caseTextTitle: values.caseTextTitle,
        caseTextBody: values.caseTextBody,
      })
      .then(() => {
        toast.success(`${CaseTypeLabelMap[values.caseType as CaseType]} case created successfully`);
        queryClient.invalidateQueries({ queryKey: ["work-packets", workPacketType] });
        queryClient.invalidateQueries({ queryKey: ["cases"] });
        onClose(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoadingDisputes(true);
    casesApi
      .getDisputesIdsAndVendorInfo({
        workPacketType,
        excludedWorkPackets: excludedWorkPacketsIds,
        filters: filters,
        workPacketIds: selectedWorkPacketsIds,
      })
      .then(data => setWorkPacketDisputesInfo(data))
      .catch(() => onClose(true))
      .finally(() => {
        setLoadingDisputes(false);
      });
  }, [selectedWorkPacketsIds, excludedWorkPacketsIds, filters, onClose, workPacketType]);

  if (loadingDisputes)
    return (
      <Dialog open={open} maxWidth="md" fullWidth>
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      </Dialog>
    );

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth="md" fullWidth>
      <DialogTitle>Attach a Case ID</DialogTitle>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ dirty, isValid }) => (
          <Form>
            <DialogContent>
              {workPacketDisputesInfo && (
                <Box paddingBottom={"1.5rem"}>
                  <ol
                    className={styles.logCaseSteps}
                    style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
                  >
                    {workPacketType === WorkPacketType.SHORTAGES && (
                      <>
                        <li>Select the Type of Case</li>
                        <Box marginY={".2rem"} maxWidth={250}>
                          <Field
                            name={"caseType"}
                            label="Case Type"
                            size="small"
                            fullwidth
                            component={AutocompleteField}
                            placeholder="Select a type of case"
                            getOptionLabel={(option: any) => option.title}
                            options={[
                              { value: "SHORTAGE_ESCALATION", title: "Shortage Escalation" },
                              { value: "SHORTAGE_SETTLEMENT", title: "Shortage Settlement" },
                            ]}
                          />
                        </Box>
                      </>
                    )}
                    <li>
                      Log into
                      <Button
                        className={styles.linkBtn}
                        href={"https://vendorcentral.amazon.com"}
                        target={"_blank"}
                        variant="text"
                      >
                        Amazon Vendor Central
                      </Button>
                      as Filling User: {workPacketDisputesInfo.manual_filing_user}
                    </li>
                    <li>
                      Go to{" "}
                      <Button
                        className={styles.linkBtn}
                        href={"https://vendorcentral.amazon.com/katalmonsapp/vendor/members/disputes/re/create"}
                        variant="text"
                        target={"_blank"}
                      >
                        Case Creation Page
                      </Button>
                    </li>
                    <li>Fill in the following dispute IDs:</li>
                    <OutlinedInput
                      size="small"
                      value={workPacketDisputesInfo.dispute_ids.join(", ")}
                      sx={{
                        minWidth: 250,
                      }}
                      endAdornment={
                        <InputAdornment position={"end"}>
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(workPacketDisputesInfo?.dispute_ids.join(", ") ?? "");
                              toast.success("Dispute IDs copied");
                            }}
                          >
                            <FiCopy />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {workPacketType === WorkPacketType.SHORTAGES && (
                      <CaseTextInfo workPacketDisputesInfo={workPacketDisputesInfo} />
                    )}
                  </ol>
                </Box>
              )}
              <hr className={styles.hr} />
              <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
                <Field
                  name={"caseId"}
                  label="Case ID"
                  as={TextField}
                  type="number"
                  size="small"
                  variant="outlined"
                  placeholder="Enter Case ID"
                />
                <Field
                  name={"caseCreatedAt"}
                  label="Case Created Date"
                  component={DatePickerField}
                  placeholder="Case Created Date"
                />
              </Box>
            </DialogContent>
            <DialogActions style={{ background: "transparent" }}>
              <Button variant="outlined" onClick={() => onClose()} disabled={loading}>
                Cancel {isValid}
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!dirty || !isValid || loading}
                sx={{ paddingX: "24px" }}
              >
                <span>Attach Case ID</span>
                {loading && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
