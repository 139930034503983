import { InputAdornment, TextField } from "@mui/material";
import { FC } from "react";
import { FieldProps, useFormikContext } from "formik";

export const CurrencyField: FC<FieldProps & React.ComponentProps<typeof TextField>> = ({ field, form, ...props }) => {
  const renderValue = field?.value === undefined || Number.isNaN(field?.value) ? "" : field.value;
  const { getFieldMeta } = useFormikContext();
  const { error } = getFieldMeta(field.name);

  return (
    <TextField
      {...field}
      type="number"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        inputProps: { step: 0.01 },
      }}
      value={renderValue}
      error={!!error}
      helperText={error}
      {...props}
    />
  );
};
