import { Field, useFormikContext } from "formik";
import { TextField } from "@mui/material";
import { FormFieldsGroup } from "src/pages/UserDashboard/WorkPackets/Cases/Components/FormFieldsGroup";
import { FC } from "react";
import { AutocompleteField, DatePickerField, FormTextField } from "components/UI/Form";
import { useUserOptions } from "src/pages/UserDashboard/WorkPackets/useUserOptions";
import { WorkPacketOwner } from "src/types/work-packets";
import {
  settableCaseStatuses,
  settlementCaseStagesMap,
} from "src/pages/UserDashboard/Cases/api/constants";
import usePermissions from "components/CustomHooks/usePermissions";
import { SettlementCase } from "src/types/cases";
import { CaseDetailsKeys } from "src/pages/UserDashboard/Cases/mappers/mapServerToLocalCase";
import { VcLinkButton } from "src/components/work-packets/VcLinkButton";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { useAuditAccountOptions } from "src/pages/UserDashboard/WorkPackets/WorkPacketsGrid/filters/hooks/useAuditAccountOptions";

export const CaseInfo: FC = () => {
  const userOptions = useUserOptions(true);
  const auditAccountOptions = useAuditAccountOptions();
  const { hasPermission } = usePermissions();
  const { setFieldValue, values } = useFormikContext<SettlementCase>();

  return (
    <FormFieldsGroup title="Case Info" id={"case-info"}>
      <Field
        name={CaseDetailsKeys.AmazonCaseId}
        component={FormTextField}
        variant="outlined"
        placeholder="Amazon Case ID"
        size="small"
        label="Amazon Case ID"
        disabled
      />
      <Field
        name={CaseDetailsKeys.RecoveryStream}
        component={FormTextField}
        variant="outlined"
        placeholder="Recovery Stream"
        size="small"
        label="Recovery Stream"
        disabled
      />
      <Field
        name={CaseDetailsKeys.RecoveryStreamActivationDate}
        component={FormTextField}
        variant="outlined"
        placeholder="Recovery Stream Activation Date"
        size="small"
        label="Recovery Stream Activation Date"
        disabled
      />
      <Field
        name={CaseDetailsKeys.RecoveryStreamSubType}
        component={FormTextField}
        variant="outlined"
        placeholder="Recovery Stream Sub Type"
        size="small"
        label="Recovery Stream Sub Type"
        disabled
      />
      <Field
        name={CaseDetailsKeys.ManualFilingUser}
        component={FormTextField}
        variant="outlined"
        placeholder="Manual Filing User"
        size="small"
        label="Manual Filing User"
        disabled
      />
      <Field
        name={CaseDetailsKeys.CaseFilingUser}
        component={AutocompleteField}
        label="Case Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Case Filing User"
        disabled={!hasPermission(["admin:*", "user:shortages_case:write:*"])}
      />
      <Field
        name={`${CaseDetailsKeys.CaseOwner}.id`}
        component={AutocompleteField}
        label="Current Case Owner"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Case Owner"
        disabled={!hasPermission(["admin:*", "user:shortages_case:supervisor:*"])}
        onChange={(value: WorkPacketOwner | null) => {
          setFieldValue(`${CaseDetailsKeys.CaseOwner}.id`, value?.id ?? null);
        }}
      />
      <Field
        name={CaseDetailsKeys.Sprinter}
        component={AutocompleteField}
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        variant="outlined"
        placeholder="Sprinter"
        size="small"
        label="Sprinter"
        onChange={(value: WorkPacketOwner | null) => {
          setFieldValue(CaseDetailsKeys.Sprinter, value?.id ?? null);
        }}
      />
      <Field
        name={CaseDetailsKeys.SprinterDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Sprinter Date"
        size="small"
        label="Sprinter Date"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.Processor}
        component={AutocompleteField}
        label="Processor"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Processor"
        onChange={(value: WorkPacketOwner | null) => {
          setFieldValue(CaseDetailsKeys.Processor, value?.id ?? null);
        }}
      />
      <Field
        name={CaseDetailsKeys.ProcessorDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Processor Date"
        size="small"
        label="Processor Date"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.OpsExternalCommunication}
        component={AutocompleteField}
        variant="outlined"
        placeholder="OPS External Communication"
        size="small"
        label="OPS External Communication"
        getOptionLabel={(option: any) => option.title}
        options={[
          { value: true, title: "Yes" },
          { value: false, title: "No" },
        ]}
        onChange={(value: { value: boolean; title: string } | null) => {
          setFieldValue(CaseDetailsKeys.OpsExternalCommunication, value?.value ?? null);
        }}
      />
      <Field
        name={CaseDetailsKeys.SubmissionPath}
        component={AutocompleteField}
        variant="outlined"
        placeholder="Submission Path"
        size="small"
        label="Submission Path"
        getOptionLabel={(option: any) => option.title}
        options={[
          { value: "TEMPLATE", title: "Template", color: "#FF820F" },
          { value: "ESCALATIONS", title: "Escalations", color: "#318FFF" },
          { value: "VM", title: "VM", color: "#7B3DFF" },
        ]}
        onChange={(value: { value: string; title: string; color: string } | null) => {
          setFieldValue(CaseDetailsKeys.SubmissionPath, value?.value ?? null);
        }}
      />
      <Field
        name={CaseDetailsKeys.Status}
        variant="outlined"
        placeholder="Case Status"
        size="small"
        label="Case Status"
        component={AutocompleteField}
        options={settableCaseStatuses[CaseType.SHORTAGE_SETTLEMENT]}
        getOptionLabel={(option: any) => option.title}
        onChange={(value: { value: string; title: string } | null) => {
          setFieldValue(CaseDetailsKeys.Status, value?.value ?? null);
        }}
      />
      <Field
        name={CaseDetailsKeys.Stage}
        variant="outlined"
        placeholder="Stage"
        size="small"
        label="Stage"
        component={AutocompleteField}
        options={settlementCaseStagesMap}
        getOptionLabel={(option: any) => option.title}
        onChange={(value: { value: string; title: string } | null) => {
          setFieldValue(CaseDetailsKeys.Stage, value?.value ?? null);
        }}
      />
      <Field
        name={CaseDetailsKeys.CcUser}
        component={FormTextField}
        variant="outlined"
        placeholder="CC'd User"
        size="small"
        label="CC'd User"
        InputLabelProps={{ shrink: true }}
      />
      <Field
        name={CaseDetailsKeys.AmazonCaseId}
        component={FormTextField}
        variant="outlined"
        placeholder="Current Case ID"
        size="small"
        label="Current Case ID"
        disabled
      />
      {values.vcLink ? (
        <VcLinkButton link={values.vcLink} />
      ) : (
        <TextField label="Link To Case" placeholder="Link To Case" variant="outlined" size="small" disabled />
      )}
      <Field
        name={CaseDetailsKeys.PrevCaseIds}
        component={FormTextField}
        variant="outlined"
        placeholder="Previous Case #(s)"
        size="small"
        label="Previous Case #(s)"
        disabled
      />
      <Field
        name={CaseDetailsKeys.AmazonLastRespondedAt}
        component={DatePickerField}
        variant="outlined"
        placeholder="AMZ last response"
        size="small"
        label="AMZ last response"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.CgLastRespondedAt}
        component={DatePickerField}
        variant="outlined"
        placeholder="CG last response"
        size="small"
        label="CG last response"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.AmazonCaseManager}
        component={FormTextField}
        variant="outlined"
        placeholder="Amazon Case Manager"
        size="small"
        label="Amazon Case Manager"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.LastModifiedBy}
        component={FormTextField}
        variant="outlined"
        placeholder="Last Modified By"
        size="small"
        label="Last Modified By"
        disabled
      />
    </FormFieldsGroup>
  );
};
