import {
  ActionCategory,
  PermissionLevel,
  StagefulAction,
  StagelessAction,
  StageName,
  type WorkPacketAction,
  WorkPacketFilter,
} from "src/types/work-packets";
import { WorkPacketType } from "../../WorkPacketType";
import {
  actionMappers,
  createActionsForWorkPacketType,
  createStagefulAction,
  createStagefulActions,
  createStagelessAction,
  createStagelessActions,
} from "../../mappers";

const recoveryStreamOptions = [
  { value: "", label: "All" },
  { value: "INVENTORY_SHORTAGES", label: "Inventory shortages" },
  { value: "PRICE_CLAIMS", label: "Price Claims" },
  { value: "ACCRUALS", label: "Accruals" },
  { value: "CHARGEBACKS", label: "Chargebacks" },
];

const packetStageOptions = [
  { value: "", label: "All" },
  { value: "Identify", label: "Identify" },
  { value: "Trigger", label: "Trigger" },
];

const redFlagOptions = [
  { value: "ACTION_STALLED", label: "Current action has not changed in 7+ days" },
  { value: "DISPUTE_OLD", label: "Dispute created 30+ days ago" },
  { value: "DISPUTE_STALLED", label: "Dispute identified 7+ days ago" },
];

const paymentStatusOptions = [
  { value: "identified", label: "VC Client Payment" },
  { value: "invoiced", label: "C6 Invoices Client" },
];

const accrualRedFlagOptions = [
  { value: "historicalFirstAccrual", label: "Historical - First Accrual Identified > 7 days" },
  { value: "ongoingFirstAccrual", label: "Ongoing - First Accrual Identified > 14 days" },
  { value: "historicalSecondAccrual", label: "Historical - Second Accrual Identified > 10 days" },
  { value: "ongoingSecondAccrual", label: "Ongoing - Second Accrual Identified > 10 days" },
  { value: "obarGreaterThanInvoiceAmount", label: "OBAR Suggested Dispute > Invoice Amount" },
  { value: "approvedMoreThan14Days", label: "First/Second/Third Dispute Resolved - Approved > 14 days" },
  {
    value: "partiallyApprovedMoreThan14Days",
    label: "First/Second/Third Dispute Resolved - Partially Approved > 14 days",
  },
];

const allSettableActions: Record<WorkPacketType, WorkPacketAction[]> = {
  [WorkPacketType.CHARGEBACKS]: [
    createStagefulAction(StageName.First, StagefulAction.Identified),

    // chargebacks only have two stages
    createStagefulAction(StageName.First, StagefulAction.DisputeCreated),
    createStagefulAction(StageName.First, StagefulAction.DisputeInReview),
    createStagefulAction(StageName.First, StagefulAction.DisputeExpired),

    createStagefulAction(StageName.Second, StagefulAction.DisputeCreated),
    createStagefulAction(StageName.Second, StagefulAction.DisputeInReview),
    createStagefulAction(StageName.Second, StagefulAction.DisputeExpired),

    createStagelessAction(StagelessAction.Junk),
    createStagelessAction(StagelessAction.Duplicate),
    createStagelessAction(StagelessAction.NotApplicable),
  ],

  [WorkPacketType.SHORTAGES]: [
    createStagefulAction(StageName.First, StagefulAction.Identified),

    // shortages only have one stage, and don't have Dispute In Review
    createStagefulAction(StageName.First, StagefulAction.DisputeCreated),

    // shortages don't have Junk or Expired actions
    createStagelessAction(StagelessAction.Duplicate),
    createStagelessAction(StagelessAction.NotApplicable),
  ],

  [WorkPacketType.ACCRUALS]: [
    ...createStagefulActions(WorkPacketType.ACCRUALS),
    ...createStagelessActions(WorkPacketType.ACCRUALS),
  ],
};

const allSettablePreCaseActions: Record<WorkPacketType, WorkPacketAction[]> = {
  [WorkPacketType.CHARGEBACKS]: [
    {
      ...createStagelessAction(StagelessAction.NotPursuing),
      minimumPermissionToSet: PermissionLevel.Supervisor,
    },
    {
      ...createStagelessAction(StagelessAction.ReadyForReview),
      minimumPermissionToSet: PermissionLevel.Admin,
    },
  ],

  [WorkPacketType.SHORTAGES]: [
    {
      ...createStagelessAction(StagelessAction.NotPursuing),
      minimumPermissionToSet: PermissionLevel.Supervisor,
    },
    {
      ...createStagelessAction(StagelessAction.ReadyForReview),
      minimumPermissionToSet: PermissionLevel.Admin,
    },
    {
      ...createStagelessAction(StagelessAction.ReadyForOssr),
      minimumPermissionToSet: PermissionLevel.Admin,
    },
  ],

  [WorkPacketType.ACCRUALS]: [
    // TODO: add pre-case actions
  ],
};

function createSettableActionsMap(
  actionSet: Record<WorkPacketType, WorkPacketAction[]>,
  permissionLevel: PermissionLevel,
) {
  const workPacketTypes = Object.values(WorkPacketType);
  return Object.fromEntries(
    workPacketTypes.map(workPacketType => [
      workPacketType,
      actionSet[workPacketType]
        .filter(
          action => action.minimumPermissionToSet === undefined || permissionLevel >= action.minimumPermissionToSet,
        )
        .map(actionMappers[workPacketType]),
    ]),
  ) as Record<WorkPacketType, WorkPacketAction[]>;
}

/**
 * Used within the WorkPacketsGrid to filter out the actions that are editable. Kept for backwards compatibilty.
 * Use `getEditWorkPacketActionOptions` instead.
 */
export const settableWorkPacketActions: Record<WorkPacketType, WorkPacketAction[]> = createSettableActionsMap(
  allSettableActions,
  PermissionLevel.Default,
);
export const adminSettableWorkPacketActions: Record<WorkPacketType, WorkPacketAction[]> = createSettableActionsMap(
  allSettableActions,
  PermissionLevel.Admin,
);

const disputeStatuses = ["", "Resolved", "Pending Amazon action", "Pending your action"];

const disputeStatusOptions = disputeStatuses.map(status => ({ value: status, title: status }));

export const staticFilterOptions = {
  [WorkPacketFilter.RecoveryStream]: recoveryStreamOptions,
  [WorkPacketFilter.PacketStage]: packetStageOptions,
  [WorkPacketFilter.DisputeStatus]: disputeStatusOptions,
  [WorkPacketFilter.ChargebackRedFlags]: redFlagOptions,
  [WorkPacketFilter.PaymentStatus]: paymentStatusOptions,
  [WorkPacketFilter.AccrualRedFlags]: accrualRedFlagOptions,
};

function createFilterableActionsMap() {
  const workPacketTypes = Object.values(WorkPacketType);

  return Object.fromEntries(
    workPacketTypes.map(workPacketType => [
      workPacketType,
      createActionsForWorkPacketType(workPacketType).filter(
        action =>
          // Filter out actions that should not be displayed on the triggers pages
          ![ActionCategory.PreCase, ActionCategory.Case, ActionCategory.Escalation].includes(action.categoryKey),
      ),
    ]),
  ) as Record<WorkPacketType, WorkPacketAction[]>;
}

export const filterableWorkPacketActions: Record<WorkPacketType, WorkPacketAction[]> = createFilterableActionsMap();

export const shortageEscalationWorkPacketActions = [
  {
    value: "WAITING_ON_AMAZON",
    title: "Escalation - Waiting on Amazon",
    color: "#FFCD4D",
  },
  {
    value: "WAITING_ON_CG",
    title: "Escalation - Waiting on CG",
    color: "#FF820F",
  },
  {
    value: "APPROVED",
    title: "Escalation - Approved",
    color: "#39D4A5",
  },
  {
    value: "PARTIALLY_APPROVED",
    title: "Escalation - Partially Approved",
    color: "#318FFF",
  },
  {
    value: "DENIED",
    title: "Escalation - Denied",
    color: "#FECDD6",
  },
  {
    value: "NO_PURSUE",
    title: "Escalation - Not Pursuing",
    color: "#EAECF0",
  },
];

export const chargebackEscalationWorkPacketActions = [
  {
    value: "WAITING_ON_AMAZON",
    title: "Waiting on Amazon",
    color: "#EAECF0",
  },
  {
    value: "STUCK",
    title: "Stuck",
    color: "#E03741",
  },
  {
    value: "APPROVED",
    title: "Approved",
    color: "#F155FF",
  },
  {
    value: "WAITING_ON_CG",
    title: "Waiting on CG",
    color: "#FF820F",
  },
  {
    value: "RECEIVED",
    title: "Received",
    color: "#318FFF",
  },
  {
    value: "WAITING_ON_CLIENT",
    title: "Waiting on Client",
    color: "#FECDD6",
  },
  {
    value: "PREP_FLAG_REMOVED",
    title: "Prep Flag Removed",
    color: "#66CCFF",
  },
  {
    value: "WAITING_ON_AM_VM",
    title: "Waiting on AM/VM",
    color: "#5559DF",
  },
  {
    value: "APPROVED_PARTIALLY_APPROVED",
    title: "Approved / Partially Approved",
    color: "#39D4A5",
  },
  {
    value: "CLOSED",
    title: "Closed",
    color: "#7D3B8A",
  },
  {
    value: "REJECTED",
    title: "Rejected",
    color: "#7B3DFF",
  },
  {
    value: "APPROVED_NO_PAYMENT",
    title: "Approved No Payment",
    color: "#800B12",
  },
];

const settableActionMaps = {
  default: {
    [PermissionLevel.Admin]: createSettableActionsMap(allSettableActions, PermissionLevel.Admin),
    [PermissionLevel.Supervisor]: createSettableActionsMap(allSettableActions, PermissionLevel.Supervisor),
    [PermissionLevel.Default]: createSettableActionsMap(allSettableActions, PermissionLevel.Default),
  },
  preCase: {
    [PermissionLevel.Admin]: createSettableActionsMap(allSettablePreCaseActions, PermissionLevel.Admin),
    [PermissionLevel.Supervisor]: createSettableActionsMap(allSettablePreCaseActions, PermissionLevel.Supervisor),
    [PermissionLevel.Default]: createSettableActionsMap(allSettablePreCaseActions, PermissionLevel.Default),
  },
};

export function getEditWorkPacketActionOptions(
  workPacketType: WorkPacketType,
  isPreCaseView: boolean,
  permissionLevel: PermissionLevel,
) {
  return settableActionMaps[isPreCaseView ? "preCase" : "default"][permissionLevel][workPacketType];
}
