import { SidebarContent, SidebarFooter } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "../Sidebar.css";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../../Loader/Loader.jsx";
import usePermissions from "../../../CustomHooks/usePermissions.js";
import { Box } from "@mui/material";
import SidebarLink from "./SidebarLink";
import SidebarNestedLink from "./SidebarNestedLink";
import SidebarLogout from "./SidebarLogout";
import { useFeatureFlag } from "src/feature-flags";
import { useMemo } from "react";
import { spreadIf } from "src/utils/spread-if";

interface SideBarContentProps {
  menuCollapsed: boolean;
}

const createNavigationEntries = ({ showAccruals = false, showCases = false }) => [
  {
    title: "OSSR",
    to: "/user-dashboard/ossr",
    iconName: "ossr",
    permission: ["admin:*", "user:shortages:*", "user:ossr:*"],
  },
  {
    title: "CB Dispute",
    iconName: "cbDispute",
    permission: ["admin:*", "user:chargebacks:*"],
    forceActiveOnUrls: [
      "/user-dashboard/massdispute",
      "/user-dashboard/usageHistory",
      "/user-dashboard/configuration",
      "/user-dashboard/disputephrases",
    ],
    subMenu: [
      {
        title: "Dispute Tool",
        to: "/user-dashboard/massdispute",
        permission: ["admin:*", "user:chargebacks:*"],
        iconName: "cbDisputeTool",
      },
      {
        title: "Usage History",
        to: "/user-dashboard/usageHistory",
        permission: ["admin:*", "user:chargebacks:read:*"],
        iconName: "cbDisputeUsage",
      },
      {
        title: "Manage Phrase",
        to: "/user-dashboard/disputephrases",
        permission: ["admin:*", "user:chargebacks:write:*"],
        iconName: "cbDisputeManage",
      },
    ],
  },
  {
    title: "Shortage Dispute",
    to: "/user-dashboard/shortage-dispute",
    iconName: "dispute",
    permission: ["admin:*", "user:shortages:*"],
  },
  {
    title: "Invoicing Automation",
    to: "/user-dashboard/invoicing-automation",
    iconName: "invoice",
    permission: ["admin:*", "user:invoicing:*"],
  },

  {
    title: "Audits",
    to: "/user-dashboard/audits",
    iconName: "audits",
    permission: ["admin:*", "user:audit:*"],
  },
  {
    title: "Customers",
    to: "/user-dashboard/customers",
    iconName: "customers",
    permission: ["admin:*", "user:client_config:*"],
  },
  {
    title: "OBAR",
    to: "/user-dashboard/obar/view",
    iconName: "obar",
    permission: ["admin:*", "user:accruals:*"],
  },
  {
    title: "Work Packets",
    to: "/user-dashboard/work-packets",
    iconName: "workPackets",
    subMenu: [
      {
        title: "Chargeback Triggers",
        to: "/user-dashboard/work-packets/chargebacks",
        matchExactParams: ["preCase"],
        permission: ["admin:*", "user:chargebacks_work_packet:*"],
        iconName: "cbDispute",
      },
      ...spreadIf(showCases, {
        title: "Chargeback Escalations",
        to: "/user-dashboard/work-packets/chargebacks?preCase=true",
        matchExactParams: ["preCase"],
        permission: ["admin:*", "user:chargebacks_case:read:*"],
        iconName: "cbDispute",
        forceActiveOnUrls: ["/user-dashboard/work-packets/chargebacks/escalations"],
      }),
      {
        title: "Shortage Triggers",
        to: "/user-dashboard/work-packets/shortages",
        matchExactParams: ["preCase"],
        permission: ["admin:*", "user:shortages_work_packet:read:*"],
        iconName: "dispute",
      },
      ...spreadIf(showCases, {
        title: "Shortage Escalations & Settlements",
        to: "/user-dashboard/work-packets/shortages?preCase=true",
        matchExactParams: ["preCase"],
        permission: ["admin:*", "user:shortages_case:read:*"],
        iconName: "dispute",
        forceActiveOnUrls: [
          "/user-dashboard/work-packets/shortages/escalations",
          "/user-dashboard/work-packets/shortages/settlements",
        ],
      }),
      ...spreadIf(showAccruals, {
        title: "Accruals",
        to: "/user-dashboard/work-packets/accruals",
        permission: ["admin:*", "user:accruals_work_packet:read:*"],
        iconName: "dispute",
      }),
    ],
    forceActiveOnUrls: [
      "/user-dashboard/work-packets/chargebacks/escalations",
      "/user-dashboard/work-packets/shortages/escalations",
      "/user-dashboard/work-packets/shortages/settlements",
    ],
  },
  {
    title: "Users",
    to: "/user-dashboard/users",
    iconName: "users",
    permission: ["admin:*"],
  },
];

const SideBarContent = (props: SideBarContentProps) => {
  const { logout, isAuthenticated } = useAuth0();
  const { hasPermission } = usePermissions();
  const { value: showAccruals } = useFeatureFlag("ACCRUAL_WORK_PACKETS", false);
  const { value: showCases } = useFeatureFlag("CASES_V1", false);
  const navigation = useMemo(() => createNavigationEntries({ showAccruals, showCases }), [showAccruals, showCases]);

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  if (!isAuthenticated) return <Loader />;

  return (
    <Box paddingX="8px" display="flex" flexDirection="column" flex="1">
      <SidebarContent>
        <Box display="flex" flexDirection="column" gap="4px">
          {navigation.map((nav, index) => {
            if (nav.subMenu) {
              if (!nav.subMenu.some((subNav) => hasPermission(subNav.permission))) return null;
              return (
                <SidebarNestedLink
                  key={index}
                  title={nav.title}
                  iconName={nav.iconName}
                  minimized={props.menuCollapsed}
                  subMenu={nav.subMenu}
                  forceActiveOnUrls={nav.forceActiveOnUrls}
                />
              );
            }

            if (!hasPermission(nav.permission)) return null;

            return (
              <SidebarLink
                key={index}
                title={nav.title}
                to={nav.to}
                iconName={nav.iconName}
                minimized={props.menuCollapsed}
              />
            );
          })}
        </Box>
      </SidebarContent>

      <SidebarFooter
        style={{
          marginTop: "auto",
          paddingTop: "8px",
          paddingBottom: "8px",
        }}
      >
        <SidebarLogout title="Logout" onClick={handleLogout} minimized={props.menuCollapsed} />
      </SidebarFooter>
    </Box>
  );
};

export default SideBarContent;
