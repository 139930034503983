import usePermissions from "components/CustomHooks/usePermissions";
import { createContext, type FC, type ReactNode, useCallback, useContext, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { WorkPacketFilter, type WorkPacketFilters } from "src/types/work-packets";
import { WorkPacketView } from "./WorkPacketView";
import { WorkPacketType } from "./WorkPacketType";

export const initialFilters: WorkPacketFilters = {
  [WorkPacketFilter.RecoveryStream]: "",
  [WorkPacketFilter.RecoveryStreamType]: "",
  [WorkPacketFilter.RecoveryStreamSubtype1]: "",
  [WorkPacketFilter.RecoveryStreamSubtype2]: "",
  [WorkPacketFilter.PacketStage]: "",
  [WorkPacketFilter.StoreName]: [],
  [WorkPacketFilter.VendorName]: [],
  [WorkPacketFilter.CurrentAction]: "",
  [WorkPacketFilter.CurrentPacketOwner]: "",
  [WorkPacketFilter.RedFlags]: false,
  [WorkPacketFilter.PreCase]: false,
  [WorkPacketFilter.ManualFilingUser]: "",
  [WorkPacketFilter.DisputeCreatedAt]: "",
  [WorkPacketFilter.NumberOfEscalations]: "",
  [WorkPacketFilter.NumberOfSettlements]: "",
  [WorkPacketFilter.PreviouslyRemovedFromEscalations]: false,
  [WorkPacketFilter.PreviouslyRemovedFromSettlements]: false,
  [WorkPacketFilter.InvoiceDueDate]: [],
  [WorkPacketFilter.CaseID]: "",
  [WorkPacketFilter.PaymentStatus]: [],
  [WorkPacketFilter.PayeeCode]: [],
  [WorkPacketFilter.VendorCode]: [],

  // chargeback packets only:
  [WorkPacketFilter.AsinId]: [],
  [WorkPacketFilter.VcPoId]: [],
  [WorkPacketFilter.IssueId]: [],
  [WorkPacketFilter.ChargebackCreatedAt]: "",
  [WorkPacketFilter.CalculatedDisputeByDate]: "",
  [WorkPacketFilter.FreightTerms]: "",
  [WorkPacketFilter.InboundShipmentDeliveryIsdId]: [],
  [WorkPacketFilter.ChargebackRedFlags]: [],
  [WorkPacketFilter.ParentInvoiceId]: [],
  [WorkPacketFilter.DisputedInvoiceId]: [],
  [WorkPacketFilter.DisputeId]: [],

  // accrual packets only:
  [WorkPacketFilter.RecoveryStreamSubtype]: "",
  [WorkPacketFilter.ObarCreatedDate]: [],
  [WorkPacketFilter.AgreementId]: [],
  [WorkPacketFilter.InvoiceDate]: [],
  [WorkPacketFilter.InvoiceNumber]: [],
  [WorkPacketFilter.OriginalDisputeId]: [],
  [WorkPacketFilter.ConsolidatedDisputeId]: [],
  [WorkPacketFilter.DisputeStatus]: "",
  [WorkPacketFilter.WithNotes]: false,
  [WorkPacketFilter.WithMonitoringDate]: false,
  [WorkPacketFilter.AccrualRedFlags]: [],
};

type UrlParamsState = Partial<WorkPacketFilters>;

const urlToWorkPacketType: Record<string, WorkPacketType> = {
  chargebacks: WorkPacketType.CHARGEBACKS,
  shortages: WorkPacketType.SHORTAGES,
  accruals: WorkPacketType.ACCRUALS,
};

export interface WorkPacketsStore {
  currentWorkPacketType: WorkPacketType;
  currentView: WorkPacketView;
  currentFilters: WorkPacketFilters;
  setView: (view: WorkPacketView) => void;
  setFilters: (filters: WorkPacketFilters) => void;
  clearFilters: () => void;
}

export const WorkPacketsStoreContext = createContext<WorkPacketsStore | null>(null);

const serializeState = (urlState: UrlParamsState): URLSearchParams => {
  return new URLSearchParams(
    Object.fromEntries(
      Object.entries(urlState)
        .filter(([_key, val]) => {
          if (Array.isArray(val)) return val.length > 0;
          return val != null && val !== "" && (typeof val !== "boolean" || val);
        })
        .map(([key, val]) => {
          const isArray = Array.isArray(val);
          if (isArray) return [key, val.join(",")];

          return [key, val.toString()];
        }),
    ),
  );
};

const validStateKeys: string[] = Object.values(WorkPacketFilter);
const validViewIds = Object.values(WorkPacketView).filter(x => typeof x === "number");

const deserializeState = (params: URLSearchParams): UrlParamsState => {
  const urlState = Object.fromEntries(
    Array.from(params.entries())
      .filter(([key, _val]) => validStateKeys.includes(key))
      .map(([key, val]) => {
        const isBoolean = typeof initialFilters[key as WorkPacketFilter] === "boolean";
        if (isBoolean) return [key, val === "true"];

        const isArray = Array.isArray(initialFilters[key as WorkPacketFilter]);
        if (isArray) return [key, val.split(",")];

        return [key, val];
      }),
  );
  const parsedViewId = Number.parseInt(urlState.view);
  const view = validViewIds.includes(parsedViewId) ? parsedViewId : undefined;
  return { ...initialFilters, ...urlState, view };
};

const deserializeCurrentState = () => deserializeState(new URLSearchParams(window.location.search));

const getFilterDepsList = (state: UrlParamsState) => Object.values(WorkPacketFilter).map(key => state[key]);

export const WorkPacketsContext: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const storeState = useMemo(() => deserializeState(searchParams), [searchParams]);
  const setStoreState = useCallback(
    (newState: UrlParamsState) => {
      setSearchParams(serializeState(newState), { replace: true });
    },
    [setSearchParams],
  );

  const { isAdmin } = usePermissions();
  useEffect(() => {
    if (storeState.view === undefined) {
      const initialView = isAdmin ? WorkPacketView.AllPackets : WorkPacketView.MyPackets;
      setStoreState({ ...storeState, view: initialView });
    }
  }, [isAdmin, setStoreState, storeState]);

  const currentView = useMemo((): WorkPacketView | undefined => storeState.view, [storeState.view]);

  const currentFilters = useMemo((): WorkPacketFilters => {
    return { ...initialFilters, ...storeState };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, getFilterDepsList(storeState));

  const setView = useCallback(
    (view: WorkPacketView) => setStoreState({ view, preCase: storeState[WorkPacketFilter.PreCase] }),
    [setStoreState, storeState],
  );

  const setFilters = useCallback(
    (filters: WorkPacketFilters) => {
      const currentView = deserializeCurrentState().view;
      const canSetPacketOwner = currentView === WorkPacketView.AllPackets;
      setStoreState({
        ...filters,
        ...(!canSetPacketOwner && {
          [WorkPacketFilter.CurrentPacketOwner]: "",
        }),
      });
    },
    [setStoreState],
  );

  const clearFilters = useCallback(() => {
    const currentState = deserializeCurrentState();
    const { preCase, view } = currentState;
    return setStoreState({ preCase, view });
  }, [setStoreState]);

  const currentUrl = location.pathname.split("/").at(-1);
  const currentWorkPacketType = currentUrl
    ? (urlToWorkPacketType[currentUrl] ?? WorkPacketType.CHARGEBACKS)
    : WorkPacketType.CHARGEBACKS;

  const store = useMemo(
    (): WorkPacketsStore => ({
      currentWorkPacketType,
      currentView: currentView!, // we won't render any children if there's no current view
      currentFilters,
      setView,
      setFilters,
      clearFilters,
    }),
    [currentWorkPacketType, currentView, currentFilters, setView, setFilters, clearFilters],
  );

  if (currentView === undefined) return null;
  return <WorkPacketsStoreContext.Provider value={store} children={children} />;
};

export const useWorkPacketsContext = () => useContext(WorkPacketsStoreContext)!;
