import { Field, useFormikContext } from "formik";
import { type FC } from "react";
import {
  AutocompleteField,
  MultiAutocompleteField,
  MultiCheckboxField,
  SelectField,
  StringDateRangePickerField,
} from "src/components/UI/Form";
import { StagelessAction, WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import { WorkPacketType } from "../../WorkPacketType";
import { WorkPacketView } from "../../WorkPacketView";
import { useWorkPacketsContext } from "../../WorkPacketsContext";
import { useUserNickname } from "../../useUserNickname";
import { useUserOptions } from "../../useUserOptions";
import { filterableWorkPacketActions, staticFilterOptions } from "../helpers/getStaticFilterOptions";
import { getPacketOwnerLabel } from "./getPacketOwnerLabel";
import { useChargebackSubtypes } from "./hooks/useChargebackSubtypes";
import { useVendorOptions } from "./hooks/userVendorOptions";
import { createStagelessAction } from "src/pages/UserDashboard/WorkPackets/mappers";
import { useAccountOptions } from "./hooks/useAccountOptions";
import { FilterFieldGrid } from "../../FilterFieldGrid";
import { useAuditAccountOptions } from "./hooks/useAuditAccountOptions";

const preCaseActions = [
  createStagelessAction(StagelessAction.ReadyForReview),
  createStagelessAction(StagelessAction.NotPursuing),
];

const freightTermsOptions = [
  { title: "Prepaid", value: "Prepaid" },
  { title: "Collect", value: "Collect" },
];

export const ChargebackFiltersForm: FC = () => {
  const { currentFilters, currentView, currentWorkPacketType } = useWorkPacketsContext();
  const { setFieldValue } = useFormikContext();
  const userNickname = useUserNickname();

  const userOptions = useUserOptions();
  const accountOptions = useAccountOptions();
  const vendorOptions = useVendorOptions();
  const auditAccountOptions = useAuditAccountOptions();
  const chargebackSubtypes = useChargebackSubtypes();

  const isPreCase = !!currentFilters[WorkPacketFilter.PreCase];

  return (
    <FilterFieldGrid>
      <Field
        name={WorkPacketFilter.VendorName}
        component={MultiAutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        freeText={false}
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={MultiAutocompleteField}
        label="Store Name"
        options={accountOptions}
        freeText={false}
        setFieldValue={setFieldValue}
      />

      {!isPreCase && (
        <Field
          name={WorkPacketFilter.PacketStage}
          component={SelectField}
          label="Packet Stage"
          options={staticFilterOptions[WorkPacketFilter.PacketStage]}
        />
      )}

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(currentView, userNickname)}
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disabled={currentView !== WorkPacketView.AllPackets}
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={isPreCase ? preCaseActions : filterableWorkPacketActions[WorkPacketType.CHARGEBACKS]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={WorkPacketFilter.RecoveryStreamType}
        component={AutocompleteField}
        label="Recovery Stream Type"
        options={chargebackSubtypes?.recoveryStreamType ?? EMPTY_ARRAY}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Type"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.RecoveryStreamSubtype1}
        component={AutocompleteField}
        label="Recovery Stream Subtype 1"
        options={chargebackSubtypes?.recoveryStreamSubtype1 ?? EMPTY_ARRAY}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Subtype 1"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.RecoveryStreamSubtype2}
        component={AutocompleteField}
        label="Recovery Stream Subtype 2"
        options={chargebackSubtypes?.recoveryStreamSubtype2 ?? EMPTY_ARRAY}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream Subtype 2"
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.ManualFilingUser}
        component={AutocompleteField}
        label="Manual Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={WorkPacketFilter.IssueId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        getOptionLabel={(option: any) => option.title}
        label="Chargeback Issue ID"
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.ChargebackCreatedAt}
        component={StringDateRangePickerField}
        label="Chargeback Created At"
      />

      <Field
        name={WorkPacketFilter.CalculatedDisputeByDate}
        component={StringDateRangePickerField}
        label="Calculated Dispute By Date"
      />

      <Field
        name={WorkPacketFilter.VcPoId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="VC PO ID"
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.FreightTerms}
        component={AutocompleteField}
        options={freightTermsOptions}
        getOptionLabel={(option: any) => option.title}
        label="Freight Terms"
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.AsinId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Filter by ASIN"
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.InboundShipmentDeliveryIsdId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Filter by ISD"
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.ChargebackRedFlags}
        component={MultiCheckboxField}
        label="Red Flags"
        options={staticFilterOptions[WorkPacketFilter.ChargebackRedFlags]}
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.PaymentStatus}
        component={MultiCheckboxField}
        label="Payment Status"
        options={staticFilterOptions[WorkPacketFilter.PaymentStatus]}
        setFieldValue={setFieldValue}
        disableSuggestions
      />
    </FilterFieldGrid>
  );
};
