import CheckIcon from "@mui/icons-material/Check";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { type SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { type FC } from "react";
import { http } from "src/axios";
import { type WorkPacket } from "src/types/work-packets";

const filenameRegex = /filename="(.*?)"/;
const parseContentDispositionFilename = (headerValue: string) => filenameRegex.exec(headerValue)?.[1];

const fetchObar = (packetId: string) =>
  http.get<Blob>(`/api/v2/work_packets/${packetId}/accruals/evidence`, { responseType: "blob" });

const saveObarBlob = (response: AxiosResponse<Blob>, packetId: string) => {
  const a = document.createElement("a");
  const href = URL.createObjectURL(response.data);
  const filename = parseContentDispositionFilename(response.headers["content-disposition"]) ?? `${packetId}-obar.xlsx`;
  a.href = href;
  a.download = filename;
  a.click();
};

// This component can be used standalone or as a custom cell in MUI Data Grid
export type GenerateObarButtonProps = {
  sx?: SxProps;
} & (
  | {
      workPacketId: string;
    }
  | {
      row: WorkPacket;
    }
);

export const GenerateObarButton: FC<GenerateObarButtonProps> = props => {
  const packetId = "workPacketId" in props ? props.workPacketId : props.row.packetId;
  const download = useMutation({
    mutationKey: ["generate-obar", packetId],
    mutationFn: fetchObar,
    onSuccess: saveObarBlob,
  });

  const onClick = () => download.mutate(packetId);

  return (
    <Button
      sx={{ width: "11rem", ...props.sx }}
      onClick={onClick}
      disabled={download.isPending}
      variant="outlined"
      startIcon={
        download.isPending ? (
          <CircularProgress size={12} />
        ) : download.isSuccess ? (
          <CheckIcon />
        ) : (
          <InsertDriveFileOutlinedIcon />
        )
      }
    >
      <span>Download Evidence</span>
    </Button>
  );
};
