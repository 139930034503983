import { FC } from "react";
import { Box, Button, CircularProgress, Dialog } from "@mui/material";
import toast from "react-hot-toast";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { deleteCaseItem } from "src/pages/UserDashboard/Cases/api/casesApi.ts";
import { CaseItem } from "src/types/case-items.ts";
import { QueryClient, useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

interface DeletePopupProps {
  caseItem: CaseItem;
  open: boolean;
  onClose: () => void;
  onDeleted?: () => void;
}

interface DeleteParams {
  caseId: string;
  caseItemId: string;
  queryClient: QueryClient;
  caseType: string;
}

const deleteCaseItemMutation: UseMutationOptions<void, Error, DeleteParams> = {
  mutationFn: async params => {
    await deleteCaseItem(params.caseId, params.caseItemId);
  },
  mutationKey: ["update-case-items"],

  onSuccess: async (_data, { caseType, caseId, queryClient }) => {
    await queryClient.invalidateQueries({ queryKey: ["cases", caseType] });
    await queryClient.invalidateQueries({ queryKey: ["cases", caseType, caseId, "items"] });
  },
};

export const CaseItemDeletePopup: FC<DeletePopupProps> = ({ caseItem, open, onClose, onDeleted }) => {
  const queryClient = useQueryClient();
  const { mutateAsync, isPending } = useMutation(deleteCaseItemMutation);
  const onDeleteCaseItem = () => {
    mutateAsync({
      caseId: caseItem.case_id,
      caseItemId: caseItem.id,
      caseType: caseItem.caseType,
      queryClient: queryClient,
    }).then(() => {
      if (onDeleted) {
        onDeleted();
      }
      onClose();
      toast.success(`You have successfully removed a work packet from this case.`, {
        position: "top-right",
        icon: <IoMdCheckmarkCircleOutline size={25} />,
        style: {
          background: " rgba(246, 254, 249, 1)",
          color: "rgba(2, 122, 72, 1)",
          border: "1px solid rgba(108, 233, 166, 1)",
          boxShadow: "0px 4px 6px -2px rgba(16, 24, 40, 0.03) 0px 12px 16px -4px rgba(16, 24, 40, 0.08)",
        },
      });
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box display="flex" flexDirection="column" padding="10px" justifyContent="space-between" height="150px">
        <Box>Are you sure you want to remove Work Packet {caseItem.packetId} from this Case?</Box>
        <Box display="flex" justifyContent="end">
          <Button disabled={isPending} onClick={onClose} variant="text">
            No
          </Button>
          <Button disabled={isPending} onClick={onDeleteCaseItem} variant="contained">
            Yes
            {isPending && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
