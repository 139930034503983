import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { type FieldProps, useFormikContext } from "formik";
import { type FC } from "react";

export interface CheckboxFieldProps extends FieldProps {
  name: string;
  label: string;
  disabled?: boolean;
}

/**
 * CheckboxField component integrates Material-UI's Checkbox component with Formik.
 */
const CheckboxField: FC<CheckboxFieldProps> = ({ field, label, disabled }) => {
  const { getFieldMeta } = useFormikContext();
  const { error } = getFieldMeta(field.name);
  return (
    <FormControl size="small" disabled={disabled} margin="none">
      <FormControlLabel {...field} control={<Checkbox />} label={label} labelPlacement="end" sx={{ margin: 0 }} />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxField;
