import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FieldProps, useFormikContext } from "formik";
import { FC } from "react";
import { Box } from "@mui/material";

interface DatePickerFieldProps extends FieldProps {
  label: string;
  disabled?: boolean;
}

/**
 * DatePickerField component integrates Material-UI's DatePicker component with Formik.
 *
 * @param {FieldProps} field - Formik field prop, automatically injected by Formik.
 * @param {object} form - Formik form prop, automatically injected by Formik.
 * @param {string} label - The label for the date picker field.
 * @param {boolean} disabled - Whether the date picker field is disabled.
 */
const DatePickerField: FC<DatePickerFieldProps> = ({ field, form, label, disabled }) => {
  const { getFieldMeta } = useFormikContext();
  const { error } = getFieldMeta(field.name);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <DatePicker
          label={label}
          value={field.value}
          onChange={value => {
            form.setFieldValue(field.name, value);
          }}
          format="MMM D, YYYY"
          slotProps={{
            inputAdornment: {
              position: "start",
            },
            textField: {
              error: !!error,
              helperText: error,
            },
          }}
          sx={{
            width: "100%",
            "& .MuiInputBase-root input": {
              padding: "8.5px 14px 8.5px 0",
            },
            "& .MuiInputAdornment-root": {
              marginRight: 0,
            },
          }}
          disabled={disabled}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default DatePickerField;
