import { useEffect, useState } from "react";
import Input from "../../../../components/Input/input.jsx";
import toast from "react-hot-toast";
import Validations from "../../../../Regex.js";
import { addAccounts, UpdateAccounts } from "../Api/Accounts/Accounts.js";
import Select from "react-select";
import SelectField from "../../../../components/Input/selectField.jsx";
import Mandotory from "../../../../components/Input/Mandotory.jsx";
import TextAreaField from "../../../../components/Input/TextAreaField.jsx";
import {
  accountStatuses,
  adminPermissionOptions,
  categoryOptions,
  dataPullStatusOptions,
  OptionWhiteLabelled,
  priceClaimProcessingOptions,
  shortageLagOptions,
  shortageProcessingOptions,
  vmPermissionOptions,
} from "../Data/data";
import usePermissions from "../../../../components/CustomHooks/usePermissions.js";

const AddAccount = ({
  mode = "add",
  getData,
  avcData,
  ShippingData,
  tableDataEdit,
  tableData,
  handleShowAccountsForm,
  chargebackProcessorData,
}) => {
  const [loader, setLoader] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(true);
  const { hasPermission } = usePermissions();
  const [selectedOptionAvc, setSelectedOptionAvc] = useState(null);
  const [selectedOptionWhiteLabelled, setSelectedOptionWhiteLabelled] = useState(null);

  const [selectedAuditUser, setSelectedAuditUser] = useState(null);
  const [selectedManualFilingUser, setManualFilingUser] = useState(null);

  const [selectedOptionDefaultAVCUser, setSelectedOptionDefaultAVCUser] = useState(avcData[4]);
  const [isClearable, setIsClearable] = useState(true);

  const [inputData, setInputData] = useState({
    avc_user_names: "",
    shipping_id: "",
    name: "",
    activation_date: "",
    deactivation_date: "",
    vendor_manager_name: "",
    vendor_manager_email: "",
    amazon_vendor_service_name: "",
    amazon_vendor_service_email: "",
    vendor_manager_permission: "",
    category: "",
    cb_processor_user_id: "",
    shortage_processing_status: "",
    price_claim_processing_status: "",
    white_labeled_account: "",
    shortage_lag: "45",
    recovery_potential: "",
    gmv: "",
    avc_payment_terms: "",
    avc_pull_status_id: "",
    admin_permissions: "",
    default_avc_user: "",
    audit_user: "",
    manual_filing_user: "",
    status: "active",
  });

  const handleChange = e => {
    const { name, value } = e.target;

    // Check if the input is a valid number with up to two decimal places
    if ((name === "gmv" || name === "recovery_potential") && value !== "" && !Validations.isDecimal(value)) {
      return;
    }

    setInputData(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setIsFormChanged(false);
  };

  const handleMultiSelectChange = selectedOptions => {
    setSelectedOptionAvc(selectedOptions);
    setIsFormChanged(false);
  };

  const handleMultiSelectChangeAvc = selectedOptions => {
    setSelectedOptionWhiteLabelled(selectedOptions);
    setIsFormChanged(false);
  };

  const handleMultiSelectChangeDefaultAvc = selectedOptions => {
    setSelectedOptionDefaultAVCUser(selectedOptions);
    setIsFormChanged(false);
  };

  const handleSelectAuditUser = selectedOptions => {
    setSelectedAuditUser(selectedOptions);
    setIsFormChanged(false);
  };

  const handleSelectManualFilingUser = selectedOptions => {
    setManualFilingUser(selectedOptions);
    setIsFormChanged(false);
  };

  const resetForm = () => {
    setInputData({
      avc_user_names: "",
      shipping_id: "",
      name: "",
      activation_date: "",
      deactivation_date: "",
      vm_name: "",
      white_labeled_account: true,
      shortage_lag: "",
      recovery_potential: "",
      gmv: "",
      avc_payment_terms: "",
      default_avc_user: "",
      status: "",
    });
  };

  useEffect(() => {
    if (mode === "edit" && tableDataEdit) {
      const parsedAVCUsers = JSON.parse(tableDataEdit.AVC_USERS || "");
      const selectedAVC = parsedAVCUsers?.map(obj => ({
        value: obj.avc_user_name,
        label: obj.avc_user_name,
      }));

      setSelectedOptionAvc(selectedAVC);

      // Handle white_labeled_account
      const whiteLabelledLabel = tableDataEdit.WHITE_LABELED_ACCOUNT;
      const whiteLabelledOption = OptionWhiteLabelled.find(option => option.label === whiteLabelledLabel) || null;
      setSelectedOptionWhiteLabelled(whiteLabelledOption);

      const defaultAvcUserSelected = tableDataEdit.DEFAULT_AVC_USER;

      const defaultAvcUser = avcData.find(option => option.label === defaultAvcUserSelected) || null;
      setSelectedOptionDefaultAVCUser(defaultAvcUser);

      const defaultAuditUser = avcData.find(option => option.label === tableDataEdit.AUDIT_USER) || null;

      setSelectedAuditUser(defaultAuditUser);

      const defaultManualFilingUser = avcData.find(option => option.label === tableDataEdit.MANUAL_FILING_USER) || null;

      setManualFilingUser(defaultManualFilingUser);

      setInputData({
        avc_user_names: "", // Assuming this should be an array
        shipping_id: tableDataEdit.SHIPPING_ID || "",
        name: tableDataEdit.NAME || "",
        cb_processor_user_id: tableDataEdit.CB_PROCESSOR_USER_ID || null,
        activation_date:
          tableDataEdit.ACTIVATION_DATE !== null
            ? new Date(tableDataEdit.ACTIVATION_DATE).toISOString().split("T")[0]
            : "",
        deactivation_date:
          tableDataEdit.DEACTIVATION_DATE !== null
            ? new Date(tableDataEdit.DEACTIVATION_DATE).toISOString().split("T")[0]
            : "",
        vendor_manager_name: tableDataEdit.VENDOR_MANAGER_NAME || "",
        vendor_manager_email: tableDataEdit.VENDOR_MANAGER_EMAIL || "",
        amazon_vendor_store_name: tableDataEdit.AMAZON_VENDOR_STORE_NAME || "",
        amazon_vendor_store_email: tableDataEdit.AMAZON_VENDOR_STORE_EMAIL || "",
        vendor_manager_permission: tableDataEdit.VENDOR_MANAGER_PERMISSION || "",
        category: tableDataEdit.CATEGORY || "",
        shortage_processing_status: tableDataEdit.SHORTAGE_PROCESSING_STATUS || "",
        price_claim_processing_status: tableDataEdit.PRICE_CLAIM_PROCESSING_STATUS || "",
        white_labeled_account: "",
        shortage_lag: tableDataEdit.SHORTAGE_LAG || "",
        recovery_potential: tableDataEdit.RECOVERY_POTENTIAL || "",
        gmv: tableDataEdit.GMV || "",
        avc_payment_terms: tableDataEdit.AVC_PAYMENT_TERMS || "",
        avc_pull_status_id: tableDataEdit.AVC_PULL_STATUS_ID || "",
        admin_permissions: tableDataEdit.ADMIN_PERMISSIONS || "",
        default_avc_user: tableDataEdit.DEFAULT_AVC_USER || "",
        audit_user: tableDataEdit.AUDIT_USER || "",
        manual_filing_user: tableDataEdit.MANUAL_FILING_USER || "",
        amazon_vendor_service_name: tableDataEdit.AMAZON_VENDOR_SERVICE_NAME || "",
        amazon_vendor_service_email: tableDataEdit.AMAZON_VENDOR_SERVICE_EMAIL || "",
        status: tableDataEdit.STATUS || "",
      });
    }
  }, [mode, tableDataEdit]);

  //email object for email validation

  const emailFields = [
    {
      field: "client_success_manager_email",
      message: "Invalid email format for Client Success Manager Email",
    },
    {
      field: "amazon_vendor_service_email",
      message: "Invalid email format for Amazon Vendor Services Email",
    },
  ];

  const handleAccountSubmission = () => {
    const accountID = tableData?.VENDOR_ID;

    const avcUserName = selectedOptionAvc ? selectedOptionAvc.map(option => option.label) : [];

    const mandatoryFields = [
      { name: "Store Name", value: inputData.name },
      { name: "AVC User", value: avcUserName[0] },
      { name: "Activation date", value: inputData.activation_date },
      {
        name: "Data Pull Status",
        value: inputData.avc_pull_status_id,
      },
      { name: "Status", value: inputData.status },
    ];

    const emptyFields = Validations.checkMandatoryFields(mandatoryFields);

    const updatedInputData = {
      ...inputData,
      avc_user_names: avcUserName,
      white_labeled_account: selectedOptionWhiteLabelled?.label || [],
      default_avc_user: selectedOptionDefaultAVCUser?.label || [],
      audit_user: selectedAuditUser?.label || [],
      manual_filing_user: selectedManualFilingUser?.label || [],
      admin_permissions: inputData.admin_permissions === "true",
    };

    const resultJson = JSON.parse(
      JSON.stringify(updatedInputData, (key, value) => {
        // Replace empty strings and empty arrays with null
        if (value === "" || (Array.isArray(value) && value.length === 0)) {
          return null;
        }
        return value;
      }),
    );

    let invalidEmailDetected = false;

    if (emptyFields.length > 0) {
      toast.error(`${emptyFields[0]?.name} can't be empty`);
    } else {
      // Validate Store Name
      if (inputData.name.trim() !== inputData.name) {
        toast.error("Store Name cannot start or end with spaces");
        return;
      }

      // Validate emails
      emailFields.forEach(({ field, message }) => {
        if (!Validations.validateAndToastEmail(inputData[field], message)) {
          invalidEmailDetected = true;
        }
      });

      if (!invalidEmailDetected) {
        addAccounts(resultJson, accountID, setLoader, toast, getData, resetForm, handleShowAccountsForm);
        setSelectedOptionAvc(null);
        setSelectedOptionWhiteLabelled(null);
      }
    }
  };

  const handleAccountUpdate = () => {
    const accountID = tableDataEdit.ID;
    const VendorID = tableDataEdit.VENDOR_ID;
    const avcUserIds = selectedOptionAvc ? selectedOptionAvc.map(option => option.label) : "";

    const updatedInputData = {
      ...inputData,
      avc_user_names: avcUserIds,
      white_labeled_account: selectedOptionWhiteLabelled?.label || "",
      default_avc_user: selectedOptionDefaultAVCUser?.label || "",
      audit_user: selectedAuditUser?.label || [],
      manual_filing_user: selectedManualFilingUser?.label || [],
      admin_permissions: inputData.admin_permissions === "true", // Convert string to boolean
    };

    // Check mandatory fields
    const mandatoryFields = [
      { name: "Store Name", value: inputData.name },
      { name: "AVC User", value: avcUserIds[0] },
      { name: "Activation date", value: inputData.activation_date },
      {
        name: "Data Pull Status",
        value: inputData.avc_pull_status_id,
      },
    ];

    const emptyFields = Validations.checkMandatoryFields(mandatoryFields);

    let invalidEmailDetected = false;

    if (emptyFields.length > 0) {
      toast.error(`${emptyFields[0]?.name} can't be empty`);
    } else {
      // Validate Store Name
      if (inputData.name.trim() !== inputData.name) {
        toast.error("Store Name cannot start or end with spaces");
        return;
      }
      
      // Validate emails
      emailFields.forEach(({ field, message }) => {
        if (!Validations.validateAndToastEmail(inputData[field], message)) {
          invalidEmailDetected = true;
        }
      });

      if (!invalidEmailDetected) {
        // Convert empty string values to null
        const resultJson = JSON.parse(
          JSON.stringify(updatedInputData, (key, value) => {
            // Replace empty strings and empty arrays with null
            if (value === "" || (Array.isArray(value) && value.length === 0)) {
              return null;
            }
            return value;
          }),
        );

        // Perform the update if all validations pass
        UpdateAccounts(resultJson, VendorID, accountID, setLoader, toast, getData);
        setIsFormChanged(true);
      }
    }
  };

  return (
    <>
      <div className="container p-5 rounded bg_shadow bg-light-subtle mt-4">
        <div className="row">
          <div className="col-md-4">
            <label>
              Store Name <Mandotory classes="text-red"></Mandotory>
            </label>
            <Input name={"name"} handleChange={handleChange} value={inputData.name} type={"text"} />
          </div>
          <div className="col-md-4">
            <label>
              Status <Mandotory classes="text-red"></Mandotory>
            </label>
            <SelectField
              name={"status"}
              options={accountStatuses}
              handleChange={handleChange}
              value={inputData.status || ""}
              type={"select"}
            />
          </div>
          <div className="col-md-4">
            <label>
              Backup Users <Mandotory classes="text-red"></Mandotory>
            </label>
            <Select options={[...avcData]} onChange={handleMultiSelectChange} isMulti value={selectedOptionAvc} />
          </div>
          <div className="col-md-4">
            <label>
              Activation date <Mandotory classes="text-red"></Mandotory>
            </label>
            <Input
              name={"activation_date"}
              handleChange={handleChange}
              value={inputData.activation_date}
              type={"date"}
            />
          </div>
          <div className="col-md-4">
            <label>
              Data Pull Status <Mandotory classes="text-red"></Mandotory>
            </label>
            <SelectField
              name={"avc_pull_status_id"}
              options={dataPullStatusOptions}
              handleChange={handleChange}
              value={inputData.avc_pull_status_id || ""}
              type={"select"}
            />
          </div>
          <div className="col-md-4">
            <label>Category</label>
            <SelectField
              name={"category"}
              options={categoryOptions}
              handleChange={handleChange}
              value={inputData.category || ""}
              type={"select"}
            />
          </div>
          <div className="col-md-4">
            <label>Tech user</label>
            <Select
              options={[...avcData]}
              onChange={handleMultiSelectChangeDefaultAvc}
              isClearable={isClearable}
              selectedOption={selectedOptionDefaultAVCUser}
              value={selectedOptionDefaultAVCUser}
            />
          </div>
          <div className="col-md-4">
            <label>Audit User</label>
            <Select
              options={[...avcData]}
              onChange={handleSelectAuditUser}
              isClearable={isClearable}
              selectedOption={selectedAuditUser}
              value={selectedAuditUser}
            />
          </div>
          <div className="col-md-4">
            <label>Manual Filing User</label>
            <Select
              options={[...avcData]}
              onChange={handleSelectManualFilingUser}
              isClearable={isClearable}
              selectedOption={selectedManualFilingUser}
              value={selectedManualFilingUser}
            />
          </div>
          <div className="col-md-4">
            <label>Vendor Manager Name </label>
            <Input
              name={"vendor_manager_name"}
              handleChange={handleChange}
              value={inputData.vendor_manager_name}
              type={"text"}
            />
          </div>
          <div className="col-md-4">
            <label>Vendor Manager Email </label>
            <TextAreaField
              name={"vendor_manager_email"}
              handleChange={handleChange}
              value={inputData.vendor_manager_email}
              type={"email"}
              rows={1}
            />
          </div>
          <div className="col-md-4">
            <label>Amazon Vendor Services Name </label>
            <Input
              name={"amazon_vendor_service_name"}
              handleChange={handleChange}
              value={inputData.amazon_vendor_service_name}
              type={"text"}
            />
          </div>
          <div className="col-md-4">
            <label>Amazon Vendor Services Email </label>
            <Input
              name={"amazon_vendor_service_email"}
              handleChange={handleChange}
              value={inputData.amazon_vendor_service_email}
              type={"email"}
            />
          </div>
          <div className="col-md-4">
            <label>Shipping </label>
            <SelectField
              name={"shipping_id"}
              placeholder={"Shipping Name"}
              options={ShippingData}
              handleChange={handleChange}
              value={inputData.shipping_id || []}
              type={"select"}
            />
          </div>
          <div className="col-md-4">
            <label>Deactivation date </label>
            <Input
              name={"deactivation_date"}
              handleChange={handleChange}
              value={inputData.deactivation_date}
              type={"date"}
            />
          </div>

          <div className="col-md-4">
            <label>Vendor Manager Permissions </label>
            <SelectField
              name={"vendor_manager_permission"}
              placeholder={"Vendor Manager Permissions Name"}
              options={vmPermissionOptions}
              handleChange={handleChange}
              value={inputData.vendor_manager_permission || []}
              type={"select"}
            />
          </div>
          <div className="col-md-4">
            <label>CB Processing Owner</label>
            <SelectField
              name={"cb_processor_user_id"}
              options={chargebackProcessorData}
              handleChange={handleChange}
              value={inputData.cb_processor_user_id || ""}
              type={"select"}
            />
          </div>

          <div className="col-md-4">
            <label>Shortage Processing</label>
            <SelectField
              name={"shortage_processing_status"}
              options={shortageProcessingOptions}
              handleChange={handleChange}
              value={inputData.shortage_processing_status || ""}
              type={"select"}
            />
          </div>

          <div className="col-md-4">
            <label>Price Claim Processing</label>
            <SelectField
              name={"price_claim_processing_status"}
              options={priceClaimProcessingOptions}
              handleChange={handleChange}
              value={inputData.price_claim_processing_status || ""}
              type={"select"}
            />
          </div>

          <div className="col-md-4">
            <label>White Labeled Account</label>
            <Select
              options={[...OptionWhiteLabelled]}
              onChange={handleMultiSelectChangeAvc}
              isClearable={isClearable}
              selectedOption={selectedOptionWhiteLabelled}
              value={selectedOptionWhiteLabelled}
            />
          </div>

          <div className="col-md-4">
            <label>Shortage lag</label>
            <SelectField
              name={"shortage_lag"}
              options={shortageLagOptions}
              handleChange={handleChange}
              value={inputData.shortage_lag || ""}
              type={"select"}
            />
          </div>

          <div className="col-md-4">
            <label>Audit Recovery Potential</label>
            <Input
              name={"recovery_potential"}
              handleChange={handleChange}
              value={inputData.recovery_potential}
              type={"number"}
            />
          </div>

          <div className="col-md-4">
            <label>AVC Gross Merchandise Value (GMV)</label>
            <Input name={"gmv"} handleChange={handleChange} value={inputData.gmv} type={"number"} />
          </div>

          <div className="col-md-4">
            <label>AVC Payment Terms</label>
            <Input
              name={"avc_payment_terms"}
              handleChange={handleChange}
              value={inputData.avc_payment_terms}
              type={"text"}
            />
          </div>
          <div className="col-md-4">
            <label>Admin Permissions</label>
            <SelectField
              name={"admin_permissions"}
              options={adminPermissionOptions}
              handleChange={handleChange}
              value={inputData.admin_permissions.toString()}
              type={"select"}
            />
          </div>
        </div>
        <div className="row mt-3">
          {hasPermission(["admin:*", "user:client_config:write:*"]) && (
            <div className="col-md-12">
              {loader ? (
                <button class="btn btn-primary" type="button" disabled>
                  <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
                  <span role="status">Loading...</span>
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isFormChanged}
                  onClick={() => {
                    mode === "edit" ? handleAccountUpdate() : handleAccountSubmission();
                  }}
                >
                  {mode === "add" ? "Save" : "Update"}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AddAccount;
