import { type GridRenderEditCellParams } from "@mui/x-data-grid";
import { type FC } from "react";
import { type WorkPacket } from "src/types/work-packets";
import { useUserOptions } from "../../useUserOptions";
import EditableAutocompleteCell from "../EditableAutocompleteCell";
import usePermissions from "components/CustomHooks/usePermissions";
import { WORK_PACKET_SUPERVISOR_PERMISSIONS } from "src/pages/UserDashboard/WorkPackets/WorkPacketPermission";

export const CurrentOwnerEdit: FC<GridRenderEditCellParams<WorkPacket>> = props => {
  const { hasPermission } = usePermissions();
  const { workPacketType } = props.row;

  const isSupervisor = hasPermission(WORK_PACKET_SUPERVISOR_PERMISSIONS[workPacketType]);

  const options = useUserOptions(isSupervisor);
  return <EditableAutocompleteCell {...props} options={options} />;
};
