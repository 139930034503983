/// @ts-check
import { Form } from "formik";
import { FormikInputField } from "src/components/Input/FormikInputField";
import { FormikSelect } from "src/components/Input/FormikSelect";
import { FormikTextArea } from "src/components/Input/FormikTextArea";
import { FormikCreatableSelect } from "src/components/Input/FormikCreatableSelect";
import Loader from "src/components/Loader/Loader";
import { billingEntityOptions, partnerPaymentMethods, partnerTypes, vendorTypeOptions } from "../../Data/data";
import usePermissions from "src/components/CustomHooks/usePermissions";

/** @import { FormikProps } from "formik"; */
/** @import { FC } from "react"; */

/**
 * @typedef {FormikProps<any> & {
 *  referralAll: any[];
 *  setReferralAll: (val: any[]) => void;
 *  showVendorID?: boolean;
 *  buttonCaption?: string;
 *  disableCurrentRecovery?: boolean;
 * }} VendorFormProps
 */

/** @type {FC<VendorFormProps>} */
const VendorForm = ({
  isSubmitting,
  dirty,
  isValid,
  values,
  referralAll,
  setReferralAll,
  showVendorID = false,
  buttonCaption = "Add Customer",
  disableCurrentRecovery = true,
}) => {
  const { hasPermission } = usePermissions();

  const tierOptions = [
    { label: "Select Segment", value: "" },
    { label: "Enterprise", value: "Enterprise" },
    { label: "Mid Market", value: "Mid Market" },
    { label: "SMB", value: "SMB" },
  ];

  return (
    <Form>
      <div className="row">
        {showVendorID && (
          <div className="col-md-4 mb-2">
            <FormikInputField name="VENDOR_ID" label="Vendor ID" disabled={true} type="text" />
          </div>
        )}
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="VENDOR_NAME"
            label="Customer Name"
            type="text"
            placeholder="Customer Name"
            mandatory
            showErrorWithOutTouch
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikSelect label="Segment" name="TIER" mandatory showErrorWithOutTouch>
            {tierOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </FormikSelect>
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="SPECIFIC_POINT_OF_CONTACT_NAME"
            label="Specific point of contact name"
            type="text"
            mandatory
            showErrorWithOutTouch
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="SPECIFIC_POINT_OF_CONTACT_EMAIL"
            label="Specific point of contact email"
            type="text"
            placeholder="test123@test.com"
            mandatory
            showErrorWithOutTouch
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField name="PHONE" label="Phone" type="number" placeholder="Phone" />
        </div>
        {!showVendorID && (
          <div className="col-md-4 mb-2">
            <FormikInputField name="KICK_OFF" label="Kick Off" type="date" placeholder="Kick Off" />
          </div>
        )}
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="INITIAL_COMMISSION_RATE"
            label="Pre-threshold Commission Rate"
            type="number"
            placeholder="Pre-threshold Commission Rate"
            mandatory
            showErrorWithOutTouch
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="FINAL_COMMISSION_RATE"
            label="Post-threshold Commission Rate"
            type="number"
            placeholder="Post-threshold Commission Rate"
            mandatory
            showErrorWithOutTouch
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="ENGAGEMENT_FEE"
            label="Engagement Fee"
            type="number"
            placeholder="Engagement Fee"
            mandatory
            showErrorWithOutTouch
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="RECOVERY_THRESHOLD"
            label="Recovery Threshold"
            type="number"
            placeholder="Recovery Threshold"
            mandatory
            showErrorWithOutTouch
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="CURRENT_RECOVERY"
            label="Commissioned Recovery"
            type="number"
            disabled={disableCurrentRecovery}
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="AGREEMENT_SIGNED_DATE"
            label="Agreement Signed Date"
            type="date"
            placeholder="Agreement Signed Date"
            mandatory
            showErrorWithOutTouch
          />
        </div>
        {showVendorID && (
          <div className="col-md-4 mb-2">
            <FormikInputField name="KICK_OFF" label="Kick Off" type="date" placeholder="Kick Off" />
          </div>
        )}
        <div className="col-md-4 mb-2">
          <FormikInputField name="BILLING_NAME" label="Billing Name" type="text" placeholder="Billing Name" />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField name="BILLING_EMAIL" label="Billing Email" type="email" placeholder="Billing Email" />
        </div>
        <div className="col-md-4 mb-2">
          <FormikSelect label="Billing Entity" name="BILLING_ENTITY">
            {billingEntityOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </FormikSelect>
        </div>
        <div className="col-md-4 mb-2">
          <FormikSelect label="Vendor Type" name="VENDOR_TYPE" mandatory showErrorWithOutTouch>
            {vendorTypeOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </FormikSelect>
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            label="Client Success Manager Name"
            name="CLIENT_SUCCESS_MANAGER_NAME"
            placeholder="Client Success Manager Name"
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            label="Client Success Manager Email"
            name="CLIENT_SUCCESS_MANAGER_EMAIL"
            placeholder="Client Success Manager Email"
          />
        </div>
        <div className="col-md-8 mb-4">
          <FormikTextArea label="Notes" name="NOTES" placeholder="Notes" />
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mb-2">
          <FormikCreatableSelect
            name="REFERRAL"
            label="Partner 1 Name"
            options={referralAll ?? []}
            setOptions={setReferralAll}
            mandatory
            showErrorWithOutTouch
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField name="REFERRAL_COMMISSION_RATE" label="Partner 1 Commission Rate" type="number" />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="REFERRAL_COMMISSION_DURATION"
            label="Partner 1 Commission Eligibility End Date"
            type="date"
            placeholder="Commission Duration"
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="REFERRAL_PARTNER_ID"
            label="Partner 1 Partner ID"
            type="text"
            placeholder="Partner ID"
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikSelect label="Partner 1 Payment Method" name="REFERRAL_PAYMENT_METHOD">
            {partnerPaymentMethods.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </FormikSelect>
        </div>
        <div className="col-md-4 mb-2">
          <FormikSelect label="Partner 1 Type" name="REFERRAL_PARTNER_TYPE">
            {partnerTypes.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </FormikSelect>
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="REFERRAL_CUSTOM_COMMISSION_RATE"
            label="Partner 1 Custom Commission Rate"
            type="text"
            placeholder="Partner 1 Custom Commission Rate"
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikCreatableSelect
            name="REFERRAL_2"
            label="Partner 2 Name"
            options={referralAll ?? []}
            setOptions={setReferralAll}
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="REFERRAL_2_COMMISSION_RATE"
            label="Partner 2 Commission Rate"
            type="number"
            disabled={!values.REFERRAL_2}
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="REFERRAL_2_COMMISSION_DURATION"
            label="Partner 2 Commission Eligibility End Date"
            type="date"
            placeholder="Commission Duration"
            disabled={!values.REFERRAL_2}
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="REFERRAL_2_PARTNER_ID"
            label="Partner 2 Partner ID"
            type="text"
            placeholder="Partner ID"
            disabled={!values.REFERRAL_2}
          />
        </div>
        <div className="col-md-4 mb-2">
          <FormikSelect label="Partner 2 Payment Method" name="REFERRAL_2_PAYMENT_METHOD" disabled={!values.REFERRAL_2}>
            {partnerPaymentMethods.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </FormikSelect>
        </div>
        <div className="col-md-4 mb-2">
          <FormikSelect label="Partner 2 Type" name="REFERRAL_2_PARTNER_TYPE" disabled={!values.REFERRAL_2}>
            {partnerTypes.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </FormikSelect>
        </div>
        <div className="col-md-4 mb-2">
          <FormikInputField
            name="REFERRAL_2_CUSTOM_COMMISSION_RATE"
            label="Partner 2 Custom Commission Rate"
            type="text"
            placeholder="Partner 2 Custom Commission Rate"
            disabled={!values.REFERRAL_2}
          />
        </div>
      </div>

      <div className="row mt-2 mb-3">
        {hasPermission(["admin:*", "user:client_config:write:*"]) && (
          <div className="col-md-12">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting || !(dirty && isValid)}>
              {isSubmitting ? <Loader style={{}} color="white"></Loader> : buttonCaption}
            </button>
          </div>
        )}
      </div>
    </Form>
  );
};

export default VendorForm;
