import css from "./WorkPacketGridColumns.module.css";

import {
  type GridColumnGroupingModel,
  type GridRenderCellParams,
  type GridRenderEditCellParams,
} from "@mui/x-data-grid";
import { type GridBaseColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { getProperty } from "dot-prop";
import { type ComponentProps, type FC } from "react";
import { type WorkPacketTypeMap } from "src/types/work-packets";
import { EMPTY_OBJECT } from "src/utils/empty-values";
import type { Paths } from "type-fest";
import { WorkPacketType } from "../../WorkPacketType";
import { CurrentActionDisplay } from "../columns/CurrentActionDisplay";
import { CurrentActionEdit } from "../columns/CurrentActionEdit";
import { CurrentOwnerDisplay } from "../columns/CurrentOwnerDisplay";
import { CurrentOwnerEdit } from "../columns/CurrentOwnerEdit";
import { DetailsButton } from "../columns/DetailsButton";
import { GenerateObarButton } from "../columns/GenerateObarButton";
import { isDayjs } from "dayjs";

export type WorkPacketField<T extends WorkPacketType> = keyof WorkPacketTypeMap[T] & string;

export type WorkPacketGridCol<
  T extends WorkPacketType,
  F extends WorkPacketField<T> = WorkPacketField<T>,
> = GridBaseColDef<WorkPacketTypeMap[T]> & {
  field: F;
};

/**
 * MUI Grid `renderCell` function cannot directly call any hooks. This function wraps a `renderCell` function
 * to treat it as a normal component such that it can use React hooks as normal.
 */
const wrapRenderCell =
  (
    Component: FC<GridRenderCellParams>,
    componentProps?: ComponentProps<typeof Component>,
  ): GridBaseColDef["renderCell"] =>
  props => <Component {...props} {...componentProps} />;

/**
 * MUI Grid `renderEditCell` function cannot directly call any hooks. This function wraps a `renderEditCell` function
 * to treat it as a normal component such that it can use React hooks as normal.
 */
const wrapRenderEditCell =
  (
    Component: FC<GridRenderEditCellParams>,
    componentProps?: ComponentProps<typeof Component>,
  ): GridBaseColDef["renderEditCell"] =>
  props => <Component {...props} {...componentProps} />;

type WorkPacketGridColOptions<T extends object> = Omit<GridBaseColDef<T>, "field" | "headerName"> & {
  preCaseOnly?: boolean;
};

/**
 * Creates a column definition with preset defaults, verifying that `field` is a field of the expected work packet type,
 * and wrapping `renderCell` and `renderEditCell` into normal components so that they can use React hooks.
 */
function column<T extends WorkPacketType, F extends WorkPacketField<T>>(
  field: F,
  headerName: string,
  options: WorkPacketGridColOptions<WorkPacketTypeMap[T]> = EMPTY_OBJECT,
): WorkPacketGridCol<T, F> {
  const { renderCell, renderEditCell, ...otherOptions } = options;
  return {
    field,
    headerName,
    width: 200,
    sortable: false,
    filterable: false,
    renderCell: renderCell && wrapRenderCell(renderCell),
    renderEditCell: renderEditCell && wrapRenderEditCell(renderEditCell),
    ...otherOptions,
  };
}

type WorkPacketGridDotPathColOptions<T extends object> = Omit<WorkPacketGridColOptions<T>, "valueGetter">;

/**
 * Creates a column definition with preset defaults, using a type-checked dot notation `path` to a property of the
 * packet object.
 */
function dotPathColumn<T extends WorkPacketType, P extends Paths<WorkPacketTypeMap[T], { bracketNotation: true }>>(
  path: P,
  headerName: string,
  options: WorkPacketGridDotPathColOptions<WorkPacketTypeMap[T]> = EMPTY_OBJECT,
): WorkPacketGridCol<T> {
  return column(path as WorkPacketField<T>, headerName, {
    valueGetter: (_, packet) => getProperty(packet, path),
    ...options,
  });
}

function currentOwnerColumn<T extends WorkPacketType>(): WorkPacketGridCol<T> {
  return column("currentPacketOwner", "Current Packet Owner", {
    width: 300,
    sortable: true,
    editable: true,
    renderCell: CurrentOwnerDisplay,
    renderEditCell: CurrentOwnerEdit,
  });
}

function currentActionColumn<T extends WorkPacketType>(): WorkPacketGridCol<T> {
  return column("currentAction", "Current Action", {
    type: "string",
    width: 300,
    sortable: true,
    editable: true,
    renderCell: CurrentActionDisplay,
    renderEditCell: CurrentActionEdit,
  });
}

function detailsColumn<T extends WorkPacketType>(): WorkPacketGridCol<T> {
  return column("_details", "", {
    width: 120,
    align: "center",
    hideable: false,
    renderCell: DetailsButton,
    cellClassName: "bg-gray-100",
  });
}

function generateObarColumn(): WorkPacketGridCol<WorkPacketType.ACCRUALS> {
  return column("_generateObar", "", {
    display: "flex",
    align: "center",
    hideable: false,
    renderCell: GenerateObarButton,
    cellClassName: "bg-gray-100",
  });
}

const renderMoneyCell = (params: GridRenderCellParams<any, number>) =>
  params.value != null
    ? new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(params.value)
    : "";

const renderDayjsCell = (params: GridRenderCellParams<any, number>) =>
  isDayjs(params.value) ? params.value.format("YYYY-MM-DD") : params.value;

// TODO(daniel): consolidate accrual stage names in one place
const accrualStageNames = ["First", "Second", "Third"];

function createAccrualDisputeColumns(): WorkPacketGridCol<WorkPacketType.ACCRUALS>[] {
  return accrualStageNames.flatMap((_stageName, index) => {
    return [
      dotPathColumn(`disputes[${index}].disputeAmount`, "Dispute Amount", { sortable: true }),
      dotPathColumn(`disputes[${index}].disputeDate`, "Dispute Date", { sortable: true }),
      dotPathColumn(`disputes[${index}].originalDisputeId`, "Original Dispute ID"),
      dotPathColumn(`disputes[${index}].consolidatedDisputeId`, "Consolidated Dispute ID"),
      dotPathColumn(`disputes[${index}].disputeStatus`, "Dispute Status", { sortable: true }),

      // dispute resolution
      dotPathColumn(`disputes[${index}].approvedAmount`, "Approved Amount", { sortable: true }),
      dotPathColumn(`disputes[${index}].disputeResolutionDate`, "Dispute Resolution Date", { sortable: true }),
      dotPathColumn(`disputes[${index}].paidAmount`, "Paid Amount", { sortable: true }),
      dotPathColumn(`disputes[${index}].reversalInvoiceNumber`, "Reversal Invoice Number"),
    ];
  });
}

type WorkPacketGridColMap = {
  [T in WorkPacketType]: WorkPacketGridCol<T>[];
};

const gridColumnMap: WorkPacketGridColMap = {
  [WorkPacketType.CHARGEBACKS]: [
    column("packetId", "Packet ID", { width: 150, sortable: true }),
    column("vendorName", "Vendor Name", { sortable: true }),
    column("storeName", "Store Name", { sortable: true }),
    currentOwnerColumn(),
    currentActionColumn(),
    column("recoveryStream", "Recovery Stream", { sortable: true }),
    column("recoveryStreamSubtype", "Recovery Stream Type", { width: 300, sortable: true }),
    column("recoveryStreamSubtype1", "Recovery Stream Subtype 1", { width: 300, sortable: true }),
    column("recoveryStreamSubtype2", "Recovery Stream Subtype 2", { width: 300, sortable: true }),
    // column("packetStage", "Packet Stage", { width: 150, sortable: true }),
    column("vcPoId", "VC PO ID", { width: 150, sortable: true }),
    column("asinId", "ASIN ID", { width: 150, sortable: true }),
    column("inboundShipmentDeliveryIsdId", "ISD", { width: 150, sortable: true }),
    column("chargebackIssueId", "Chargeback Issue ID"),
    column("freightTerms", "Freight Terms"),
    column("manualFilingUser", "Manual Filing user"),
    column("techUser", "Tech user"),
    column("financialCharge", "Financial Charge"),
    column("reversedAmount", "Reversed Amount"),
    column("chargebackCreateDate", "Chargeback Create Date", { sortable: true }),
    column("disputeByDate", "Dispute By Date", { sortable: true }),
    column("calculatedDisputeByDate", "Calculated Dispute By Date", { sortable: true }),
    column("firstDisputeCreatedAt", "First Dispute Created At", { sortable: true }),
    column("secondDisputeCreatedAt", "Second Dispute Created At", { sortable: true }),
    detailsColumn(),
  ],

  [WorkPacketType.SHORTAGES]: [
    column("packetId", "Packet ID", { width: 150, sortable: true }),
    column("vendorName", "Vendor Name", { sortable: true }),
    column("storeName", "Store Name", { sortable: true }),
    currentOwnerColumn(),
    currentActionColumn(),
    column("recoveryStreamServer", "Recovery Stream"),
    column("escalationCount", "Number Of Escalations"),
    // column("packetStage", "Packet Stage", { width: 150 }),
    column("remainingOpenBalance", "Remaining Open Balance", {
      sortable: true,
      headerClassName: css.remainingOpenBalance,
    }),
    column("disputeId", "Dispute ID"),
    column("disputeAmount", "Dispute Amount", { renderCell: renderMoneyCell }),
    column("disputeCreatedAt", "Dispute Created At"),
    column("vcParentInvoiceId", "VC Parent Invoice ID"),
    column("vcDisputedInvoiceId", "VC Disputed Invoice ID"),
    column("lastChildInvoiceId", "Last Child Invoice ID"),
    column("invoiceDate", "Invoice Date"),
    column("invoiceDueDate", "Invoice Due Date"),
    column("disputeByDate", "Dispute By Date"),
    detailsColumn(),
  ],

  [WorkPacketType.ACCRUALS]: [
    column("packetId", "Packet ID", { width: 150, sortable: true }),
    column("vendorName", "Vendor Name", { sortable: true }),
    column("storeName", "Store Name", { sortable: true }),
    column("manualFilingUser", "Manual Filing User", { sortable: true }),
    column("techUser", "Tech User", { sortable: true }),
    currentOwnerColumn(),
    currentActionColumn(),
    column("monitoringDate", "Monitoring Date", { sortable: true, renderCell: renderDayjsCell }),
    column("createdAt", "OBAR Created Date", { sortable: true }),
    column("subType", "Recovery Stream Subtype"),
    column("agreementId", "Agreement ID"),
    column("invoiceId", "Invoice Number"),
    column("invoiceDate", "Invoice Date"),
    column("currency", "Currency"),
    column("invoiceAmount", "Invoice Amount"),
    ...createAccrualDisputeColumns(),
    // TODO: Remove this commented out code after demo
    // column("sumOfAllReversalPayments", "Sum of All Reversal Payments"),
    // column("recoveryRate", "Recovery Rate"),
    generateObarColumn(),
    detailsColumn(),
  ],
};

export function getWorkPacketsGridColumns<T extends WorkPacketType>(workPacketType: T): WorkPacketGridCol<T>[] {
  return gridColumnMap[workPacketType];
}

const columnGroupings: Partial<Record<WorkPacketType, GridColumnGroupingModel>> = {
  [WorkPacketType.ACCRUALS]: [
    ...accrualStageNames.flatMap((stageName, index) => [
      {
        groupId: `${stageName} Trigger Dispute`,
        children: [
          { field: `disputes[${index}].disputeAmount` },
          { field: `disputes[${index}].disputeDate` },
          { field: `disputes[${index}].originalDisputeId` },
          { field: `disputes[${index}].consolidatedDisputeId` },
          { field: `disputes[${index}].disputeStatus` },
        ],
      },
      {
        groupId: `${stageName} Trigger Resolution`,
        children: [
          { field: `disputes[${index}].approvedAmount` },
          { field: `disputes[${index}].disputeResolutionDate` },
          { field: `disputes[${index}].paidAmount` },
          { field: `disputes[${index}].reversalInvoiceNumber` },
        ],
      },
    ]),
    {
      groupId: "Final Recovery Results",
      children: [{ field: "sumOfAllReversalPayments" }, { field: "recoveryRate" }],
    },
  ],
};

export function getWorkPacketGridColumnGrouping(workPacketType: WorkPacketType): GridColumnGroupingModel | undefined {
  return columnGroupings[workPacketType];
}
