import { useMutation, useQuery } from "@tanstack/react-query";
import { getCaseAttachmentList, uploadCaseAttachments, type AttachmentUploadVariables } from "../../queries";
import { updateCase } from "../../api/casesApi";
import { useFileUploadStore } from "src/pages/UserDashboard/WorkPackets/WorkPacketDetailsPopup/Summary/useFileUploadStore";
import toast from "react-hot-toast";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { queryClient } from "src/query-client";

interface UpdateMutationVars {
  caseId: string;
  caseType: CaseType;
  updateParams: Record<string, any>;
}

const onCaseUpdateSuccess = (_response: unknown, { caseId, caseType }: UpdateMutationVars) => {
  toast.success("Case updated");
  return Promise.all([
    queryClient.invalidateQueries({ queryKey: ["cases", caseType, caseId, "items"] }),
    queryClient.invalidateQueries({ queryKey: ["cases", caseId, "details"] }),
    queryClient.invalidateQueries({ queryKey: ["case-logs", caseId, caseType] }),
  ]);
};

export const useCaseUpdate = (caseId: string) => {
  const existingAttachmentsQuery = useQuery({
    queryFn: getCaseAttachmentList,
    queryKey: ["cases", caseId, "attachments"],
  });

  const attachmentUploadMutation = useMutation({
    mutationKey: ["case-attachment-upload", caseId],
    mutationFn: ({ caseItem, fileUploads, setFileUploadStatus }: AttachmentUploadVariables) =>
      uploadCaseAttachments({ caseItem, fileUploads, setFileUploadStatus }),
    onMutate: () => void toast("Uploading attachments..."),
    onSuccess: () => void toast.success("Files uploaded!"),
    onError: error => {
      console.error("Upload error:", error);
      toast.error("Attachment upload failed");
    },
  });

  const caseUpdateMutation = useMutation({
    mutationKey: ["cases", caseId, "details-update"],
    mutationFn: ({ caseId, caseType, updateParams }: UpdateMutationVars) => updateCase(caseId, caseType, updateParams),
    onSuccess: onCaseUpdateSuccess,
  });

  const attachmentsUploadStore = useFileUploadStore({
    existingFiles: existingAttachmentsQuery.data ?? [],
    isLoadingExistingFiles: existingAttachmentsQuery.isFetching,
    isUploading: false,
  });

  return {
    existingAttachmentsQuery,
    attachmentUploadMutation,
    caseUpdateMutation,
    attachmentsUploadStore,
    isSubmitting: caseUpdateMutation.isPending,
  };
};
