import { Box, Button, CircularProgress, Collapse, IconButton, Menu, MenuItem } from "@mui/material";
import { DataGridPro, type GridPinnedColumnFields, useGridApiRef } from "@mui/x-data-grid-pro";
import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { WorkPacketsGridFilters } from "./filters/WorkPacketsGridFilters";
import { getWorkPacketGridColumnGrouping, getWorkPacketsGridColumns } from "./helpers/getWorkPacketsGridColumns";
import { CloudDownloadOutlined, FilterList } from "@mui/icons-material";
import { WorkPacketDetailsPopup } from "../WorkPacketDetailsPopup";
import { useWorkPacketsGrid } from "../useWorkPacketsGrid";
import useColumnVisibility from "src/utils/grid/useColumnVisibility";
import { WorkPacketView } from "../WorkPacketView";
import { BulkUpdatePopup } from "./BulkUpdatePopup";
import usePermissions from "components/CustomHooks/usePermissions";
import { useWorkPacketsContext } from "../WorkPacketsContext";
import { WorkPacketType } from "../WorkPacketType";
import { WorkPacketPopupContext } from "./WorkPacketPopupContext";
import { CreateCasePopup } from "src/pages/UserDashboard/Cases/CreateCasePopup";
import { StagelessAction, WorkPacket, WorkPacketFilter } from "src/types/work-packets.ts";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { createStagelessAction } from "../mappers";
import { CaseType } from "../Cases/case-types";
import { useFeatureFlag } from "src/feature-flags";
import { WORK_PACKET_SUPERVISOR_PERMISSIONS } from "src/pages/UserDashboard/WorkPackets/WorkPacketPermission";

const pinnedColumns: GridPinnedColumnFields = {
  right: ["_generateObar", "_details"],
};

interface WorkPacketsGridProps<T extends WorkPacketType> {
  workPacketType: T;
  onDataUpdate?: () => void;
}

const getFormattedCurrencyValue = (formattedValue: string): number =>
  Number.parseFloat(formattedValue.replaceAll("$", "").replaceAll(",", ""));

const getOpenBalance = (packet: WorkPacket): number =>
  "remainingOpenBalance" in packet ? getFormattedCurrencyValue(packet.remainingOpenBalance) : 0;

const WorkPacketsGrid = function WorkPacketsGrid<T extends WorkPacketType>({
  workPacketType,
  onDataUpdate,
}: WorkPacketsGridProps<T>) {
  const { isAdmin, hasPermission } = usePermissions();
  const isSupervisor = hasPermission(WORK_PACKET_SUPERVISOR_PERMISSIONS[workPacketType]);
  const canExportCsv = isAdmin || isSupervisor;
  
  const apiRef = useGridApiRef();
  const [showFilters, setShowFilters] = useState(true);
  const [currentPacketId, setCurrentPacketId] = useState<string | null>(null);
  const [showBulkUpdatePopup, setShowBulkUpdatePopup] = useState(false);
  const [showCreateCasePopup, setShowCreateCasePopup] = useState(false);

  const { currentFilters, currentView: view, setFilters } = useWorkPacketsContext();
  const isPreCaseView = !!currentFilters[WorkPacketFilter.PreCase];

  const {
    data,
    paginationModel,
    total,
    loading,
    filters,
    rowSelection,
    setRowSelection,
    callbacks,
    selectAllEnabled,
    excludeWorkPacketIds,
    resetSelection,
    triggerFetchWorkPackets,
    rowUpdateSignal,
    handleExport,
    isExporting,
    localeText,
  } = useWorkPacketsGrid(workPacketType);

  useEffect(() => {
    onDataUpdate && onDataUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowUpdateSignal]);

  const allowEditPacketActionAndNotes = isAdmin || view === WorkPacketView.MyPackets || (isPreCaseView && isSupervisor);
  const { value: showSmartSelect } = useFeatureFlag("PRE_CASE_SMART_SELECT", false);

  const columns = getWorkPacketsGridColumns(workPacketType);
  const demoColumns = useMemo(
    () =>
      isPreCaseView
        ? columns.filter(col => col.field !== "recoveryStreamServer")
        : columns.filter(col => !["remainingOpenBalance", "escalationCount", "lastChildInvoiceId"].includes(col.field)),
    [columns, isPreCaseView],
  );
  const columnGroupingModel = getWorkPacketGridColumnGrouping(workPacketType);

  const { columnVisibilityModel, handleColumnVisibilityChange } = useColumnVisibility("work-packets-grid");

  const toggleFilters = () => {
    setShowFilters(prev => !prev);
  };

  const isCellEditable = useCallback(() => allowEditPacketActionAndNotes, [allowEditPacketActionAndNotes]);

  // negative pending smart select count means that it is waiting to enter loading state
  // once loading state is entered, pending count is flipped to positive and waits to exit loading state
  // before applying selection
  const [smartSelectCaseType, setSmartSelectCaseType] = useState<CaseType | undefined>();
  const [pendingSmartSelectCount, setPendingSmartSelectCount] = useState<number>(0);
  const smartSelect = (count: number, caseType: CaseType) => {
    callbacks.onPaginationModelChange({ page: 0, pageSize: 50 });
    callbacks.onSortModelChange([{ field: "remainingOpenBalance", sort: "desc" }]);
    setPendingSmartSelectCount(-count);
    setSmartSelectCaseType(caseType);
    setFilters({
      ...currentFilters,
      [WorkPacketFilter.CurrentAction]: createStagelessAction(StagelessAction.ReadyForReview).value,
    });
  };
  useEffect(() => {
    if (pendingSmartSelectCount < 0 && loading) {
      setPendingSmartSelectCount(-pendingSmartSelectCount);
    }
    if (pendingSmartSelectCount > 0 && !loading) {
      setPendingSmartSelectCount(0);
      if (pendingSmartSelectCount === 1) {
        setRowSelection([data[0].packetId]);
        return;
      }

      const half = pendingSmartSelectCount / 2;

      const firstHalf = data.slice(0, half).map(p => p.packetId);
      const secondHalf = data
        .slice(half)
        .sort((a, b) => getOpenBalance(b) - getOpenBalance(a))
        .slice(0, half)
        .map(p => p.packetId);

      setRowSelection([...firstHalf, ...secondHalf]);
    }
  }, [callbacks, data, loading, pendingSmartSelectCount, setRowSelection]);

  return (
    <>
      <Box bgcolor="#fff" p={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
          <Box display="flex" gap="12px">
            <Button
              startIcon={<FilterList />}
              variant="outlined"
              onClick={toggleFilters}
              className={showFilters ? "" : "active"}
            >
              Filters
            </Button>
          </Box>

          <Box display="flex" gap="12px">
            {showSmartSelect && (
                <SmartSelectMenu
                  disabled={!currentFilters[WorkPacketFilter.StoreName] || loading}
                  smartSelect={smartSelect}
                />
            )}
            {[WorkPacketType.SHORTAGES, WorkPacketType.CHARGEBACKS].includes(workPacketType) && isPreCaseView && (
              <Button
                  variant="outlined"
                  disabled={rowSelection.length === 0}
                  onClick={() => setShowCreateCasePopup(true)}
                >
                  Attach a Case ID
                </Button>
            )}
            <Button
              variant="outlined"
              disabled={rowSelection.length === 0}
              onClick={() => {
                console.log("Current selection", { rowSelection });
                setShowBulkUpdatePopup(true);
              }}
            >
              Bulk Actions
            </Button>
            {canExportCsv && (
              <IconButton className="outlined" onClick={handleExport} disabled={loading || isExporting}>
                <CloudDownloadOutlined />

                {isExporting && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
              </IconButton>
            )}
          </Box>
        </Box>

        <Collapse in={showFilters}>
          <WorkPacketsGridFilters loading={loading} />
        </Collapse>

        <Box position="relative" overflow="auto">
          <Box maxHeight="70vh" display="flex" flexDirection="column">
            <WorkPacketPopupContext.Provider value={setCurrentPacketId}>
              <DataGridPro
                {...callbacks}
                apiRef={apiRef}
                rows={data}
                isCellEditable={isCellEditable}
                columns={demoColumns}
                pinnedColumns={pinnedColumns}
                columnGroupingModel={columnGroupingModel}
                getRowId={row => row.packetId}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                rowCount={total}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                filterMode="server"
                sortingMode="server"
                rowSelectionModel={rowSelection}
                keepNonExistentRowsSelected
                checkboxSelection
                disableRowSelectionOnClick
                loading={loading}
                rowHeight={70}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                localeText={localeText}
              />
            </WorkPacketPopupContext.Provider>
          </Box>
        </Box>
      </Box>

      {currentPacketId != null && (
        <WorkPacketDetailsPopup
          workPacketId={currentPacketId}
          open
          onClose={() => setCurrentPacketId(null)}
          onSuccessfulUpdate={() => {
            triggerFetchWorkPackets();

            // Trigger summary data re-fetch
            onDataUpdate && onDataUpdate();
          }}
        />
      )}

      {showBulkUpdatePopup && (
        <BulkUpdatePopup
          open={showBulkUpdatePopup}
          onClose={(success?: boolean) => {
            setShowBulkUpdatePopup(false);

            // Refetch data if the bulk update was successful
            if (success) {
              triggerFetchWorkPackets();
              resetSelection();

              // Trigger summary data re-fetch
              onDataUpdate && onDataUpdate();
            }
          }}
          workPacketIds={rowSelection as string[]}
          filters={filters}
          selectedAll={selectAllEnabled}
          excludeIds={excludeWorkPacketIds}
          allowEditPacketActionAndNotes={allowEditPacketActionAndNotes}
        />
      )}
      {showCreateCasePopup && (
        <CreateCasePopup
          open={showCreateCasePopup}
          excludedWorkPacketsIds={excludeWorkPacketIds}
          filters={filters}
          onClose={(success?: boolean) => {
            setShowCreateCasePopup(false);

            // Refetch data if case was created successfully
            if (success) {
              triggerFetchWorkPackets();
              resetSelection();

              // Trigger summary data re-fetch
              onDataUpdate && onDataUpdate();
            }
          }}
          selectedWorkPacketsIds={selectAllEnabled ? [] : (rowSelection as string[])}
          prepopulatedCaseType={smartSelectCaseType}
          workPacketType={workPacketType}
        />
      )}
    </>
  );
};

const SmartSelectMenu: FC<{
  disabled: boolean;
  smartSelect: (count: number, caseType: CaseType) => void;
}> = ({ disabled, smartSelect }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const onButtonClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const onMenuClose = () => setAnchorEl(null);

  return (
    <>
      <Button variant="outlined" onClick={onButtonClick} endIcon={<KeyboardArrowDownIcon />} disabled={disabled}>
        Smart Select
      </Button>
      <Menu open={anchorEl !== null} anchorEl={anchorEl} onClose={onMenuClose}>
        <MenuItem onClick={() => (smartSelect(1, CaseType.SHORTAGE_ESCALATION), onMenuClose())}>Escalation</MenuItem>
        <MenuItem onClick={() => (smartSelect(10, CaseType.SHORTAGE_SETTLEMENT), onMenuClose())}>
          Settlement (New VC Screen)
        </MenuItem>
        <MenuItem onClick={() => (smartSelect(40, CaseType.SHORTAGE_SETTLEMENT), onMenuClose())}>
          Settlement (Old VC Screen)
        </MenuItem>
      </Menu>
    </>
  );
};

export default WorkPacketsGrid;
