import { type GridColDef, type GridRenderCellParams, type GridRenderEditCellParams } from "@mui/x-data-grid";
import { type GridBaseColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import { type ComponentProps, type FC } from "react";
import { EMPTY_OBJECT } from "src/utils/empty-values";
import { CaseType, caseColMap, CaseFields, EscalationCaseFields, SettlementCaseFields } from "./case-types";
import { CaseActionDisplay } from "./columns/CaseActionDisplay";
import { DetailsButton } from "./columns/DetailsButton";

const renderMoneyCell = (params: GridRenderCellParams<any, number>) =>
  params.value !== undefined
    ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(params.value)
    : "";

/**
 * MUI Grid `renderCell` function cannot directly call any hooks. This function wraps a `renderCell` function
 * to treat it as a normal component such that it can use React hooks as normal.
 */
const wrapRenderCell =
  (
    Component: FC<GridRenderCellParams>,
    componentProps?: ComponentProps<typeof Component>,
  ): GridBaseColDef["renderCell"] =>
  props =>
    <Component {...props} {...componentProps} />;

/**
 * MUI Grid `renderEditCell` function cannot directly call any hooks. This function wraps a `renderEditCell` function
 * to treat it as a normal component such that it can use React hooks as normal.
 */
const wrapRenderEditCell =
  (
    Component: FC<GridRenderEditCellParams>,
    componentProps?: ComponentProps<typeof Component>,
  ): GridBaseColDef["renderEditCell"] =>
  props =>
    <Component {...props} {...componentProps} />;

type GridColOptions = Omit<GridBaseColDef, "field" | "headerName">;

/**
 * Creates a column definition with preset defaults, verifying that `field` is a field of the expected work packet type,
 * and wrapping `renderCell` and `renderEditCell` into normal components so that they can use React hooks.
 */
function column(caseField: CaseFields, options: GridColOptions = EMPTY_OBJECT): GridBaseColDef {
  const { renderCell, renderEditCell, ...otherOptions } = options;
  return {
    field: caseField,
    headerName: caseColMap[caseField],
    width: 200,
    sortable: false,
    filterable: false,
    renderCell: renderCell && wrapRenderCell(renderCell),
    renderEditCell: renderEditCell && wrapRenderEditCell(renderEditCell),
    ...otherOptions,
  };
}

function detailsColumn(): GridBaseColDef {
  return {
    field: "_details",
    headerName: "",
    width: 120,
    display: "flex",
    sortable: false,
    filterable: false,
    renderCell: wrapRenderCell(DetailsButton),
  };
}

export const caseTypeColumnMap: Record<CaseType, GridColDef[]> = {
  [CaseType.CHARGEBACK_ESCALATION]: [
    column(EscalationCaseFields.ChargeGuardCaseId),
    column(EscalationCaseFields.AmazonCaseId, { sortable: true }),
    column(EscalationCaseFields.NumOfPackets, { sortable: true }),
    column(EscalationCaseFields.VendorName, { sortable: true }),
    column(EscalationCaseFields.StoreName, { sortable: true }),
    column(EscalationCaseFields.CurrentCaseOwner, { sortable: true }),
    column(EscalationCaseFields.CurrentAction, { renderCell: CaseActionDisplay }),
    column(EscalationCaseFields.RecoveryStreamType),
    column(EscalationCaseFields.RecoveryStreamSubtype1),
    column(EscalationCaseFields.RecoveryStreamSubtype2),
    column(EscalationCaseFields.CreatedAt, { sortable: true }),
    column(EscalationCaseFields.ManualFilingUser, { sortable: true }),
    column(EscalationCaseFields.CaseFilingUser, { sortable: true }),
    column(EscalationCaseFields.DisputedAmount, { renderCell: renderMoneyCell }),
    column(EscalationCaseFields.ApprovedAmount, { renderCell: renderMoneyCell }),
    column(EscalationCaseFields.ApprovedDate),
    column(EscalationCaseFields.PaidAmount, { renderCell: renderMoneyCell }),
    column(EscalationCaseFields.PO),
    column(EscalationCaseFields.ASIN),
    detailsColumn(),
  ],

  [CaseType.SHORTAGE_ESCALATION]: [
    column(EscalationCaseFields.ChargeGuardCaseId),
    column(EscalationCaseFields.AmazonCaseId, { sortable: true }),
    column(EscalationCaseFields.VendorName, { sortable: true }),
    column(EscalationCaseFields.StoreName, { sortable: true }),
    column(EscalationCaseFields.CurrentCaseOwner, { sortable: true }),
    column(EscalationCaseFields.Processor, { sortable: true }),
    column(EscalationCaseFields.CurrentAction, { renderCell: CaseActionDisplay }),
    column(EscalationCaseFields.CreatedAt, { sortable: true }),
    column(EscalationCaseFields.ManualFilingUser, { sortable: true }),
    column(EscalationCaseFields.CaseFilingUser, { sortable: true }),
    column(EscalationCaseFields.RemainingOpenBalance, { renderCell: renderMoneyCell, sortable: true }),
    column(EscalationCaseFields.ApprovedAmount, { renderCell: renderMoneyCell }),
    detailsColumn(),
  ],

  [CaseType.SHORTAGE_SETTLEMENT]: [
    column(SettlementCaseFields.ChargeGuardCaseId),
    column(SettlementCaseFields.AmazonCaseId, { sortable: true }),
    column(SettlementCaseFields.NumOfPackets, { sortable: true }),
    column(SettlementCaseFields.VendorName, { sortable: true }),
    column(SettlementCaseFields.StoreName, { sortable: true }),
    column(SettlementCaseFields.CurrentCaseOwner, { sortable: true }),
    column(EscalationCaseFields.ManualFilingUser, { sortable: true }),
    column(SettlementCaseFields.CaseFilingUser, { sortable: true }),
    // column(SettlementCaseFields.PresubmissionRequired, { renderCell: renderBooleanCell, sortable: true }),
    // column(SettlementCaseFields.Business),
    column(SettlementCaseFields.Payee),
    column(SettlementCaseFields.VendorCodes),
    column(SettlementCaseFields.NumOfSettlements),
    column(SettlementCaseFields.InvoiceStartDueDate),
    column(SettlementCaseFields.InvoiceEndDueDate),
    // column(SettlementCaseFields.Sprinter),
    // column(SettlementCaseFields.SprinterViewedAt),
    // column(SettlementCaseFields.Processor, { sortable: true, renderCell: UserDisplay }),
    // column(SettlementCaseFields.ProcessorViewedAt),
    column(SettlementCaseFields.SubmissionPath, { sortable: true }),
    column(SettlementCaseFields.CurrentCaseNumber),
    // column(SettlementCaseFields.Link),
    column(SettlementCaseFields.Stage, { sortable: true }),
    column(SettlementCaseFields.SubmissionAmt, { renderCell: renderMoneyCell }),
    column(SettlementCaseFields.SubmissionDate),
    column(SettlementCaseFields.AlignedBalanceAmount, { renderCell: renderMoneyCell }),
    column(SettlementCaseFields.CurrentRRCalc, { renderCell: renderMoneyCell }),
    column(SettlementCaseFields.CurrentAmount, { renderCell: renderMoneyCell }),
    column(SettlementCaseFields.ValidAmount, { renderCell: renderMoneyCell }),
    column(SettlementCaseFields.FinalRecoveryRateCalc, { renderCell: renderMoneyCell }),
    detailsColumn(),
  ],
};
