import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  type FilterOptionsState,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { type FieldProps } from "formik";
import { type ClipboardEvent, type FC, useCallback, useMemo, useState } from "react";

type Option = string | { inputValue: string; title: string };

export interface MultiAutocompleteFieldProps extends FieldProps<string[]> {
  id?: string;
  label?: string;
  options: any[];
  groupBy?: (option: any) => string;
  placeholder?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  disabled?: boolean;
  disableClearable?: boolean;
  disableSuggestions?: boolean;
}

const filter = createFilterOptions<Option>();

const NullComponent = () => null;

const filterOptions: (options: Option[], state: FilterOptionsState<Option>) => Option[] = (options, state) => {
  const filtered = filter(options, state);
  const { inputValue } = state;
  const trimmed = inputValue.trim();
  const exists = options.some(option => option === trimmed);
  if (inputValue && !exists) {
    filtered.push({
      inputValue,
      title: `Other: "${inputValue}"`,
    });
  }
  return filtered;
};

const getOptionValue = (option: Option) => typeof option === "string" ? option : option.inputValue ?? option.title;

type ListOptionProps = React.HTMLAttributes<HTMLLIElement> & { key: any };

const renderOption = (props: ListOptionProps, option: Option) => (
  <li {...props} className={`autocomplete-menu-item ${props.className}`} key={props.key}>
    <span className="autocomplete-menu-item__text">{typeof option === "string" ? option : option.title}</span>
  </li>
);

const domParser = new DOMParser();

/** Creates a `ClipboardEvent` handler that handles potentially tabular data (eg Excel, Google Sheets). */
const createPasteHandler =
  (onIncomingValues: (incomingValues: string[]) => void) => (event: ClipboardEvent<HTMLDivElement>) => {
    const html = event.clipboardData.getData("text/html");
    if (!html) return;

    const doc = domParser.parseFromString(html, "text/html");
    const table = doc.querySelector("table");
    if (!table) return;

    event.preventDefault();
    const incomingValues = Array.from(table.querySelectorAll("td"), cell => cell.innerText.trim());
    onIncomingValues(incomingValues);
  };

export const MultiAutocompleteField: FC<MultiAutocompleteFieldProps> = ({
  id,
  label,
  options,
  groupBy,
  placeholder,
  setFieldValue,
  field,
  disabled,
  disableClearable,
  disableSuggestions,
}) => {
  const [inputValue, setInputValue] = useState("");

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} variant="outlined" placeholder={placeholder} label={label} />
    ),
    [placeholder, label],
  );

  const computedOptions = useMemo(() => (groupBy ? groupAutocompleteOptions(options) : options), [groupBy, options]);

  const onIncomingValues = useCallback(
    (incomingValues: string[]) => {
      const newValues = incomingValues
        .filter(incomingValue => !field.value.includes(incomingValue))
        .map(val => val.trim())
        .filter(val => val.length > 0);
      setFieldValue(field.name, [...field.value, ...newValues]);
    },
    [field.name, field.value, setFieldValue],
  );

  const pasteHandler = useMemo(() => createPasteHandler(onIncomingValues), [onIncomingValues]);

  return (
    <Autocomplete
      onPaste={pasteHandler}
      onBlur={() => onIncomingValues(inputValue.split(","))}
      id={id}
      size="small"
      freeSolo
      multiple
      value={field.value}
      groupBy={groupBy}
      options={computedOptions}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
      renderInput={renderInput}
      onChange={(_event, newValues: Option[]) =>
        setFieldValue(
          field.name,
          newValues.flatMap(val => getOptionValue(val).split(",")),
        )
      }
      disabled={disabled}
      disableClearable={disableClearable}
      filterSelectedOptions
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      filterOptions={filterOptions}
      PaperComponent={disableSuggestions ? NullComponent : undefined}
      renderOption={renderOption}
      getOptionLabel={getOptionValue}
    />
  );
};

const groupAutocompleteOptions = (options: any[]) => {
  return options.map(option => {
    const firstLetter = option.category[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
};
