import * as Yup from "yup";
import { CaseDetailsKeys } from "src/pages/UserDashboard/Cases/mappers/mapServerToLocalCase";
import { ShortagesEscalationCaseItemStatus } from "src/pages/UserDashboard/Cases/api/constants";
import { ShortageWorkPacket } from "src/types/work-packets";

const isUnchanged = (a: any, b: any) => {
  if (a == null) {
    a = "";
  }
  if (b == null) {
    b = "";
  }
  return a === b;
};

export const createShortageWorkPacketSchema = (initialValues: ShortageWorkPacket) =>
  Yup.object().shape({
    escalations: Yup.array().of(
      Yup.object()
        .shape({
          status: Yup.string().notRequired(),
          remainingOpenBalance: Yup.string().notRequired(),
          approvedAmount: Yup.string()
            .notRequired()
            .test(
              `${CaseDetailsKeys.ApprovedAmount}-${CaseDetailsKeys.RemainingOpenBalance}-equal`,
              "The Approved Amount does not equal the Remaining Open Balance",
              (value, ctx) => {
                const status = ctx.parent["status"];
                if (status === ShortagesEscalationCaseItemStatus.APPROVED) {
                  const remainingOpenBalance = parseFloat(ctx.parent["remainingOpenBalance"]);
                  if (parseFloat(value ? value : "0") != remainingOpenBalance) {
                    return false;
                  }
                }
                return true;
              },
            ),
        })
        .notRequired(),
    ),
    vcDisputedInvoiceId: Yup.string().test(
      "requiredWhenDisputeIdHasChanged",
      "VC Disputed Invoice ID is required, when setting a dispute ID",
      (value, ctx) => {
        if (initialValues.isHistoric && initialValues.clientRegion === "US") {
          return true;
        }
        if (isUnchanged(initialValues.disputeId, ctx.parent["disputeId"])) {
          return true;
        }

        return !!value;
      },
    ),
    disputeAmount: Yup.string().test(
      "requiredWhenDisputeIdHasChanged",
      "VC Disputed Invoice ID is required, when setting a dispute ID",
      (value, ctx) => {
        if (initialValues.isHistoric && initialValues.clientRegion === "US") {
          return true;
        }
        if (isUnchanged(initialValues.disputeId, ctx.parent["disputeId"])) {
          return true;
        }

        return !!value;
      },
    ),
    clientDisputed: Yup.string().test(
      "disputeIdRequiredWhenSettingClientDisputed",
      "Dispute Id is required, when setting Client Disputed",
      (value, ctx) => {
        if (initialValues.isHistoric && initialValues.clientRegion === "US") {
          return true;
        }
        const clientDisputed = value == "true";
        if (clientDisputed === initialValues.clientDisputed) {
          return true;
        }
        return !!ctx.parent["disputeId"];
      },
    ),
  });

export const createChargebackWorkPacketSchema = () =>
  Yup.object().shape({
    escalations: Yup.array().of(
      Yup.object()
        .shape({
          approvedAmount: Yup.string()
            .notRequired()
            .test(
              `${CaseDetailsKeys.ApprovedAmount}-${CaseDetailsKeys.ApprovedDate}-exist`,
              "Approved Amount can't be empty",
              (value, ctx) => {
                const approvedDate = ctx.parent["approvedDate"];
                const approvedAmount = parseFloat(value ? value : "0");
                if (approvedDate !== undefined && approvedAmount === 0) {
                  return false;
                }
                return true;
              },
            ),
          approvedDate: Yup.string()
            .notRequired()
            .test(
              `${CaseDetailsKeys.ApprovedDate}-${CaseDetailsKeys.ApprovedAmount}-exist`,
              "Approved Date can't be empty",
              (value, ctx) => {
                const approvedAmountStr = ctx.parent["approvedAmount"] || "0";
                const approvedAmount = parseFloat(approvedAmountStr);
                const approvedDate = value;
                if (approvedAmount > 0 && approvedDate === undefined) {
                  return false;
                }
                return true;
              },
            ),
        })
        .notRequired(),
    ),
  });
