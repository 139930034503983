// import { Box, Divider, Typography } from "@mui/material";
// import { type FC, useCallback, useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { type FC } from "react";
import { ControlPanel } from "./ControlPanel";
// import {
//   MetricRowCard,
//   RecoverableSummaryCard,
//   SummaryByWorkPacketActionCard,
// } from "components/work-packets/metric-cards";
// import {
//   WorkPacketFilter,
//   WorkPacketMetric,
//   type WorkPacketsSummaryAggregated,
//   type WorkPacketSummaryFilters,
// } from "src/types/work-packets";
// import { WorkPacketView } from "../WorkPacketView";
// import * as WorkPacketsAPI from "../api/workPacketsAPI";
// import debounce from "lodash.debounce";
import { useWorkPacketsContext } from "../WorkPacketsContext";
// import { WorkPacketType } from "../WorkPacketType";
// import { SummaryByStatus } from "src/pages/UserDashboard/WorkPackets/WorkPacketsManager/SummaryByStatus.tsx";
// import { Formik } from "formik";

interface WorkPacketsManagerProps {
  /** Used to trigger a refetch when grid data changes.*/
  refetch?: boolean;
}

// export const WorkPacketsManager: FC<WorkPacketsManagerProps> = ({
//   refetch,
// }) => {
export const WorkPacketsManager: FC<WorkPacketsManagerProps> = () => {
  // const { currentView, setView, currentFilters: tableFilters, currentWorkPacketType } = useWorkPacketsContext();
  const { currentView, setView } = useWorkPacketsContext();
  // const [data, setData] = useState<WorkPacketsSummaryAggregated | null>(null);
  // const [loading, setLoading] = useState(false);
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setView(newValue);
  };

  // const isPreCase = !!tableFilters[WorkPacketFilter.PreCase];

  // const mapViewToAPI = {
  //   [WorkPacketView.AllPackets]: WorkPacketsAPI.fetchAllPacketsSummary,
  //   [WorkPacketView.NewPackets]: WorkPacketsAPI.fetchNewPacketsSummary,
  //   [WorkPacketView.MyPackets]: WorkPacketsAPI.fetchUserPacketsSummary,
  // };

  // const fetchSummaryData = async (view: WorkPacketView, type: WorkPacketType, filters: WorkPacketSummaryFilters) => {
  //   try {
  //     setLoading(true);
  //     const summaryData = await mapViewToAPI[view](type, isPreCase, filters);
  //     setData(summaryData);
  //   } catch (error) {
  //     console.error("Error fetching summary data", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  /* Preventing multiple requests especially on initial load */
  // const debouncedFetchSummaryData = useCallback(
  //   debounce(
  //     (currentView: WorkPacketView, type: WorkPacketType, filters) => {
  //       fetchSummaryData(currentView, type, filters);
  //     },
  //     300,
  //     { leading: true, trailing: false },
  //   ),
  //   [fetchSummaryData],
  // );

  // const [filters, setFilters] = useState<WorkPacketSummaryFilters>({
  //   [WorkPacketFilter.StoreName]: "",
  //   [WorkPacketFilter.DisputeCreatedAt]: "",
  //   [WorkPacketFilter.PreCase]: isPreCase,
  // });

  // useEffect(() => {
  //   setData(null);
  //   debouncedFetchSummaryData(currentView, currentWorkPacketType, filters);
  // }, [currentView, setData, refetch, currentWorkPacketType, filters]);

  return (
    <>
      <ControlPanel
        selectedTab={currentView}
        handleTabChange={handleTabChange}
      />

      {/* <Box bgcolor="#fff" padding={3}>
        <Typography variant="h6" marginBottom={2}>
          Summary
        </Typography>

        <Box
          display="flex"
          gap={1.5}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          {currentView === WorkPacketView.AllPackets && (
            <>
              <RecoverableSummaryCard
                total={Number(data?.recoverableAmount?.total)}
                {...(!isPreCase && {
                  pending: Number(data?.recoverableAmount?.pending),
                  approved: Number(data?.recoverableAmount?.approved),
                })}
                loading={loading}
              />

              <Box display="flex" flexDirection="column" flex={1} gap={1.5}>
                {[
                  {
                    name: WorkPacketMetric.TotalStores,
                    value: data?.totalStores || 0,
                  },
                  {
                    name: WorkPacketMetric.OpenWorkPackets,
                    value: data?.totalWorkPackets || 0,
                  },
                ].map((metric) => (
                  <MetricRowCard
                    key={metric.name}
                    {...metric}
                    loading={loading}
                  />
                ))}
              </Box>
            </>
          )}

          {currentView === WorkPacketView.NewPackets && (
            <>
              <RecoverableSummaryCard
                total={Number(data?.recoverableAmount?.total)}
                loading={loading}
              />

              <Box display="flex" flexDirection="column" flex={1} gap={1.5}>
                {[
                  {
                    name: WorkPacketMetric.TotalStores,
                    value: data?.totalStores || 0,
                  },
                  {
                    name: WorkPacketMetric.NewPackets,
                    value: data?.totalWorkPackets || 0,
                  },
                ].map((metric) => (
                  <MetricRowCard
                    key={metric.name}
                    {...metric}
                    loading={loading}
                  />
                ))}
              </Box>
            </>
          )}

          {currentView === WorkPacketView.MyPackets && (
            <>
              <SummaryByWorkPacketActionCard
                total={data?.totalWorkPackets || 0}
                actions={data?.workPackets || []}
                loading={loading}
              />

              <Box display="flex" flexDirection="column" flex={1} gap={1.5}>
                {[
                  {
                    name: WorkPacketMetric.TotalPacketsAssigned,
                    value: data?.totalWorkPackets || 0,
                  },
                  {
                    name: WorkPacketMetric.PacketsWithRedFlags,
                    value: data?.totalRedFlags || 0,
                  },
                ].map((metric) => (
                  <MetricRowCard
                    key={metric.name}
                    {...metric}
                    loading={loading}
                  />
                ))}
              </Box>
            </>
          )}
        </Box>
      </Box>

      {currentView === WorkPacketView.AllPackets && isPreCase && [WorkPacketType.SHORTAGES, WorkPacketType.CHARGEBACKS].includes(currentWorkPacketType) &&  (
        <Box bgcolor="#fff" padding={3} paddingTop={0}>
          <Formik initialValues={filters} onSubmit={setFilters} enableReinitialize>
            <SummaryByStatus rows={data?.byStatus || []} loading={loading} />
          </Formik>
        </Box>
      )} */}

      <Divider />
    </>
  );
};
