import css from "./AccrualDisputes.module.css";

import { Box, Button, TextField, Typography } from "@mui/material";
import { Field, FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { type FC, useCallback, useContext, useMemo } from "react";
import { type AccrualWorkPacket, StageName } from "src/types/work-packets";
import { capitalize } from "src/utils/capitalize";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";
import { confirmAlert, ReactConfirmAlertProps } from "react-confirm-alert";
import { type QueryClient, useMutation, type UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { createAccrualDispute } from "../../../api/workPacketsAPI";
import { DialogActionContext, DialogActionStore } from "../../DialogActionContext";
import { type WorkPacketType } from "../../../WorkPacketType";
import { CurrencyField } from "components/UI/Form/CurrencyField.tsx";

const getField = createFieldFn<AccrualWorkPacket>();

const stageNumberMap = [StageName.First, StageName.Second, StageName.Third];

enum DisputeAddBlocker {
  MissingDisputeAmount,
  RequiredFieldsMissing,
  MaxPaidAmountReached,
}

const disputeAddBlockerMessageMap: Record<DisputeAddBlocker, string> = {
  [DisputeAddBlocker.MissingDisputeAmount]: "Latest dispute is missing dispute amount.",
  [DisputeAddBlocker.RequiredFieldsMissing]: "Latest dispute is missing paid amount or reversal invoice number.",
  [DisputeAddBlocker.MaxPaidAmountReached]: "Latest dispute paid amount is >= 80% of dispute amount.",
};

interface CreateDisputeParams {
  workPacketId: string;
  workPacketType: WorkPacketType;
  dialogActionStore: DialogActionStore;
  queryClient: QueryClient;
}

const createDisputeMutationOptions: UseMutationOptions<void, Error, CreateDisputeParams> = {
  mutationFn: ({ workPacketId }) => createAccrualDispute(workPacketId),
  mutationKey: ["create-accrual-dispute"],

  onMutate: ({ dialogActionStore }) =>
    dialogActionStore.setPendingDialogAction("create-accrual-dispute", { description: "Creating accrual dispute" }),

  onSuccess: (_data, { workPacketId, workPacketType, queryClient }) =>
    queryClient.invalidateQueries({ queryKey: ["work-packets", workPacketType, "details", workPacketId] }),

  onSettled: (_data, _error, { dialogActionStore }) =>
    dialogActionStore.clearPendingDialogAction("create-accrual-dispute"),
};

const createDialogActions = (triggerMutation: () => void): ReactConfirmAlertProps => ({
  overlayClassName: css.dialogOverlay,
  title: "Add new accrual dispute",
  message: "A new accrual dispute will be added to the database immediately. This action cannot be undone.",
  buttons: [
    { label: "Cancel", className: css.cancelButton },
    { label: "Add a new accrual dispute now", className: css.addButton, onClick: triggerMutation },
  ],
});

const DisputeSections: FC<FieldArrayRenderProps & { packet: AccrualWorkPacket }> = ({ packet }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(createDisputeMutationOptions);
  const dialogActionStore = useContext(DialogActionContext);
  const { mutateAsync } = mutation;
  const { dirty } = useFormikContext();

  const onAddAccrualClick = useCallback(() => {
    const triggerMutation = () =>
      mutateAsync({
        workPacketId: packet.packetId,
        workPacketType: packet.workPacketType,
        dialogActionStore,
        queryClient,
      });
    confirmAlert(createDialogActions(triggerMutation));
  }, [dialogActionStore, mutateAsync, packet.packetId, packet.workPacketType, queryClient]);

  const latestDisputeHasOriginalDisputeId = Boolean(packet.disputes.at(-1)?.originalDisputeId?.trim());

  const latestTriggerDispute = useMemo(() => packet.disputes.at(-1), [packet.disputes]);

  const disputeAddBlocker = useMemo<DisputeAddBlocker | null>(() => {
    if (!latestTriggerDispute) return null;
    if (
      latestTriggerDispute.reversalInvoiceNumber === "" ||
      latestTriggerDispute.reversalInvoiceNumber == null ||
      latestTriggerDispute.paidAmount == null
    ) {
      return DisputeAddBlocker.RequiredFieldsMissing;
    }
    if (latestTriggerDispute.disputeAmount == null) {
      return DisputeAddBlocker.MissingDisputeAmount;
    }
    // https://c6engineering.atlassian.net/browse/CG-1733
    if (latestTriggerDispute.paidAmount >= latestTriggerDispute.disputeAmount * 0.8) {
      return DisputeAddBlocker.MaxPaidAmountReached;
    }
    return null;
  }, [latestTriggerDispute]);

  return (
    <>
      {packet.disputes.map((dispute, index) => {
        const stageNumber = stageNumberMap[index] ?? `${index + 1}th`;
        const capitalizedStageNumber = capitalize(stageNumber);
        const id = `trigger-dispute-${index}`;

        return (
          <FormFieldsGroup withDivider title={`${capitalizedStageNumber} Trigger Dispute`} id={id} key={id}>
            <Field
              name={getField(`disputes[${index}].disputeAmount`)}
              component={CurrencyField}
              label="Dispute Amount"
              variant="outlined"
              size="small"
            />
            <TextField
              label="Dispute Greater than Invoice Amount"
              value={"Yes"}
              variant="outlined"
              size="small"
              disabled
            />
            <TextField
              label="OBAR Suggested Dispute Amount"
              value={packet.obarSuggestedDisputeAmount}
              variant="outlined"
              size="small"
              disabled
            />
            <TextField label="Dispute Date" value={dispute.disputeDate} variant="outlined" size="small" disabled />
            <Field
              name={getField(`disputes[${index}].originalDisputeId`)}
              as={TextField}
              label="Original Disputed ID"
              variant="outlined"
              size="small"
            />
            <Field
              name={getField(`disputes[${index}].consolidatedDisputeId`)}
              as={TextField}
              label="Consolidated Disputed ID"
              variant="outlined"
              size="small"
            />
            <TextField label="Dispute Status" value={dispute.disputeStatus} variant="outlined" size="small" disabled />
            <Field
              name={getField(`disputes[${index}].invoiceStatus`)}
              as={TextField}
              label="Invoice Status"
              variant="outlined"
              size="small"
            />
            <Field
              name={getField(`disputes[${index}].paidAmount`)}
              component={CurrencyField}
              label="Paid Amount"
              variant="outlined"
              size="small"
            />
            <Field
              name={getField(`disputes[${index}].reversalInvoiceNumber`)}
              as={TextField}
              label="Reversal Invoice Number"
              variant="outlined"
              size="small"
            />
          </FormFieldsGroup>
        );
      })}
      <FormFieldsGroup withDivider id="add-another-trigger-dispute" key="add-another-trigger-dispute">
        <Box
          gridColumn="1 / -1"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={1.5}
        >
          <Button
            variant="outlined"
            onClick={onAddAccrualClick}
            disabled={dirty || mutation.isPending || !latestDisputeHasOriginalDisputeId || disputeAddBlocker !== null}
          >
            {mutation.isPending ? <span>Adding new trigger dispute...</span> : <span>Add another Trigger Dispute</span>}
          </Button>
          {dirty ? (
            <Typography fontSize="0.75rem">
              You have unsaved changes. Save them or discard them in order to add a new trigger dispute.
            </Typography>
          ) : !latestDisputeHasOriginalDisputeId ? (
            <Typography fontSize="0.75rem">
              The latest dispute is not filled in. Fill its details in order to add a new trigger dispute.
            </Typography>
          ) : disputeAddBlocker !== null ? (
            <Typography fontSize="0.75rem">{disputeAddBlockerMessageMap[disputeAddBlocker]}</Typography>
          ) : (
            <>{/* Mutation is pending */}</>
          )}
        </Box>
      </FormFieldsGroup>
    </>
  );
};

export const AccrualDisputes: FC = () => {
  const { values: packet } = useFormikContext<AccrualWorkPacket>();
  return <FieldArray name={getField("disputes")}>{props => <DisputeSections packet={packet} {...props} />}</FieldArray>;
};
