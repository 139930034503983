import { Box, TextField } from "@mui/material";
import usePermissions from "components/CustomHooks/usePermissions";
import { AutocompleteField, DatePickerField, TextAreaField } from "components/UI/Form";
import { Field, useFormikContext } from "formik";
import { type FC } from "react";
import { PermissionLevel, type ShortageWorkPacket, WorkPacketFilter, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketView } from "../../../WorkPacketView";
import { useWorkPacketsContext } from "../../../WorkPacketsContext";
import {
  getEditWorkPacketActionOptions,
} from "../../../WorkPacketsGrid/helpers/getStaticFilterOptions";
import { useUserOptions } from "../../../useUserOptions";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";
import { WORK_PACKET_SUPERVISOR_PERMISSIONS } from "src/pages/UserDashboard/WorkPackets/WorkPacketPermission";

const recoveryStreamOptions = [
  { value: "PPV", title: "PPV" },
  { value: "PQV", title: "PQV" },
];

const getField = createFieldFn<ShortageWorkPacket>();

export const WorkPacketInfo: FC = () => {
  const { isAdmin, hasPermission } = usePermissions();
  const { currentView, currentWorkPacketType, currentFilters } = useWorkPacketsContext();
  const { setFieldValue } = useFormikContext();

  const isPreCaseView = !!currentFilters[WorkPacketFilter.PreCase];
  const isSupervisor = hasPermission(WORK_PACKET_SUPERVISOR_PERMISSIONS[currentWorkPacketType]);

  const userOptions = useUserOptions(isSupervisor);

  const permissionLevel = isAdmin
    ? PermissionLevel.Admin
    : isSupervisor
    ? PermissionLevel.Supervisor
    : PermissionLevel.Default;

  const actionOptions = getEditWorkPacketActionOptions(currentWorkPacketType, isPreCaseView, permissionLevel);

  return (
    <FormFieldsGroup title="Work Packet info" id="work-packet-info">
      <Field
        name={getField("packetId")}
        as={TextField}
        variant="outlined"
        placeholder="Enter Packet ID"
        size="small"
        label="Packet ID"
        disabled
      />

      <Field name={getField("packetDate")} component={DatePickerField} label="Packet Date" disabled />

      <Field
        name={getField("packetStage")}
        as={TextField}
        variant="outlined"
        placeholder="Packet Stage"
        size="small"
        label="Packet Stage"
        disabled
      />

      <Field
        name={getField("currentAction")}
        component={AutocompleteField}
        label="Current Action"
        options={actionOptions}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        setFieldValue={setFieldValue}
        disabled={currentView === WorkPacketView.NewPackets && !isAdmin}
        disableClearable
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={getField("recoveryStreamServer")}
        component={AutocompleteField}
        getOptionLabel={(option: any) => option.title}
        label="Recovery Stream"
        options={recoveryStreamOptions}
        disabled
      />

      <Field
        name={getField("recoveryStreamActivationDate")}
        component={DatePickerField}
        label="Recovery Stream Activation Date"
        disabled
      />

      {/* Omitted for now - will be implemented later */}
      {/* <Field
        name={getField("vc-po-id")}
        as={TextField}
        variant="outlined"
        placeholder="VC PO ID"
        size="small"
        label="VC PO ID"
        disabled
      />

      <Field
        name={getField("vc-vendor-code")}
        as={TextField}
        variant="outlined"
        placeholder="VC Vendor Code"
        size="small"
        label="VC Vendor Code"
        disabled
      /> */}

      <Field
        name={getField("vcPayeeCode")}
        as={TextField}
        variant="outlined"
        placeholder="VC Payee Code"
        size="small"
        label="VC Payee Code"
        disabled
      />

      <Field
        name={getField("currentPacketOwner.id")}
        component={AutocompleteField}
        label="Current Packet Owner"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        setFieldValue={setFieldValue}
        disableClearable={!isAdmin}
      />

      <Field
        name={getField("lastModifiedBy.title")}
        as={TextField}
        variant="outlined"
        placeholder="Last Modified By"
        size="small"
        label="Last Modified By"
        disabled
      />

      <Field
        name={getField("escalationCount")}
        as={TextField}
        variant="outlined"
        placeholder="Number of Escalations"
        size="small"
        label="Number of Escalations"
        disabled
      />

      <Box sx={{ gridColumn: { xs: "span 1", md: "span 2" } }}>
        <Field name={getField("notes")} component={TextAreaField} label="Notes" />
      </Box>
      <Box sx={{ gridColumn: { xs: "span 1", md: "span 2" } }}>
        <Field name={getField("systemNotes")} component={TextAreaField} label="System Notes" disabled />
      </Box>
    </FormFieldsGroup>
  );
};
