import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";
import { AutocompleteField, SelectField, StringDateRangePickerField, MultiAutocompleteField } from "components/UI/Form";
import { Field, Form, useFormikContext } from "formik";
import { type FC } from "react";
import { useUserOptions } from "../useUserOptions";
import { useAccountOptions } from "../WorkPacketsGrid/filters/hooks/useAccountOptions";
import { initialFilters } from "./case-initial-filters";
import { caseFilterLabelMap, CaseType, type Option } from "./case-types";
import { ServerCaseFilters } from "./casesApi";
import { redFlagOptions, settlementsRedFlagOptions } from "./redFlags";
import { useVendorOptions } from "../WorkPacketsGrid/filters/hooks/userVendorOptions";
import { submissionPathOptions } from "./filters/SubmissionPath";
import { preSubmissionRequiredOptions } from "./filters/PreSubmissionRequired";
import { useAuditAccountOptions } from "../WorkPacketsGrid/filters/hooks/useAuditAccountOptions";
import {
  shortageEscalationCorrespondenceWorkFlagOptions,
  shortageSettlementCorrespondenceWorkFlagOptions,
} from "src/pages/UserDashboard/WorkPackets/Cases/filters/CorrespondenceWorkFlags";
import { filterableCaseStatuses, settlementCaseStagesMap } from "src/pages/UserDashboard/Cases/api/constants";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import { FilterFieldGrid } from "../FilterFieldGrid";

export const ChargebackEscalationCaseFilters: FC = () => {
  const auditAccountOptions = useAuditAccountOptions();
  return (
    <>
      <FilterField serverFilter="AmazonCaseId" />
      <FilterField serverFilter="CurrentCaseOwner" options={useUserOptions(true)} />
      <FilterField serverFilter="VendorName" options={useVendorOptions()} isMultiAutocompleteWithoutFreeText />
      <FilterField serverFilter="StoreName" options={useAccountOptions()} isMultiAutocompleteWithoutFreeText />
      <FilterField serverFilter="CurrentAction" options={filterableCaseStatuses[CaseType.CHARGEBACK_ESCALATION]} />
      <FilterField serverFilter="ManualFilingUser" options={auditAccountOptions} />
      <FilterField serverFilter="CaseFilingUser" options={auditAccountOptions} />
    </>
  );
};

export const ShortageEscalationCaseFilters: FC = () => {
  const userOptions = useUserOptions(true);

  return (
    <>
      <FilterField serverFilter="AmazonCaseId" />
      <FilterField serverFilter="VendorName" options={useVendorOptions()} isMultiAutocompleteWithoutFreeText />
      <FilterField serverFilter="StoreName" options={useAccountOptions()} isMultiAutocompleteWithoutFreeText />
      <FilterField serverFilter="CurrentCaseOwner" options={userOptions} />
      <FilterField serverFilter="Processor" options={userOptions} />
      <FilterField serverFilter="CaseFilingUser" options={useAuditAccountOptions()} />
      <FilterField serverFilter="CurrentAction" options={filterableCaseStatuses[CaseType.SHORTAGE_ESCALATION]} />
      <FilterField serverFilter="CreatedAt" isDate />
      <FilterField serverFilter="RedFlags" options={redFlagOptions} isMulti />
      <FilterField
        serverFilter="CorrespondenceWorkFlags"
        options={shortageEscalationCorrespondenceWorkFlagOptions}
        isMulti
      />
      <FilterField serverFilter="VcDisputedInvoiceIds" isMultiAutoComplete />
    </>
  );
};

export const ShortageSettlementCaseFilters: FC = () => {
  const userOptions = useUserOptions(true);

  return (
    <>
      <FilterField serverFilter="AmazonCaseId" />
      <FilterField serverFilter="VendorName" options={useVendorOptions()} isMultiAutocompleteWithoutFreeText />
      <FilterField serverFilter="StoreName" options={useAccountOptions()} isMultiAutocompleteWithoutFreeText />
      <FilterField serverFilter="PresubmissionRequired" options={preSubmissionRequiredOptions} />
      <FilterField serverFilter="CurrentCaseOwner" options={userOptions} />
      <FilterField serverFilter="CaseFilingUser" options={useAuditAccountOptions()} />
      <FilterField serverFilter="SprinterViewedAt" isDate />
      <FilterField serverFilter="Sprinter" options={userOptions} />
      <FilterField serverFilter="Processor" options={userOptions} />
      <FilterField serverFilter="SubmissionPath" options={submissionPathOptions} />
      <FilterField serverFilter="CurrentAction" options={filterableCaseStatuses[CaseType.SHORTAGE_SETTLEMENT]} />
      <FilterField serverFilter="Stage" options={settlementCaseStagesMap} />
      <FilterField serverFilter="SubmissionDate" isDate />
      <FilterField
        serverFilter="RedFlags"
        isMulti
        groupBy={option => option.group}
        options={settlementsRedFlagOptions}
      />
      <FilterField
        serverFilter="CorrespondenceWorkFlags"
        options={shortageSettlementCorrespondenceWorkFlagOptions}
        isMulti
      />
      <FilterField serverFilter="VcDisputedInvoiceIds" isMultiAutoComplete />
    </>
  );
};

export const CaseFilterForm: FC<{ caseType: CaseType }> = ({ caseType }) => {
  const { resetForm, dirty, initialValues, submitForm } = useFormikContext<ServerCaseFilters>();
  const hasNonEmptyValues = initialFilters !== initialValues;
  const clearFilters = () => {
    resetForm({ values: initialValues });
    submitForm();
  };

  const loading = useIsFetching({ queryKey: ["cases"], predicate: query => query.queryKey[3] === "list" }) > 0;

  const caseTypeToFiltersFormMap: Record<CaseType, FC> = {
    [CaseType.CHARGEBACK_ESCALATION]: ChargebackEscalationCaseFilters,
    [CaseType.SHORTAGE_ESCALATION]: ShortageEscalationCaseFilters,
    [CaseType.SHORTAGE_SETTLEMENT]: ShortageSettlementCaseFilters,
  };

  const FormComponent = caseTypeToFiltersFormMap[caseType];

  return (
    <Form>
      <Box display="flex" flexDirection="column" mb={4}>
        <FilterFieldGrid>
          <FormComponent />
        </FilterFieldGrid>

        <Box display="flex" justifyContent="flex-end" gap={3}>
          {dirty && (
            <Button style={{ paddingLeft: "12px", paddingRight: "12px" }} onClick={() => resetForm()}>
              Cancel
            </Button>
          )}
          {hasNonEmptyValues && (
            <Button
              style={{ paddingLeft: "12px", paddingRight: "12px" }}
              onClick={() => {
                clearFilters();
                resetForm({ values: initialFilters });
              }}
            >
              Clear
            </Button>
          )}
          <Button type="submit" variant="contained" disabled={!dirty || loading} sx={{ paddingX: "24px" }}>
            <span>Apply</span>
            {loading && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export const FilterField: FC<{
  serverFilter: keyof typeof ServerCaseFilters;
  options?: Option[];
  isDate?: boolean;
  isMulti?: boolean;
  isMultiAutoComplete?: boolean;
  isMultiAutocompleteWithoutFreeText?: boolean;
  groupBy?: (option: any) => string;
}> = ({ serverFilter, options, isDate, isMulti, isMultiAutoComplete, isMultiAutocompleteWithoutFreeText, groupBy }) => {
  if (options && isMulti) {
    return <MultiSelectField groupBy={groupBy} serverFilter={serverFilter} options={options} />;
  } else if (isMultiAutoComplete) {
    return <MultiAutocompleteFilterField serverFilter={serverFilter} />;
  } else if (isMultiAutocompleteWithoutFreeText) {
    return <MultiAutocompleteWithoutFreeTextFilterField serverFilter={serverFilter} options={options} />;
  } else if (options) {
    return <AutoCompleteFilterField serverFilter={serverFilter} options={options} />;
  } else if (isDate) {
    return <DateFilterField serverFilter={serverFilter} />;
  } else {
    return <TextFieldFilterField serverFilter={serverFilter} />;
  }
};

interface FilterFieldProps {
  serverFilter: keyof typeof ServerCaseFilters;
  options?: Option[];
  groupBy?: (option: any) => string;
}

const TextFieldFilterField: FC<FilterFieldProps> = ({ serverFilter }) => (
  <Field
    name={ServerCaseFilters[serverFilter]}
    label={caseFilterLabelMap[serverFilter]}
    placeholder={caseFilterLabelMap[serverFilter]}
    as={TextField}
    variant="outlined"
    size="small"
  />
);

const AutoCompleteFilterField: FC<FilterFieldProps> = ({ serverFilter, options }) => (
  <Field
    name={ServerCaseFilters[serverFilter]}
    label={caseFilterLabelMap[serverFilter]}
    component={AutocompleteField}
    options={options}
    getOptionLabel={(option: any) => option.title}
    placeholder={caseFilterLabelMap[serverFilter]}
  />
);

const MultiSelectField: FC<FilterFieldProps> = ({ serverFilter, options, groupBy }) => {
  return (
    <Field
      name={ServerCaseFilters[serverFilter]}
      component={SelectField}
      options={(options || EMPTY_ARRAY).map(option => ({ ...option, value: option.value, label: option.title }))}
      label={caseFilterLabelMap[serverFilter]}
      multiple
      checkmarks
      groupBy={groupBy}
      renderValue={(value: any) => `${value.length} Criterion${value.length > 1 ? "s" : ""} Selected`}
    />
  );
};

const DateFilterField: FC<FilterFieldProps> = ({ serverFilter }) => (
  <Field
    name={ServerCaseFilters[serverFilter]}
    label={caseFilterLabelMap[serverFilter]}
    placeholder={caseFilterLabelMap[serverFilter]}
    component={StringDateRangePickerField}
  />
);

const MultiAutocompleteFilterField: FC<FilterFieldProps> = ({ serverFilter }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field
      name={ServerCaseFilters[serverFilter]}
      options={EMPTY_ARRAY}
      label={caseFilterLabelMap[serverFilter]}
      component={MultiAutocompleteField}
      setFieldValue={setFieldValue}
      disableSuggestions
    />
  );
};

const MultiAutocompleteWithoutFreeTextFilterField: FC<FilterFieldProps> = ({ serverFilter, options }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <Field
      name={ServerCaseFilters[serverFilter]}
      component={MultiAutocompleteField}
      options={options || EMPTY_ARRAY}
      label={caseFilterLabelMap[serverFilter]}
      setFieldValue={setFieldValue}
    />
  );
};
