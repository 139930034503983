import { useUrlState } from "state-in-url/react-router";
import { useCallback } from "react";

export const ServerCaseItemsFiltersKeys = {
  VcDisputedInvoiceId: "vcDisputedInvoiceIDs", // no options
} as const;

export type ServerCaseItemFilters = {
  [ServerCaseItemsFiltersKeys.VcDisputedInvoiceId]: string[];
};

export const initialFilters: ServerCaseItemFilters = {
  [ServerCaseItemsFiltersKeys.VcDisputedInvoiceId]: [],
};

export const useCaseItemsFilters = () => {
  const { urlState: filters, setUrl } = useUrlState(initialFilters, { replace: true });
  const setFilters = useCallback(
    (newFilters: ServerCaseItemFilters) =>
      setUrl(prevState => ({
        ...prevState,
        ...newFilters,
      })),
    [setUrl],
  );
  const applyFilters = useCallback(
    (values: ServerCaseItemFilters) => {
      setFilters(values);
    },
    [setFilters],
  );
  return {
    setFilters,
    filters,
    applyFilters,
  };
};
