import { useAuth0 } from "@auth0/auth0-react";
import { Box } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { type ReactNode, useCallback, useContext, useMemo } from "react";
import toast from "react-hot-toast";
import { WorkPacket, WorkPacketTypeMap } from "src/types/work-packets";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import * as WorkPacketApi from "../../api/workPacketsAPI";
import { getUpdatedFields } from "../../util/getUpdatedFields";
import { useWorkPacketsContext } from "../../WorkPacketsContext";
import { WorkPacketType } from "../../WorkPacketType";
import { DialogActionContext } from "../DialogActionContext";
import { FormSection, SummaryNavigation } from "./components";
import { FileUploadContext } from "./FileUploadContext";
import { useFileUploadStore } from "./useFileUploadStore";
import { getWorkPacketAttachmentList, uploadWorkPacketAttachments } from "./work-attachment-queries";

import * as AccrualSections from "./accrual-sections";
import * as ChargebackSections from "./chargeback-sections";
import * as ShortageSections from "./shortage-sections";
import { createChargebackWorkPacketSchema, createShortageWorkPacketSchema } from "./schemas";
import { FocusFormError } from "components/UI/Form";
import { ObjectSchema } from "yup";

const workPacketFormMap: Record<WorkPacketType, ReactNode> = {
  [WorkPacketType.CHARGEBACKS]: (
    <>
      <FocusFormError />
      <FormSection title="General Packet Info" id="general-packet-info">
        <ChargebackSections.CustomerReference />
        <ChargebackSections.WorkPacketInfo />
      </FormSection>

      <FormSection title="Packet details" id="packet-details">
        <ChargebackSections.DetailsWorkPacketInfo />
        <ChargebackSections.DetailsFirstDispute />
        <ChargebackSections.DetailsSecondDispute />
        <ChargebackSections.Escalations />
      </FormSection>
      <FormSection title="Payment details" id="payment-details">
        <ChargebackSections.ChargebackRemittances />
      </FormSection>
    </>
  ),

  [WorkPacketType.SHORTAGES]: (
    <>
      <FocusFormError />
      <FormSection title="General Packet Info" id="general-packet-info">
        <ShortageSections.CustomerReference />
        <ShortageSections.WorkPacketInfo />
      </FormSection>

      <FormSection title="Packet details" id="packet-details">
        <ShortageSections.DisputeInformation />
        {/* Omitted for now - will be added in the future */}
        {/* <ShortageSections.InvoiceInformation /> */}
        <ShortageSections.Escalations />
        <ShortageSections.Settlements />
      </FormSection>
      <FormSection title="Payment details" id="payment-details">
        <ShortageSections.ShortageRemittances />
      </FormSection>
    </>
  ),

  [WorkPacketType.ACCRUALS]: (
    <>
      <FormSection title="General Packet Info" id="general-packet-info">
        <AccrualSections.CustomerReference />
      </FormSection>

      <FormSection title="Packet details" id="packet-details">
        <AccrualSections.ObarInfo />
        <AccrualSections.AccrualDisputes />
        <AccrualSections.AccrualNotes />
        <AccrualSections.EvidenceAttachments />
      </FormSection>

      <FormSection title="AccrualRemittances" id="remittance">
        <AccrualSections.AccrualRemittances />
      </FormSection>

      {/*TODO: Remove this commented out code after demo*/}
      {/*<FormSection title="Final recovery results" id="final-recovery-resuls">*/}
      {/*  <AccrualSections.FinalRecoveryResults />*/}
      {/*</FormSection>*/}
    </>
  ),
};

const workPacketSchemaMap: { [T in WorkPacketType]?: (initialValues: WorkPacketTypeMap[T]) => ObjectSchema<object> } = {
  [WorkPacketType.SHORTAGES]: createShortageWorkPacketSchema,
  [WorkPacketType.CHARGEBACKS]: createChargebackWorkPacketSchema,
  [WorkPacketType.ACCRUALS]: undefined,
};

export interface SummaryProps {
  workPacket: WorkPacket;
  formRef: any;
  onSubmitSuccess: () => void;
  setLoading: (loading: boolean) => void;
}

export function Summary({ workPacket, formRef, onSubmitSuccess, setLoading }: SummaryProps) {
  const { user } = useAuth0();
  const { currentWorkPacketType } = useWorkPacketsContext();
  const FormContent = workPacketFormMap[workPacket.workPacketType];
  const queryClient = useQueryClient();

  const existingAttachmentsQuery = useQuery({
    queryFn: getWorkPacketAttachmentList,
    queryKey: ["work-packets", currentWorkPacketType, "details", workPacket.packetId, "attachments"],
    enabled: workPacket.workPacketType === WorkPacketType.ACCRUALS,
  });

  const attachmentUploadMutation = useMutation({
    mutationKey: ["work-packet-attachment-upload", workPacket.packetId],
    mutationFn: uploadWorkPacketAttachments,
    onMutate: () => void toast("Uploading attachments..."),
    onSuccess: () => void toast.success("Files uploaded!"),
    onError: () => void toast.error("Attachment upload failed"),
  });

  const dialogActionStore = useContext(DialogActionContext);

  const fileUploadStore = useFileUploadStore({
    existingFiles: existingAttachmentsQuery.data ?? EMPTY_ARRAY,
    isLoadingExistingFiles: existingAttachmentsQuery.isFetching,
    isUploading: attachmentUploadMutation.isPending,
    dialogActionStore,
  });
  const { hasPendingUploads, fileUploads, setFileUploadStatus } = fileUploadStore;

  const performAttachmentUpload = attachmentUploadMutation.mutateAsync;
  const uploadPendingAttachments = useCallback(async () => {
    if (!hasPendingUploads) return false;
    await performAttachmentUpload({
      workPacket,
      fileUploads,
      setFileUploadStatus,
    });
    return true;
  }, [fileUploads, hasPendingUploads, performAttachmentUpload, setFileUploadStatus, workPacket]);

  const onFormSubmit = useCallback(
    async (updatedWorkPacket: WorkPacket) => {
      if (!user?.sub) return;
      if (attachmentUploadMutation.isPending) return;

      try {
        const didUploadAttachments = await uploadPendingAttachments();

        // calling `setLoading(true)` will replace the entire popup's contents with a loading spinner
        // instead we call this after uplaoads are done, so that they can show their upload progress
        setLoading(true);

        const updatedFields = getUpdatedFields(workPacket, updatedWorkPacket);

        if (!didUploadAttachments && Object.keys(updatedFields).length === 0) {
          console.log("No fields have been updated");
          toast.success("No fields have been updated");
          return;
        }

        if (Object.keys(updatedFields).length > 0) {
          await WorkPacketApi.updateWorkPacket({
            workPacketType: currentWorkPacketType,
            workPacketId: workPacket.packetId,
            updatedFields,
          });
          queryClient.invalidateQueries({ queryKey: ["work-packets", currentWorkPacketType] });
          queryClient.invalidateQueries({ queryKey: ["cases"] });
        }

        toast.success("Work packet updated successfully");
        onSubmitSuccess(); // invalidates work packet list data
      } catch (error) {
        console.error("Error in handleSubmit: ", error);
        toast.error("Error updating work packet");
      } finally {
        setLoading(false);
      }
    },
    [
      user?.sub,
      attachmentUploadMutation.isPending,
      uploadPendingAttachments,
      setLoading,
      workPacket,
      onSubmitSuccess,
      currentWorkPacketType,
      queryClient,
    ],
  );

  // TODO(daniel): figure out how to type this
  const validationSchema = useMemo(
    () => workPacketSchemaMap[workPacket.workPacketType]?.(workPacket as any),
    [workPacket],
  );

  return (
    <FileUploadContext.Provider value={fileUploadStore}>
      <Box display="flex" paddingTop={3} gap={3}>
        <Box flex={1}>
          <Formik
            validationSchema={validationSchema}
            initialValues={workPacket}
            onSubmit={onFormSubmit}
            innerRef={formRef}
          >
            <Form children={FormContent} />
          </Formik>
        </Box>
        <SummaryNavigation workPacket={workPacket} workPacketType={workPacket.workPacketType} />
      </Box>
    </FileUploadContext.Provider>
  );
}
