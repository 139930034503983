import * as Yup from "yup";
import { CHINESE, STANDARD } from "./constants.ts";

export const vendorSchema = Yup.object().shape({
  VENDOR_NAME: Yup.string().required("Customer Name is required"),
  TIER: Yup.string().oneOf(["Enterprise", "Mid Market", "SMB"], "Invalid Tier selected").required("Tier is required"),
  SPECIFIC_POINT_OF_CONTACT_NAME: Yup.string().required("SPOC Name is required"),
  SPECIFIC_POINT_OF_CONTACT_EMAIL: Yup.string().email("Invalid email address").required("SPOC Email is required"),

  INITIAL_COMMISSION_RATE: Yup.string()
    .matches(/^(100(\.0{0,2})?|[1-9]?\d(\.\d{0,2})?)$/, "Invalid percentage format")
    .required("Pre-threshold Commission Rate is required"),
  FINAL_COMMISSION_RATE: Yup.string()
    .matches(/^(100(\.0{0,2})?|[1-9]?\d(\.\d{0,2})?)$/, "Invalid percentage format")
    .required("Post-threshold Commission Rate is required"),
  ENGAGEMENT_FEE: Yup.string().required("Engagement Fee is required"),
  RECOVERY_THRESHOLD: Yup.string().required("Recovery Threshold is required"),
  AGREEMENT_SIGNED_DATE: Yup.string().required("Agreement Signed Date is required"),
  REFERRAL: Yup.string().required("Referral is required"),
  REFERRAL_COMMISSION_RATE: Yup.string()
    .nullable()
    .matches(/^(100(\.0{0,2})?|[1-9]?\d(\.\d{0,2})?)$/, "Invalid percentage format"),
  REFERRAL_2_COMMISSION_RATE: Yup.string()
    .nullable()
    .matches(/^(100(\.0{0,2})?|[1-9]?\d(\.\d{0,2})?)$/, "Invalid percentage format"),
  BILLING_EMAIL: Yup.string().email("Invalid email address"),
  BILLING_ENTITY: Yup.string("Invalid billing entity"),
  NOTES: Yup.string().max(500, "Notes cannot exceed 500 characters"),
  VENDOR_TYPE: Yup.string()
    .oneOf([CHINESE, STANDARD], "Invalid Vendor Type option selected")
    .required("Vendor Type option is required"),
  CLIENT_SUCCESS_MANAGER_NAME: Yup.string(),
  CLIENT_SUCCESS_MANAGER_EMAIL: Yup.string().email("Invalid client success manager email"),
});
