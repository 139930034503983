import { FC } from "react";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { Box, Button, CircularProgress } from "@mui/material";
import { Field, Form, useFormikContext } from "formik";
import { MultiAutocompleteField } from "components/UI/Form";
import { EMPTY_ARRAY } from "src/utils/empty-values.ts";
import { initialFilters, ServerCaseItemFilters, ServerCaseItemsFiltersKeys } from "./useCaseItemsFilters";
import { useIsFetching } from "@tanstack/react-query";

interface CaseItemFiltersProps {
  caseType: CaseType;
  caseId: string;
}

function areFiltersEqual<T extends object>(currentFilters: Partial<T>, nextFilters: Partial<T>): boolean {
  return Object.entries(nextFilters).every(([filterKey, newValue]) => {
    const previousValue = currentFilters[filterKey as keyof T];
    if (Array.isArray(previousValue) && Array.isArray(newValue)) {
      if (previousValue.length !== newValue.length) return false;
      return previousValue.every((previousArrayEntry, index) => previousArrayEntry === newValue[index]);
    }
    return previousValue === newValue;
  });
}

const ShortageSettlementFilter: FC<CaseItemFiltersProps> = ({ caseId, caseType }) => {
  const { setFieldValue, initialValues, dirty, resetForm, submitForm } = useFormikContext<ServerCaseItemFilters>();
  const hasNonEmptyValues = !areFiltersEqual(initialFilters, initialValues);
  const loading =
    useIsFetching({
      queryKey: ["cases", caseType, caseId, "items"],
    }) > 0;

  const clearFilters = () => {
    resetForm({ values: initialValues });
    submitForm();
  };

  return (
    <Box display={"flex"} gap={2}>
      <Box flex={1}>
        <Field
          name={ServerCaseItemsFiltersKeys.VcDisputedInvoiceId}
          label="Last Child Invoice ID"
          component={MultiAutocompleteField}
          options={EMPTY_ARRAY}
          disableSuggestions
          variant="outlined"
          size="small"
          setFieldValue={setFieldValue}
        />
      </Box>
      {dirty && (
        <Button style={{ paddingLeft: "12px", paddingRight: "12px" }} onClick={() => resetForm()}>
          Cancel
        </Button>
      )}
      {hasNonEmptyValues && (
        <Button
          style={{ paddingLeft: "12px", paddingRight: "12px" }}
          onClick={() => {
            clearFilters();
            resetForm({ values: initialFilters });
          }}
        >
          Clear
        </Button>
      )}
      <Button disabled={!dirty || loading} type={"button"} onClick={submitForm} variant={"contained"}>
        Apply Filters
        {loading && <CircularProgress size={14} style={{ marginLeft: "12px", color: "#101828" }} />}
      </Button>
    </Box>
  );
};
const ShortageEscalationFilter: FC<CaseItemFiltersProps> = () => <></>;
const ChargebackEscalationFilter: FC<CaseItemFiltersProps> = () => <></>;

export const CaseItemFilters: FC<CaseItemFiltersProps> = ({ caseType, caseId }) => {
  const CASE_FILTERS = {
    [CaseType.SHORTAGE_ESCALATION]: ShortageEscalationFilter,
    [CaseType.SHORTAGE_SETTLEMENT]: ShortageSettlementFilter,
    [CaseType.CHARGEBACK_ESCALATION]: ChargebackEscalationFilter,
  };
  const FilterComponent = CASE_FILTERS[caseType];
  return (
    <Box>
      <Form>
        <FilterComponent caseType={caseType} caseId={caseId} />
      </Form>
    </Box>
  );
};
