import { CaseDetailsKeys } from "../mappers/mapServerToLocalCase";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";

export enum CaseStatus {
  WaitingOnAmazon = "WAITING_ON_AMAZON",
  WaitingOnCG = "WAITING_ON_CG",
  Approved = "APPROVED",
  PartiallyApproved = "PARTIALLY_APPROVED",
  Denied = "DENIED",
  NotPursuing = "NOT_PURSUING",
  WaitingOnClient = "WAITING_ON_CLIENT",
  WaitingOnRetemplateSubmission = "WAITING_ON_RETEMPLATE_SUBMISSION",
  WaitingOnVMAM = "WAITING_ON_VM_AM",
  WaitingOnProposalOfferApproval = "WAITING_ON_PROPOSAL_OFFER_APPROVAL",
  WaitingForPayment = "WAITING_FOR_PAYMENT",
  Reanalysis = "REANALYSIS",
  WaitingOnEvidence = "WAITING_ON_EVIDENCE",
  PaidOut = "PAID_OUT",
  SubmittedToInvoicing = "SUBMITTED_TO_INVOICING",
  Closed = "CLOSED",
  ClosedWithoutResolution = "CLOSED_WITHOUT_RESOLUTION",
  Hold = "HOLD",
  ConvertedToEscalation = "CONVERTED_TO_ESCALATION",
}

interface CaseStatusDescriptor {
  title: string;
  color: string;
}

interface CaseStatusOption {
  value: string;
  title: string;
  color: string;
}

const caseStatusDescriptors: Record<CaseStatus, CaseStatusDescriptor> = {
  [CaseStatus.WaitingOnAmazon]: {
    title: "Waiting on Amazon",
    color: "#FFCD4D",
  },
  [CaseStatus.WaitingOnCG]: {
    title: "Waiting on CG",
    color: "#FF820F",
  },
  [CaseStatus.Approved]: {
    title: "Approved",
    color: "#39D4A5",
  },
  [CaseStatus.PartiallyApproved]: {
    title: "Partially Approved",
    color: "#318FFF",
  },
  [CaseStatus.Denied]: {
    title: "Denied",
    color: "#E03741",
  },
  [CaseStatus.NotPursuing]: {
    title: "Not Pursuing",
    color: "#EAECF0",
  },
  [CaseStatus.WaitingOnClient]: {
    title: "Waiting on Client",
    color: "#FF820F",
  },
  [CaseStatus.WaitingOnRetemplateSubmission]: {
    title: "Waiting on Retemplate Submission",
    color: "#FF820F",
  },
  [CaseStatus.WaitingOnVMAM]: {
    title: "Waiting on VM/AM",
    color: "#FF820F",
  },
  [CaseStatus.WaitingOnProposalOfferApproval]: {
    title: "Waiting on Proposed Offer Approval",
    color: "#39D4A5",
  },
  [CaseStatus.WaitingForPayment]: {
    title: "Waiting For Payment",
    color: "#FF820F",
  },
  [CaseStatus.Reanalysis]: {
    title: "Re-Analysis",
    color: "#7B3DFF",
  },
  [CaseStatus.WaitingOnEvidence]: {
    title: "Waiting on Evidence",
    color: "#F155FF",
  },
  [CaseStatus.PaidOut]: {
    title: "Paid Out",
    color: "#318FFF",
  },
  [CaseStatus.SubmittedToInvoicing]: {
    title: "Submitted to Invoicing",
    color: "#F155FF",
  },
  [CaseStatus.Closed]: {
    title: "Closed",
    color: "#7D3B8A",
  },
  [CaseStatus.ClosedWithoutResolution]: {
    title: "Closed w/o Resolution",
    color: "#FECDD6",
  },
  [CaseStatus.Hold]: {
    title: "Hold",
    color: "#EAECF0",
  },
  [CaseStatus.ConvertedToEscalation]: {
    title: "Converted To Escalation",
    color: "#E1E04A",
  },
};

// List of all the statuses that can be used as filters for each case type
const caseFilterableStatusesByCaseTypeMap: Record<CaseType, CaseStatus[]> = {
  [CaseType.SHORTAGE_ESCALATION]: [
    CaseStatus.WaitingOnAmazon,
    CaseStatus.WaitingOnCG,
    CaseStatus.PaidOut,
    CaseStatus.Approved,
    CaseStatus.PartiallyApproved,
    CaseStatus.Denied,
    CaseStatus.ClosedWithoutResolution,
  ],
  [CaseType.SHORTAGE_SETTLEMENT]: [
    CaseStatus.WaitingOnAmazon,
    CaseStatus.WaitingOnClient,
    CaseStatus.WaitingOnRetemplateSubmission,
    CaseStatus.WaitingOnCG,
    CaseStatus.WaitingOnVMAM,
    CaseStatus.WaitingOnProposalOfferApproval,
    CaseStatus.WaitingForPayment,
    CaseStatus.Reanalysis,
    CaseStatus.WaitingOnEvidence,
    CaseStatus.PaidOut,
    CaseStatus.SubmittedToInvoicing,
    CaseStatus.Hold,
    CaseStatus.ConvertedToEscalation,
  ],
  [CaseType.CHARGEBACK_ESCALATION]: [
    CaseStatus.WaitingOnAmazon,
    CaseStatus.WaitingOnCG,
    CaseStatus.PaidOut,
    CaseStatus.Approved,
    CaseStatus.PartiallyApproved,
    CaseStatus.Denied,
    CaseStatus.ClosedWithoutResolution,
  ],
};

// List of all the statuses that can be set for each case type
const caseSettableStatusesByCaseTypeMap: Record<CaseType, CaseStatus[]> = {
  [CaseType.SHORTAGE_ESCALATION]: [
    CaseStatus.WaitingOnAmazon,
    CaseStatus.WaitingOnCG,
    CaseStatus.Approved,
    CaseStatus.PartiallyApproved,
    CaseStatus.Denied,
    CaseStatus.ClosedWithoutResolution,
  ],
  [CaseType.SHORTAGE_SETTLEMENT]: [
    CaseStatus.WaitingOnAmazon,
    CaseStatus.WaitingOnClient,
    CaseStatus.WaitingOnRetemplateSubmission,
    CaseStatus.WaitingOnCG,
    CaseStatus.WaitingOnVMAM,
    CaseStatus.WaitingOnProposalOfferApproval,
    CaseStatus.WaitingForPayment,
    CaseStatus.Reanalysis,
    CaseStatus.WaitingOnEvidence,
    CaseStatus.SubmittedToInvoicing,
    CaseStatus.Hold,
    CaseStatus.ConvertedToEscalation,
  ],
  [CaseType.CHARGEBACK_ESCALATION]: [
    CaseStatus.WaitingOnAmazon,
    CaseStatus.WaitingOnCG,
    CaseStatus.Approved,
    CaseStatus.PartiallyApproved,
    CaseStatus.Denied,
    CaseStatus.ClosedWithoutResolution,
  ],
};

export function getStatusOption(status: CaseStatus): CaseStatusOption {
  return {
    ...caseStatusDescriptors[status],
    value: status,
  };
}

function createOptionsMap(statusSet: Record<CaseType, CaseStatus[]>) {
  const caseTypes = Object.values(CaseType);
  return Object.fromEntries(
    caseTypes.map(caseType => [
      caseType,
      statusSet[caseType].map(getStatusOption),
    ]),
  ) as Record<CaseType, CaseStatusOption[]>;
}

export const filterableCaseStatuses = createOptionsMap(caseFilterableStatusesByCaseTypeMap);
export const settableCaseStatuses = createOptionsMap(caseSettableStatusesByCaseTypeMap);

export enum ShortagesEscalationCaseItemStatus {
  WAITING_ON_AMAZON = "WAITING_ON_AMAZON",
  WAITING_ON_CLIENT = "WAITING_ON_CLIENT",
  WAITING_ON_RETEMPLATE_SUBMISSION = "WAITING_ON_RETEMPLATE_SUBMISSION",
  WAITING_ON_CG = "WAITING_ON_CG",
  WAITING_ON_VM_AM = "WAITING_ON_VM_AM",
  WAITING_ON_PROPOSAL_OFFER_APPROVAL = "WAITING_ON_PROPOSAL_OFFER_APPROVAL",
  REANALYSIS = "REANALYSIS",
  WAITING_ON_EVIDENCE = "WAITING_ON_EVIDENCE",
  PAID_OUT = "PAID_OUT",
  APPROVED = "APPROVED",
  PARTIALLY_APPROVED = "PARTIALLY_APPROVED",
  DENIED = "DENIED",
  CLOSED_WITHOUT_RESOLUTION = "CLOSED_WITHOUT_RESOLUTION",
  HOLD = "HOLD",
}

export enum ShortagesSettlementsCaseStage {
  PUSHING_FOR_RCA_LINE_LEVEL_DETAILS = "PUSHING_FOR_RCA_LINE_LEVEL_DETAILS",
  PUSHING_FOR_ADVANCED_OFFER = "PUSHING_FOR_ADVANCED_OFFER",
  PUSHING_FOR_ALIGNED_BALANCE = "PUSHING_FOR_ALIGNED_BALANCE",
  PUSHING_FOR_RCA_TO_COMPLETE = "PUSHING_FOR_RCA_TO_COMPLETE",
  PUSHING_FOR_RCA_RESULTS = "PUSHING_FOR_RCA_RESULTS",
  AUDIT_RETAIL_VM_APPROVAL = "AUDIT_RETAIL_VM_APPROVAL",
  SUBMITTED = "SUBMITTED",
  PAID_OUT = "PAID_OUT",
  SETTLEMENT_CLOSED_WITHOUT_RESOLUTION = "SETTLEMENT_CLOSED_WITHOUT_RESOLUTION",
  SETTLEMENT_TURNED_DISPUTE = "SETTLEMENT_TURNED_DISPUTE",
}

export enum ChargebackEscalationCaseItemStatus {
  WAITING_ON_AMAZON = "WAITING_ON_AMAZON",
  STUCK = "STUCK",
  APPROVED = "APPROVED",
  WAITING_ON_CG = "WAITING_ON_CG",
  RECEIVED = "Received",
  WAITING_ON_CLIENT = "WAITING_ON_CLIENT",
  SUBMITTED_TO_INVOICING = "SUBMITTED_TO_INVOICING",
  PREP_FLAG_REMOVED = "PREP_FLAG_REMOVED",
  WAITING_ON_VM_AM = "WAITING_ON_VM_AM",
  PAID_OUT = "PAID_OUT",
  PARTIALLY_PAID_OUT = "PARTIALLY_PAID_OUT",
  APPROVED_PARTIALLY_APPROVED = "APPROVED_PARTIALLY_APPROVED",
  CLOSED = "CLOSED",
  REJECTED = "REJECTED",
  APPROVED_MO_PAYMENT = "APPROVED_MO_PAYMENT",
}

export type ShortagesEscalationCaseItemStatusOption = (typeof shortagesCaseItemStatusMap)[0];
export const shortagesCaseItemStatusMap = [
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_AMAZON, title: "Waiting on Amazon", color: "#FFCD4D" },
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_CG, title: "Waiting on CG", color: "#FF820F" },
  { value: ShortagesEscalationCaseItemStatus.PAID_OUT, title: "Paid Out", color: "#318FFF" },
  { value: ShortagesEscalationCaseItemStatus.APPROVED, title: "Approved", color: "#39D4A5" },
  { value: ShortagesEscalationCaseItemStatus.PARTIALLY_APPROVED, title: "Partially Approved", color: "#318FFF" },
  { value: ShortagesEscalationCaseItemStatus.DENIED, title: "Denied", color: "#E03741" },
  {
    value: ShortagesEscalationCaseItemStatus.CLOSED_WITHOUT_RESOLUTION,
    title: "Closed w/o Resolution",
    color: "#FECDD6",
  },
];

export const settlementCaseStatusMap = [
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_AMAZON, title: "Waiting on Amazon", color: "#FFCD4D" },
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_CLIENT, title: "Waiting on Client", color: "#FF820F" },
  {
    value: ShortagesEscalationCaseItemStatus.WAITING_ON_RETEMPLATE_SUBMISSION,
    title: "Waiting on Re Template Submission",
    color: "#318FFF",
  },
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_CG, title: "Waiting on CG", color: "#E03741" },
  { value: ShortagesEscalationCaseItemStatus.WAITING_ON_VM_AM, title: "Waiting on VM/AM", color: "#7B3DFF" },
  {
    value: ShortagesEscalationCaseItemStatus.WAITING_ON_PROPOSAL_OFFER_APPROVAL,
    title: "Waiting on Proposed Offer Approval",
    color: "#F155FF",
  },
  {
    value: ShortagesEscalationCaseItemStatus.REANALYSIS,
    title: "Re-Analysis",
    color: "#FECDD6",
  },
  {
    value: ShortagesEscalationCaseItemStatus.WAITING_ON_EVIDENCE,
    title: "Waiting on Evidence",
    color: "#FF820F",
  },
  {
    value: ShortagesEscalationCaseItemStatus.PAID_OUT,
    title: "Paid Out",
    color: "#318FFF",
  },
  {
    value: ShortagesEscalationCaseItemStatus.HOLD,
    title: "Hold",
    color: "#EAECF0",
  },
];

export const settlementCaseStagesMap = [
  {
    value: ShortagesSettlementsCaseStage.SUBMITTED,
    title: "Submitted",
    color: "#318FFF",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_ALIGNED_BALANCE,
    title: "Pushing for Aligned Balance",
    color: "#F155FF",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_RCA_TO_COMPLETE,
    title: "Pushing for RCA to Complete",
    color: "#7B3DFF",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_RCA_RESULTS,
    title: "Pushing for RCA Results: Audit/Retail/VM Approval",
    color: "#F63D68",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_RCA_LINE_LEVEL_DETAILS,
    title: "Pushing for RCA Line Level Details",
    color: "#FFCD4D",
  },
  {
    value: ShortagesSettlementsCaseStage.PUSHING_FOR_ADVANCED_OFFER,
    title: "Pushing for Advanced Offer",
    color: "#FF820F",
  },
  {
    value: ShortagesSettlementsCaseStage.PAID_OUT,
    title: "Paid Out",
    color: "#39D4A5",
  },
  {
    value: ShortagesSettlementsCaseStage.SETTLEMENT_TURNED_DISPUTE,
    title: "Settlement Turned Dispute",
    color: "#FECDD6",
  },
  {
    value: ShortagesSettlementsCaseStage.SETTLEMENT_CLOSED_WITHOUT_RESOLUTION,
    title: "Settlement Closed Without Resolution",
    color: "#EAECF0",
  },
];

const baseFields = [
  CaseDetailsKeys.CaseOwner,
  CaseDetailsKeys.Sprinter,
  CaseDetailsKeys.Processor,
  CaseDetailsKeys.OpsExternalCommunication,
  CaseDetailsKeys.SubmissionPath,
  CaseDetailsKeys.Status,
  CaseDetailsKeys.Stage,
  CaseDetailsKeys.PreSubmissionRequired,
];

const rcaCompleteFields = [
  ...baseFields,
  CaseDetailsKeys.SprinterDate,
  CaseDetailsKeys.ProcessorDate,
  CaseDetailsKeys.AmazonLastRespondedAt,
  CaseDetailsKeys.CgLastRespondedAt,
  CaseDetailsKeys.AmazonCaseManager,
  CaseDetailsKeys.AlignedBalAmount,
  CaseDetailsKeys.AlignedBalDate,
  CaseDetailsKeys.PaidAmountBeforeOpenBalanceAlignment,
  CaseDetailsKeys.ValidAmount,
];

const rcaResultsFields = [...rcaCompleteFields, CaseDetailsKeys.RcaEndDate];

const lineLevelDetailsFields = [...rcaResultsFields, CaseDetailsKeys.FirstOfferAmount, CaseDetailsKeys.FirstOfferDate];

const paidOutFields = [
  ...lineLevelDetailsFields,
  CaseDetailsKeys.SecondOfferAmount,
  CaseDetailsKeys.SecondOfferDate,
  CaseDetailsKeys.ThirdOfferAmount,
  CaseDetailsKeys.ThirdOfferDate,
  CaseDetailsKeys.SettlementAcceptanceDateToAmazon,
  CaseDetailsKeys.FinalAmount,
  CaseDetailsKeys.FinalAmountDate,
];

export const mandatoryFieldsByStage: Record<ShortagesSettlementsCaseStage, CaseDetailsKeys[]> = {
  [ShortagesSettlementsCaseStage.PUSHING_FOR_ALIGNED_BALANCE]: baseFields,
  [ShortagesSettlementsCaseStage.PUSHING_FOR_RCA_TO_COMPLETE]: rcaCompleteFields,
  [ShortagesSettlementsCaseStage.PUSHING_FOR_RCA_RESULTS]: rcaResultsFields,
  [ShortagesSettlementsCaseStage.PUSHING_FOR_RCA_LINE_LEVEL_DETAILS]: lineLevelDetailsFields,
  [ShortagesSettlementsCaseStage.PUSHING_FOR_ADVANCED_OFFER]: lineLevelDetailsFields,
  [ShortagesSettlementsCaseStage.PAID_OUT]: paidOutFields,
  [ShortagesSettlementsCaseStage.AUDIT_RETAIL_VM_APPROVAL]: [],
  [ShortagesSettlementsCaseStage.SUBMITTED]: [],
  [ShortagesSettlementsCaseStage.SETTLEMENT_CLOSED_WITHOUT_RESOLUTION]: [],
  [ShortagesSettlementsCaseStage.SETTLEMENT_TURNED_DISPUTE]: [],
};

export const chargebackEscalationCaseStagesMap = [
  { value: ChargebackEscalationCaseItemStatus.WAITING_ON_AMAZON, title: "Waiting on Amazon", color: "#EAECF0" },
  { value: ChargebackEscalationCaseItemStatus.STUCK, title: "Stuck", color: "#E03741" },
  { value: ChargebackEscalationCaseItemStatus.APPROVED, title: "Approved", color: "#F155FF" },
  { value: ChargebackEscalationCaseItemStatus.WAITING_ON_CG, title: "Waiting on CG", color: "#FF820F" },
  { value: ChargebackEscalationCaseItemStatus.RECEIVED, title: "Received", color: "#318FFF" },
  { value: ChargebackEscalationCaseItemStatus.WAITING_ON_CLIENT, title: "Waiting on Client", color: "#FECDD6" },
  {
    value: ChargebackEscalationCaseItemStatus.SUBMITTED_TO_INVOICING,
    title: "Submitted to Invoicing",
    color: "#FFCD4D",
  },
  {
    value: ChargebackEscalationCaseItemStatus.PREP_FLAG_REMOVED,
    title: "Prep Flag Removed",
    color: "#66CCFF",
  },
  {
    value: ChargebackEscalationCaseItemStatus.WAITING_ON_VM_AM,
    title: "Waiting on AM/VM",
    color: "#5559DF",
  },
  {
    value: ChargebackEscalationCaseItemStatus.PAID_OUT,
    title: "Paid Out",
    color: "#CAB642",
  },
  {
    value: ChargebackEscalationCaseItemStatus.PAID_OUT,
    title: "Partially Paid Out",
    color: "#9DD326",
  },
  {
    value: ChargebackEscalationCaseItemStatus.PAID_OUT,
    title: "Approved / Partially Approved",
    color: "#39D4A5",
  },
  {
    value: ChargebackEscalationCaseItemStatus.CLOSED,
    title: "Closed",
    color: "#7D3B8A",
  },
  {
    value: ChargebackEscalationCaseItemStatus.REJECTED,
    title: "Rejected",
    color: "#7B3DFF",
  },
  {
    value: ChargebackEscalationCaseItemStatus.APPROVED_MO_PAYMENT,
    title: "Approved - No Payment",
    color: "#800B12",
  },
];
