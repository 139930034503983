import { FC } from "react";
import { FormFieldsGroup } from "src/pages/UserDashboard/WorkPackets/Cases/Components/FormFieldsGroup";
import { Field, useFormikContext } from "formik";
import { AutocompleteField, DatePickerField, FormTextField } from "components/UI/Form";
import { CurrencyField } from "../../../../../components/UI/Form/CurrencyField";
import { CaseDetailsKeys } from "../../mappers/mapServerToLocalCase";
import { SettlementCase } from "src/types/cases";

export const SettlementInfo: FC = () => {
  const formik = useFormikContext<SettlementCase>();

  return (
    <FormFieldsGroup title="Settlements" id={"settlement-info"}>
      <Field
        name={CaseDetailsKeys.PreSubmissionRequired}
        component={AutocompleteField}
        variant="outlined"
        placeholder="Pre Submission Required"
        size="small"
        label="PreSubmission Required"
        getOptionLabel={(option: any) => option.title}
        options={[
          { value: "", title: "" },
          { value: true, title: "Yes" },
          { value: false, title: "No" },
        ]}
        onChange={(value: { value: boolean; title: string } | null) => {
          formik.setFieldValue(CaseDetailsKeys.PreSubmissionRequired, value?.value ?? null);
        }}
      />
      <Field
        name={CaseDetailsKeys.Business}
        component={FormTextField}
        variant="outlined"
        placeholder="Business"
        size="small"
        label="Business"
        disabled
      />
      <Field
        name={CaseDetailsKeys.Payee}
        component={FormTextField}
        variant="outlined"
        placeholder="Payee"
        size="small"
        label="Payee"
        disabled
      />
      <Field
        name={CaseDetailsKeys.VendorCodes}
        component={FormTextField}
        variant="outlined"
        placeholder="Vendor Codes"
        size="small"
        label="Vendor Codes"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.DirectImport}
        component={AutocompleteField}
        variant="outlined"
        placeholder="Direct Import"
        size="small"
        label="Direct Import"
        getOptionLabel={(option: any) => option.title}
        options={[
          { value: "", title: "" },
          { value: true, title: "Yes" },
          { value: false, title: "No" },
        ]}
        onChange={(value: { value: boolean; title: string } | null) => {
          formik.setFieldValue(CaseDetailsKeys.DirectImport, value?.value ?? null);
        }}
      />
      <Field
        name={CaseDetailsKeys.Category}
        component={FormTextField}
        variant="outlined"
        placeholder="Category"
        size="small"
        label="Category"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.VcDisputedIds}
        component={FormTextField}
        variant="outlined"
        placeholder="VC Disputed IDs"
        size="small"
        label="VC Disputed IDs"
        disabled
      />
      <Field
        name={CaseDetailsKeys.InvoiceStartDueDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Invoice Start Due Date"
        size="small"
        label="Invoice Start Due Date"
        disabled
      />
      <Field
        name={CaseDetailsKeys.InvoiceEndDueDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Invoice End Due Date"
        size="small"
        label="Invoice End Due Date"
        disabled
      />
      <Field
        name={CaseDetailsKeys.SubmissionAmount}
        component={CurrencyField}
        variant="outlined"
        placeholder="Submission Amount"
        size="small"
        label="Submission Amount"
        disabled
      />
      <Field
        name={CaseDetailsKeys.SubmissionDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Submission Date"
        size="small"
        label="Submission Date"
        disabled
      />
      <Field
        name={CaseDetailsKeys.SubmissionCount}
        component={FormTextField}
        variant="outlined"
        placeholder="Submission Count"
        size="small"
        label="Submission Count"
        disabled
      />
      <Field
        name={CaseDetailsKeys.AlignedBalAmount}
        component={CurrencyField}
        variant="outlined"
        placeholder="Aligned Balance Amount"
        size="small"
        label="Aligned Balance Amount"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.AlignedBalDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Aligned Balance Date"
        size="small"
        label="Aligned Bal Date (CG)"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.RcaEndDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="RCA End Date"
        size="small"
        label="RCA End Date"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.PaidAmountBeforeOpenBalanceAlignment}
        component={CurrencyField}
        variant="outlined"
        placeholder="Paid Amount before Open Balance Alignment"
        size="small"
        label="Paid Amount before Open Balance Alignment"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.ValidAmount}
        component={CurrencyField}
        variant="outlined"
        placeholder="Valid Amount"
        size="small"
        label="Valid Amount"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.FirstOfferAmount}
        component={CurrencyField}
        variant="outlined"
        placeholder="First Offer Amount"
        size="small"
        label="First Offer Amount"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.FirstOfferDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="First Offer Date"
        size="small"
        label="First Offer Date"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.SecondOfferAmount}
        component={CurrencyField}
        variant="outlined"
        placeholder="Second Offer Amount"
        size="small"
        label="Second Offer Amount"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.SecondOfferDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Second Offer Date"
        size="small"
        label="Second Offer Date"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.ThirdOfferAmount}
        component={CurrencyField}
        variant="outlined"
        placeholder="Third Offer Amount"
        size="small"
        label="Third Offer Amount"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.ThirdOfferDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Third Offer Date"
        size="small"
        label="Third Offer Date"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.SettlementAcceptanceDateToAmazon}
        component={DatePickerField}
        variant="outlined"
        placeholder="Settlement Acceptance Date to Amzn"
        size="small"
        label="Settlement Acceptance Date to Amzn"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.FinalAmount}
        component={CurrencyField}
        variant="outlined"
        placeholder="Final Amount"
        size="small"
        label="Final Amount"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.FinalAmountDate}
        component={DatePickerField}
        variant="outlined"
        placeholder="Final Amount Date"
        size="small"
        label="Final Amount Date"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        name={CaseDetailsKeys.FinalRecoveryRateCalc}
        component={FormTextField}
        variant="outlined"
        placeholder="Recovery Rate Calc"
        size="small"
        label="Recovery Rate Calc"
        disabled
      />
    </FormFieldsGroup>
  );
};
