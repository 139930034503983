import { TextField, TextFieldProps } from "@mui/material";
import { FC } from "react";
import { FieldProps, useFormikContext } from "formik";

interface FormTextFieldProps extends FieldProps, Omit<TextFieldProps, 'name' | 'value' | 'onChange'> {}

const FormTextField: FC<FormTextFieldProps> = ({ field, form, ...props }) => {
  const { getFieldMeta } = useFormikContext();
  const { error } = getFieldMeta(field.name);

  return (
    <TextField
      {...field}
      {...props}
      error={!!error}
      helperText={error}
    />
  );
};

export default FormTextField;
