import { type GridRenderCellParams } from "@mui/x-data-grid";
import { type FC } from "react";

import { ArrowForwardRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export const DetailsButton: FC<GridRenderCellParams<{ chargeGuardCaseId: string }>> = params => {
  return (
    <Link to={`${params.row.chargeGuardCaseId}`}>
      <Button variant="outlined" endIcon={<ArrowForwardRounded />}>
        Details
      </Button>
    </Link>
  );
};
