import { Box, TextField } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { type FC, useEffect } from "react";
import {
  AutocompleteField,
  CheckboxField,
  MultiAutocompleteField,
  MultiCheckboxField,
  StringDateRangePickerField,
} from "src/components/UI/Form";
import { StagelessAction, WorkPacketFilter, WorkPacketFilters, type WorkPacketOwner } from "src/types/work-packets";
import { WorkPacketType } from "../../WorkPacketType";
import { WorkPacketView } from "../../WorkPacketView";
import { useWorkPacketsContext } from "../../WorkPacketsContext";
import { createStagelessAction } from "../../mappers";
import { useUserNickname } from "../../useUserNickname";
import { useUserOptions } from "../../useUserOptions";
import { filterableWorkPacketActions, staticFilterOptions } from "../helpers/getStaticFilterOptions";
import { getPacketOwnerLabel } from "./getPacketOwnerLabel";
import { useAuditAccountOptions } from "./hooks/useAuditAccountOptions";
import { useVendorOptions } from "./hooks/userVendorOptions";
import { useAccountOptions } from "./hooks/useAccountOptions";
import { EMPTY_ARRAY } from "src/utils/empty-values";
import { FilterFieldGrid } from "../../FilterFieldGrid";

const recoveryStreamOptions = [
  { value: "PPV", title: "PPV" },
  { value: "PQV", title: "PQV" },
];

const numberOfCasesOptions = [
  { value: "ZERO", title: "Zero" },
  { value: "MORE_THAN_ZERO", title: "More Than Zero" },
];

export const ShortageFiltersForm: FC = () => {
  const { currentFilters } = useWorkPacketsContext();
  const isPreCase = !!currentFilters[WorkPacketFilter.PreCase];
  if (isPreCase) {
    return <PreCaseShortageFilters />;
  } else {
    return <NormalShortageFiltersForm />;
  }
};

const NormalShortageFiltersForm: FC = () => {
  const { setFieldValue } = useFormikContext();
  const userNickname = useUserNickname();
  const { currentView, currentWorkPacketType } = useWorkPacketsContext();

  const userOptions = useUserOptions();
  const auditAccountOptions = useAuditAccountOptions();
  const accountOptions = useAccountOptions();
  const vendorOptions = useVendorOptions();

  return (
    <FilterFieldGrid>
      <Field
        name={WorkPacketFilter.VendorName}
        component={MultiAutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={MultiAutocompleteField}
        label="Store Name"
        options={accountOptions}
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={filterableWorkPacketActions[WorkPacketType.SHORTAGES]}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={WorkPacketFilter.ManualFilingUser}
        component={AutocompleteField}
        label="Manual Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={WorkPacketFilter.RecoveryStream}
        component={AutocompleteField}
        label="Recovery Stream"
        options={recoveryStreamOptions}
        getOptionLabel={(option: any) => option.title}
        placeholder="Recovery Stream"
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(currentView, userNickname)}
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        disabled={currentView !== WorkPacketView.AllPackets}
      />

      <Field
        name={WorkPacketFilter.ParentInvoiceId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Parent Invoice ID"
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.DisputedInvoiceId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Disputed Invoice ID"
        disableSuggestions
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.DisputeId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Dispute ID"
        disableSuggestions
        setFieldValue={setFieldValue}
      />

      <Field name={WorkPacketFilter.InvoiceDueDate} component={StringDateRangePickerField} label="Invoice Due Date" />

      <Box display="none" flexDirection="row" alignItems="center">
        <Box flexGrow={1}>
          <Field
            name={WorkPacketFilter.DisputeCreatedAt}
            component={StringDateRangePickerField}
            label="Dispute - Created At"
          />
        </Box>
        <Field name={WorkPacketFilter.RedFlags} component={CheckboxField} label="Red flags" />
      </Box>

      <Field
        name={WorkPacketFilter.PaymentStatus}
        component={MultiCheckboxField}
        label="Payment Status"
        options={staticFilterOptions[WorkPacketFilter.PaymentStatus]}
        setFieldValue={setFieldValue}
        disableSuggestions
        />
    </FilterFieldGrid>
  );
};

const preCaseActions = [
  createStagelessAction(StagelessAction.ReadyForReview),
  createStagelessAction(StagelessAction.NotPursuing),
];

const PreCaseShortageFilters: FC = () => {
  const { currentWorkPacketType, currentView } = useWorkPacketsContext();
  const userOptions = useUserOptions();
  const vendorOptions = useVendorOptions();
  const auditAccountOptions = useAuditAccountOptions();
  const userNickname = useUserNickname();
  const accountOptions = useAccountOptions();
  const { setFieldValue, values } = useFormikContext<WorkPacketFilters>();

  useEffect(() => {
    if (!values[WorkPacketFilter.PreviouslyRemovedFromSettlements]) {
      setFieldValue(WorkPacketFilter.CaseID, "");
    }
  }, [setFieldValue, values]);

  return (
    <FilterFieldGrid>
      <Field
        name={WorkPacketFilter.VendorName}
        component={MultiAutocompleteField}
        label="Vendor Name"
        options={vendorOptions}
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.StoreName}
        component={MultiAutocompleteField}
        label="Store Name"
        options={accountOptions}
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.PayeeCode}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Payee Code"
        disableSuggestions
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.VendorCode}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Vendor Code"
        disableSuggestions
        setFieldValue={setFieldValue}
      />

      <Field
        name={WorkPacketFilter.CurrentAction}
        component={AutocompleteField}
        label="Current Action"
        options={preCaseActions}
        groupBy={(option: any) => option.category}
        getOptionLabel={(option: any) => option.title}
        placeholder="Current Action"
        workPacketType={currentWorkPacketType}
      />

      <Field
        name={WorkPacketFilter.ManualFilingUser}
        component={AutocompleteField}
        label="Manual Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={WorkPacketFilter.CurrentPacketOwner}
        component={AutocompleteField}
        label={getPacketOwnerLabel(currentView, userNickname)}
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Packet Owner"
        disabled={currentView !== WorkPacketView.AllPackets}
      />

      <Field
        name={WorkPacketFilter.DisputeCreatedAt}
        component={StringDateRangePickerField}
        label="Dispute - Created At"
      />

      <Field
        name={WorkPacketFilter.ParentInvoiceId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Parent Invoice ID"
        setFieldValue={setFieldValue}
        disableSuggestions
      />

      <Field
        name={WorkPacketFilter.DisputedInvoiceId}
        component={MultiAutocompleteField}
        options={EMPTY_ARRAY}
        label="Disputed Invoice ID"
        disableSuggestions
        setFieldValue={setFieldValue}
      />

      <Field name={WorkPacketFilter.InvoiceDueDate} component={StringDateRangePickerField} label="Invoice Due Date" />

      <Field
        name={WorkPacketFilter.NumberOfEscalations}
        component={AutocompleteField}
        label="# Of Escalations"
        options={numberOfCasesOptions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={WorkPacketFilter.PreviouslyRemovedFromEscalations}
        component={CheckboxField}
        label="Previously removed from Escalations"
      />

      <Field
        name={WorkPacketFilter.NumberOfSettlements}
        component={AutocompleteField}
        label="# Of Settlements"
        options={numberOfCasesOptions}
        getOptionLabel={(option: any) => option.title}
      />

      <Field
        name={WorkPacketFilter.PreviouslyRemovedFromSettlements}
        component={CheckboxField}
        label="Previously removed from Settlements"
      />
      <Field
        name={WorkPacketFilter.CaseID}
        disabled={!values[WorkPacketFilter.PreviouslyRemovedFromSettlements]}
        label="Settlement Amazon Case ID"
        as={TextField}
        size="small"
        variant="outlined"
      />

      <Field
        name={WorkPacketFilter.PaymentStatus}
        component={MultiCheckboxField}
        label="Payment Status"
        options={staticFilterOptions[WorkPacketFilter.PaymentStatus]}
        setFieldValue={setFieldValue}
        disableSuggestions
        />
    </FilterFieldGrid>
  );
};
