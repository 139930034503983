import { FC, useEffect, useMemo } from "react";
import { Field, useFormikContext } from "formik";
import dayjs from "dayjs";
import { WorkPacketDisputesInfo } from "src/types/work-packets.ts";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types.ts";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import toast from "react-hot-toast";
import { FiCopy } from "react-icons/fi";
import { formatMaybeCurrency } from "src/pages/UserDashboard/Cases/mappers/utils.ts";
import escalationTitle from "src/assets/templates/shortages/escalation_title.txt?raw";
import settlementTitle from "src/assets/templates/shortages/settlement_title.txt?raw";
import escalationBody from "src/assets/templates/shortages/escalation_body.txt?raw";
import settlementBody from "src/assets/templates/shortages/settlement_body.txt?raw";

interface CaseAutoMessageProps {
  workPacketDisputesInfo: WorkPacketDisputesInfo;
}

function parseTemplateMessage(text: string, params: Record<string, any>): string {
  for (const key of Object.keys(params)) {
    text = text.replaceAll(`{{${key}}}`, params[key]);
  }
  return text;
}

function getCaseTextInfo(
  workPacketDisputesInfo: WorkPacketDisputesInfo,
  caseType: CaseType,
  caseCreatedAt: dayjs.Dayjs,
): { title: string; body: string } {
  if (caseType == CaseType.SHORTAGE_ESCALATION) {
    escalationTitle;
    escalationBody;
    settlementTitle;
    settlementBody;
    return {
      title: parseTemplateMessage(escalationTitle, {
        STORE_NAME: workPacketDisputesInfo.store_name,
        CURRENT_MONTH: caseCreatedAt.format("MMMM"),
        CURRENT_YEAR: caseCreatedAt.format("YYYY"),
        CHILD_INVOICE_NUMBER: workPacketDisputesInfo.child_invoices.join(", "),
      }),

      body: parseTemplateMessage(escalationBody, {
        ASNS: workPacketDisputesInfo.asns.join(","),
        DISPUTE_ID: workPacketDisputesInfo.dispute_ids.join(","),
        CHILD_INVOICE_NUMBER: workPacketDisputesInfo.child_invoices.join(", "),
        PO_NUMBER: workPacketDisputesInfo.po_numbers.join(","),
      }),
    };
  } else if (caseType == CaseType.SHORTAGE_SETTLEMENT) {
    return {
      title: parseTemplateMessage(settlementTitle, {
        STORE_NAME: workPacketDisputesInfo.store_name,
      }),
      body: parseTemplateMessage(settlementBody, {
        STORE_NAME: workPacketDisputesInfo.store_name,
        PAYEE_CODES: workPacketDisputesInfo.payee_codes.join(", "),
        SUBMISSION_AMOUNT: formatMaybeCurrency(workPacketDisputesInfo.submission_amount),
        SUBMISSION_COUNT: workPacketDisputesInfo.invoice_ids.length,
        INV_START_DUE_DATE: workPacketDisputesInfo.invoice_start_due_date,
        INV_END_DUE_DATE: workPacketDisputesInfo.invoice_end_due_date,
      }),
    };
  }
  return {
    title: "",
    body: "",
  };
}

const CaseTextInfo: FC<CaseAutoMessageProps> = ({ workPacketDisputesInfo }) => {
  const { values, setFieldValue } = useFormikContext<{
    caseType: CaseType;
    caseCreatedAt: dayjs.Dayjs;
    caseTextTitle: string;
    caseTextBody: string;
  }>();
  useEffect(() => {
    const textInfo = getCaseTextInfo(workPacketDisputesInfo, values.caseType, values.caseCreatedAt);
    setFieldValue("caseTextTitle", textInfo.title);
    setFieldValue("caseTextBody", textInfo.body);
  }, [workPacketDisputesInfo, values.caseType, values.caseCreatedAt, setFieldValue]);
  const titleLabel = useMemo((): string => {
    if (values.caseType == CaseType.SHORTAGE_ESCALATION) {
      return "Dispute Title";
    } else if (values.caseType == CaseType.SHORTAGE_SETTLEMENT) {
      return "Subject";
    }
    return "";
  }, [values.caseType]);
  const bodyLabel = useMemo((): string => {
    if (values.caseType == CaseType.SHORTAGE_ESCALATION) {
      return "Final Automated Spiel";
    } else if (values.caseType == CaseType.SHORTAGE_SETTLEMENT) {
      return "Body";
    }
    return "";
  }, [values.caseType]);
  return (
    <>
      <li>
        Copy and paste the {titleLabel} and {bodyLabel} to the Case Creation page
      </li>
      <Box display={"flex"} flexDirection={"column"} paddingTop={2} rowGap={2}>
        <Field
          name={"caseTextTitle"}
          label={titleLabel}
          as={TextField}
          size="small"
          variant="outlined"
          disabled
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(values.caseTextTitle ?? "");
                    toast.success(`${titleLabel} copied`);
                  }}
                >
                  <FiCopy />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Field
          name={"caseTextBody"}
          label={bodyLabel}
          size="small"
          variant="outlined"
          as={TextField}
          disabled
          multiline
          rows={4}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(values.caseTextBody ?? "");
                    toast.success(`${bodyLabel} copied`);
                  }}
                >
                  <FiCopy />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>
  );
};

export default CaseTextInfo;
