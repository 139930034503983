import type { SxProps } from "@mui/material";
import autocompleteClasses from "@mui/material/Autocomplete/autocompleteClasses";
import Box from "@mui/material/Box";
import type { FC, ReactNode } from "react";

const filterFieldGridSx: SxProps = {
  gridTemplateColumns: {
    xs: "repeat(1, minmax(0, 1fr))",
    sm: "repeat(2, minmax(0, 1fr))",
    md: "repeat(4, minmax(0, 1fr))",
  },
  gap: 2,
  rowGap: 3,
  alignItems: "flex-start",
  [`& > .${autocompleteClasses.root}`]: {
    margin: "unset",
  },
};

export const FilterFieldGrid: FC<{ children: ReactNode }> = ({ children }) => (
  <Box display="grid" sx={filterFieldGridSx}>
    {children}
  </Box>
);
