import { FC, useMemo } from "react";
import { FormFieldsGroup } from "src/pages/UserDashboard/WorkPackets/Cases/Components/FormFieldsGroup.tsx";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { EscalationCase, SettlementCase } from "src/types/cases";
import { Box, TextField } from "@mui/material";
import { CaseDetailsKeys } from "../../mappers/mapServerToLocalCase";
import { DatePickerField } from "../../../../../components/UI/Form";
import { AutocompleteField } from "components/UI/Form";
import { WorkPacketOwner } from "src/types/work-packets.ts";
import { useUserOptions } from "src/pages/UserDashboard/WorkPackets/useUserOptions.ts";
import { CaseStatus, getStatusOption, settableCaseStatuses } from "src/pages/UserDashboard/Cases/api/constants";
import usePermissions from "components/CustomHooks/usePermissions";
import { VcLinkButton } from "src/components/work-packets/VcLinkButton";
import { CaseType, type Option } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { useAuditAccountOptions } from "src/pages/UserDashboard/WorkPackets/WorkPacketsGrid/filters/hooks/useAuditAccountOptions";

const StatusSelectField: FC<{ name: string; caseType: CaseType }> = ({ name, caseType }) => {
  const { initialValues } = useFormikContext<EscalationCase | SettlementCase>();
  const initialStatus = initialValues[CaseDetailsKeys.Status];
  const baseOptions = settableCaseStatuses[caseType];
  const allOptions = useMemo(() => {
    if (initialStatus && !baseOptions.some(option => option.value === initialStatus)) {
      return [getStatusOption(initialStatus as CaseStatus), ...baseOptions];
    }
    return baseOptions;
  }, [baseOptions, initialStatus]);

  return (
    <Box>
      <Field
        name={name}
        label="Case Status"
        variant="outlined"
        component={AutocompleteField}
        options={allOptions}
        getOptionLabel={(option: Option) => option.title}
        fullWidth
      />
      <ErrorMessage
        render={message => <Box sx={{ fontSize: ".7rem", color: "primary.light" }}>{message}</Box>}
        name={name}
      />
    </Box>
  );
};

export const CaseInfoTextField: FC<{ name: string; label: string; editable?: boolean }> = ({
  name,
  label,
  editable,
}) => (
  <Field
    name={name}
    disabled={!editable}
    as={TextField}
    variant="outlined"
    size="small"
    placeholder={label}
    label={label}
  />
);

export const ShortageCaseInfo: FC<{ caseType: CaseType }> = ({ caseType }) => {
  const userOptions = useUserOptions();
  const auditAccountOptions = useAuditAccountOptions();
  const { hasPermission } = usePermissions();
  const { values } = useFormikContext<EscalationCase>();

  return (
    <FormFieldsGroup title="Case Info" id={"case-info"}>
      <CaseInfoTextField name={CaseDetailsKeys.AmazonCaseId} label="Amazon Case ID" />
      <StatusSelectField name={CaseDetailsKeys.Status} caseType={caseType} />
      <CaseInfoTextField name={CaseDetailsKeys.RecoveryStream} label="Recovery Stream" />
      <Field
        name={CaseDetailsKeys.RecoveryStreamActivationDate}
        component={DatePickerField}
        label="Recovery Stream Activation Date"
        variant="outlined"
        size="small"
        disabled
      />
      <CaseInfoTextField name={CaseDetailsKeys.ManualFilingUser} label="Manual Filing User" />
      <Field
        name="caseFilingUser"
        component={AutocompleteField}
        label="Case Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Case Filing User"
        disabled={!hasPermission(["admin:*", "user:shortages_case:write:*"])}
      />
      <Field
        name="caseOwner.id"
        component={AutocompleteField}
        label="Current Case Owner"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Case Owner"
        disabled={!hasPermission(["admin:*", "user:shortages_case:supervisor:*"])}
      />
      <Field
        name="processorUser.id"
        component={AutocompleteField}
        label="Processor"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Processor"
        disabled={!hasPermission(["admin:*", "user:shortages_case:supervisor:*"])}
      />
      <CaseInfoTextField name={CaseDetailsKeys.LastModifiedBy} label="Last Modified By" editable />
      {values.vcLink ? (
        <VcLinkButton link={values.vcLink} />
      ) : (
        <TextField label="Link To Case" placeholder="Link To Case" variant="outlined" size="small" disabled />
      )}
    </FormFieldsGroup>
  );
};

export const ChargebackCaseInfo: FC = () => {
  const userOptions = useUserOptions(true);
  const auditAccountOptions = useAuditAccountOptions();
  const { hasPermission } = usePermissions();
  return (
    <FormFieldsGroup title="Case Info" id={"case-info"}>
      <CaseInfoTextField name={CaseDetailsKeys.AmazonCaseId} label="Amazon Case ID" />
      <StatusSelectField name={CaseDetailsKeys.Status} caseType={CaseType.CHARGEBACK_ESCALATION} />
      {/* <CaseInfoTextField name={CaseDetailsKeys.RecoveryStream} label="Recovery Stream" /> */}
      <CaseInfoTextField name={CaseDetailsKeys.RecoveryStreamType} label="Recovery Stream Type" />
      <Field
        name={CaseDetailsKeys.RecoveryStreamActivationDate}
        component={DatePickerField}
        label="Recovery Stream Activation Date"
        variant="outlined"
        size="small"
        disabled
      />
      <CaseInfoTextField name={CaseDetailsKeys.RecoveryStreamSubType} label="Recovery Stream Subtype" />
      <CaseInfoTextField name={CaseDetailsKeys.IssueSubTypeDesc} label="Recovery Stream Subtype 1" />
      <CaseInfoTextField name={CaseDetailsKeys.Notes} label="Recovery Stream Subtype 2" />
      <CaseInfoTextField name={CaseDetailsKeys.ManualFilingUser} label="Manual Filing User" />
      <Field
        name="caseFilingUser"
        component={AutocompleteField}
        label="Case Filing User"
        options={auditAccountOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Case Filing User"
        disabled={!hasPermission(["admin:*", "user:shortages_case:write:*"])}
      />
      <Field
        name="caseOwner.id"
        component={AutocompleteField}
        label="Current Case Owner"
        options={userOptions}
        getOptionLabel={(option: WorkPacketOwner) => option.title}
        placeholder="Current Case Owner"
        disabled={!hasPermission(["admin:*", "user:chargebacks_case:supervisor:*"])}
      />
      <CaseInfoTextField name={CaseDetailsKeys.LastModifiedBy} label="Last Modified By" />
    </FormFieldsGroup>
  );
};
