import { Dayjs } from "dayjs";
import type { WorkPacketType } from "src/pages/UserDashboard/WorkPackets/WorkPacketType";
import type { WorkPacketView } from "src/pages/UserDashboard/WorkPackets/WorkPacketView";
import { ServerUser } from "./user";
import { ServerCaseItem } from "src/pages/UserDashboard/Cases/api/types";
import { SettlementCase } from "./cases";

export enum RecoveryStream {
  All = "All",
  InventoryShortages = "Inventory shortages",
  PriceClaims = "Price Claims",
  Accruals = "Accruals",
  Chargebacks = "Chargebacks",
}

export enum StageName {
  First = "FIRST",
  Second = "SECOND",
  Third = "THIRD",
}

export enum StagefulAction {
  Identified = "IDENTIFIED",
  DisputeFailed = "DISPUTE_FAILED",
  DisputeCreated = "DISPUTE_CREATED",
  DisputeInReview = "DISPUTE_IN_REVIEW",
  DisputeApproved = "DISPUTE_APPROVED",
  DisputePartiallyApproved = "DISPUTE_PARTIALLY_APPROVED",
  DisputeDenied = "DISPUTE_DENIED",
  DisputeExpired = "DISPUTE_EXPIRED",
  EscalationDenied = "ESCALATION_DENIED",
  EscalationApproved = "ESCALATION_APPROVED",
  EscalationPartiallyApproved = "ESCALATION_PARTIALLY_APPROVED",
  DisputeReidentified = "DISPUTE_REIDENTIFIED",
  DipusteReattemptFailed = "DISPUTE_REATTEMPT_FAILED",
  NotPursuing = "DISPUTE_NO_PURSUE",
  MonitorRemittance = "DISPUTE_MONITOR_REMITTANCE",
  EscalationIdentified = "DISPUTE_ESCALATION_IDENTIFIED",
  EscalatedInternal = "DISPUTE_ESCALATED_INTERNAL",
  EscalatedAmazon = "DISPUTE_ESCALATED_AMAZON",
}

export enum StagelessAction {
  // pre-case
  ReadyForReview = "READY_FOR_REVIEW",
  ReadyForOssr = "READY_FOR_OSSR",
  NotPursuing = "NO_PURSUE",
  WaitingOnAmazon = "WAITING_ON_AMAZON",

  // case
  WaitingForPayment = "WAITING_FOR_PAYMENT",

  // other actions
  Junk = "JUNK",
  Duplicate = "DUPLICATE",
  Expired = "EXPIRED",
  NotApplicable = "NOT_APPLICABLE",
}

export enum ActionCategory {
  Dispute = "Dispute",
  Escalation = "Escalation",
  PreCase = "PreCase",
  Case = "Case",
  OtherActions = "OtherActions",
}

export type FullStagefulAction = `${StageName}_${StagefulAction}`;

export type WorkPacketActionName = FullStagefulAction | `${StagelessAction}`;

export enum PermissionLevel {
  Default,
  Supervisor,
  Admin,
}

export interface WorkPacketAction {
  value: WorkPacketActionName;
  title: string;
  category: string;
  categoryKey: ActionCategory;
  color: string;
  minimumPermissionToSet: PermissionLevel;
}

// Each enum value corresponds to the filter key expected by the server
export enum WorkPacketFilter {
  AsinId = "asinId",
  RecoveryStream = "recoveryStream",
  RecoveryStreamType = "recoveryStreamType",
  RecoveryStreamSubtype1 = "recoveryStreamSubtype1",
  RecoveryStreamSubtype2 = "recoveryStreamSubtype2",
  PacketStage = "packetStage",
  StoreName = "storeName",
  VcPoId = "vcPoId",
  CurrentAction = "currentAction",
  CurrentPacketOwner = "currentPacketOwner",
  VendorName = "vendorName",
  RedFlags = "redFlags",
  ChargebackRedFlags = "chargebackRedFlags",
  AccrualRedFlags = "accrualRedFlags",
  IssueId = "issueId",
  ChargebackCreatedAt = "chargebackCreatedAt",
  DisputeCreatedAt = "disputeCreatedAt",
  CalculatedDisputeByDate = "calculatedDisputeByDate",
  ManualFilingUser = "manualFilingUser",
  FreightTerms = "freightTerms",
  InboundShipmentDeliveryIsdId = "inboundShipmentDeliveryIsdId",
  NumberOfEscalations = "numberOfEscalations",
  NumberOfSettlements = "numberOfSettlements",
  PreviouslyRemovedFromEscalations = "previouslyRemovedFromEscalations",
  PreviouslyRemovedFromSettlements = "previouslyRemovedFromSettlements",
  DisputeId = "disputeId",
  PaymentStatus = "paymentStatus",

  // shortages pre case only:
  PayeeCode = "payeeCode",
  VendorCode = "vendorCode",
  ParentInvoiceId = "parentInvoiceId",
  DisputedInvoiceId = "disputedInvoiceId",
  InvoiceDueDate = "invoiceDueDate",
  // accrual packets only:
  RecoveryStreamSubtype = "recoveryStreamSubtype",
  ObarCreatedDate = "createdDateObar",
  AgreementId = "agreementId",
  InvoiceDate = "invoiceDate",
  InvoiceNumber = "invoiceNumber",
  OriginalDisputeId = "originalDisputedId",
  ConsolidatedDisputeId = "consolidatedDisputeId",
  DisputeStatus = "disputeStatus",
  WithNotes = "withNotes",
  WithMonitoringDate = "withMonitoringDate",
  CaseID = "caseID",

  // pseudo-filters
  View = "view",
  PreCase = "preCase",
}

export interface WorkPacketSummaryFilters {
  [WorkPacketFilter.StoreName]: string;
  [WorkPacketFilter.DisputeCreatedAt]: string;
  [WorkPacketFilter.PreCase]?: boolean;
}

export interface WorkPacketFilters {
  [WorkPacketFilter.RecoveryStream]: string;
  [WorkPacketFilter.RecoveryStreamType]: string;
  [WorkPacketFilter.RecoveryStreamSubtype1]: string;
  [WorkPacketFilter.RecoveryStreamSubtype2]: string;
  [WorkPacketFilter.PacketStage]: string;
  [WorkPacketFilter.StoreName]: string[];
  [WorkPacketFilter.CurrentAction]: string;
  [WorkPacketFilter.CurrentPacketOwner]: string;
  [WorkPacketFilter.VendorName]: string[];
  [WorkPacketFilter.RedFlags]: boolean;
  [WorkPacketFilter.ManualFilingUser]: string;
  [WorkPacketFilter.DisputeCreatedAt]: string;
  [WorkPacketFilter.NumberOfEscalations]: string;
  [WorkPacketFilter.NumberOfSettlements]: string;
  [WorkPacketFilter.InvoiceDueDate]: string[];
  [WorkPacketFilter.PreviouslyRemovedFromEscalations]: boolean;
  [WorkPacketFilter.PreviouslyRemovedFromSettlements]: boolean;
  [WorkPacketFilter.PaymentStatus]: string[];
  [WorkPacketFilter.PayeeCode]: string[];
  [WorkPacketFilter.VendorCode]: string[];

  // pseudo-filters:
  [WorkPacketFilter.View]?: WorkPacketView;
  [WorkPacketFilter.PreCase]?: boolean;

  // chargeback packets only:
  [WorkPacketFilter.AsinId]: string[];
  [WorkPacketFilter.VcPoId]: string[];
  [WorkPacketFilter.IssueId]: string[];
  [WorkPacketFilter.ChargebackCreatedAt]: string;
  [WorkPacketFilter.CalculatedDisputeByDate]: string;
  [WorkPacketFilter.FreightTerms]: string;
  [WorkPacketFilter.InboundShipmentDeliveryIsdId]: string[];
  [WorkPacketFilter.ChargebackRedFlags]: string[];
  [WorkPacketFilter.ParentInvoiceId]: string[];
  [WorkPacketFilter.DisputedInvoiceId]: string[];
  [WorkPacketFilter.DisputeId]: string[];
  [WorkPacketFilter.CaseID]: string;

  // accrual packets only:
  [WorkPacketFilter.RecoveryStreamSubtype]: string;
  [WorkPacketFilter.ObarCreatedDate]: string[];
  [WorkPacketFilter.AgreementId]: string[];
  [WorkPacketFilter.InvoiceDate]: string[];
  [WorkPacketFilter.InvoiceNumber]: string[];
  [WorkPacketFilter.OriginalDisputeId]: string[];
  [WorkPacketFilter.ConsolidatedDisputeId]: string[];
  [WorkPacketFilter.DisputeStatus]: string;
  [WorkPacketFilter.WithNotes]: boolean;
  [WorkPacketFilter.WithMonitoringDate]: boolean;
  [WorkPacketFilter.AccrualRedFlags]: string[];
}

export enum WorkPacketMetric {
  TotalStores = "Total Stores with Open Work Packets",
  OpenWorkPackets = "Open Work Packets",
  NewPackets = "New Packets",
  AverageTimePacketOpen = "Avg. time that packet is open",
  AverageTimeUnassigned = "Avg. time that packets have been unassigned",
  TotalPacketsAssigned = "Total Packets Assigned",
  PacketsWithRedFlags = "Packets with Red Flags",
  AvgTimePacketOpen = "Avg. time that packet is open",
}

export interface WorkPacketOwner {
  id: string;
  avatar: string;
  title: string;
}

export const stageNames = Array.from(Object.values(StageName));

/** Base interface for work packet used in Grid */
export interface WorkPacketBase {
  createdAt: string;
  workPacketType: WorkPacketType;
  packetId: string;
  vendorName: string;
  storeName: string;
  currentPacketOwner: WorkPacketOwner;
  currentAction: WorkPacketActionName;
  recoveryStream: RecoveryStream;
  packetStage: string;
  manualFilingUser: string;
  isValidWorkPacket: boolean;
  isHistoric: boolean;
  clientRegion: string;
  vendorId: string;
  storeId: number;
  packetDate: Dayjs;
  recoveryStreamServer: ServerStream;
  recoveryStreamActivationDate: Dayjs;
  vcFilingUser: string;
  notes: string;
  systemNotes: string;
  techUser: string;
  disputeByDate?: string;
  marketplaceCountry: string;
  modifiedAt: string;
  lastModifiedBy: WorkPacketOwner;
  disputeApprovedAmount: string;
  evidenceType: string[];
  evidenceAttachment: string;

  /*
   * HACK(daniel): fake properties for the purpose of displaying custom work packet grid columns
   * that are not associated with any object property
   */
  _details: undefined;
}

export interface ChargebackWorkPacket extends WorkPacketBase {
  recoveryStream: RecoveryStream.Chargebacks;
  vcPoId: string;
  asinId: string;
  chargebackIssueId: string;
  financialCharge: string;
  reversedAmount: string;
  vendorCode: string;
  chargebackCreateDate: string;
  techUser: string;
  recoveryStreamSubtype: string;
  recoveryStreamSubtype1: string;
  recoveryStreamSubtype2: string;
  firstDisputeCreatedAt?: string;
  secondDisputeCreatedAt?: string;
  calculatedDisputeByDate?: string | undefined;
  freightTerms: string;
  inboundShipmentDeliveryIsdId: string;
  escalations: ChargebackEscalation[];
}

export interface ChargebackEscalation {
  id: string;
  itemId: string;
  createdAt: Dayjs | undefined;
  status: string;
  remainingOpenBalance: number;
  monitoringDate: Dayjs | undefined;
  approvedDate: Dayjs | undefined;
  approvedAmount: number | undefined;
  disputedAmount: number | undefined;
  paidAmount: number | undefined;
  paymentDetails: ChargebackEscalationPaymentDetail[];
}

export interface ChargebackEscalationPaymentDetail {
  id: string;
  caseItemId: string;
  invoiceId: string;
  invoiceNumber: string;
  paidAmount: number;
  paymentDate: Dayjs | undefined;
  c6InvoiceDate: Dayjs | undefined;
  // TODO: figure out details on Payout Type
  // payoutType: string;
  customerInvoiceAmount: string | null;
  paymentId: string | null;
  createdAt: string | null;
}

export interface ShortageWorkPacket extends WorkPacketBase {
  recoveryStream: RecoveryStream.InventoryShortages;
  disputeId: string;
  lastChildInvoiceId?: string;
  clientDisputed: boolean;
  disputeAmount?: number;
  vcParentInvoiceId: string;
  vcDisputedInvoiceId: string;
  invoiceDate: string;
  invoiceDueDate: string;
  vcPayeeCode: string;
  shortageLag: string;
  disputeCreatedAt: string;
  disputeResolvedAt: string;
  remainingOpenBalance: string;
  escalationCount: number;
  escalations: ShortageEscalation[];
  settlement?: SettlementCase;
}

export interface ShortageEscalation {
  id: string;
  itemId: string;
  createdAt: Dayjs | undefined;
  status: string;
  remainingOpenBalance: number;
  monitoringDate: Dayjs | undefined;
  approvedDate: Dayjs | undefined;
  approvedAmount: number | undefined;
  paymentDetails: EscalationPaymentDetail[];
}

export interface ShortageSettlement {
  caseId: string;
  status: string;
  preSubmissionRequired: boolean | string;
  business: string;
  payee: string;
  vendorCodes: string;
  directImport: boolean | string;
  category: string;
  invoiceStartDueDate: Dayjs | undefined;
  invoiceEndDueDate: Dayjs | undefined;
  currentOwner: string;
  sprinter: string;
  sprinterDate: Dayjs | undefined;
  opsExternalCommunication: boolean | string;
  processor: string;
  processorDate: Dayjs | undefined;
  submissionPath: string;
  manualFilingUser: string;
  ccUser: string;
  currentCaseNumber: string;
  link: string;
  previousCaseNumbers: string;
  amazonLastRespondedAt: Dayjs | undefined;
  cgLastRespondedAt: Dayjs | undefined;
  amazonCaseManager: string;
  stage: string;
  submissionAmount: number | undefined;
  submissionDate: Dayjs | undefined;
  submissionCount: number;
  alignedBalanceAmount: number | undefined;
  alignedBalanceDate: Dayjs | undefined;
  rcaEndDate: Dayjs | undefined;
  paidAmountBeforeOpenBalanceAlignment: number | undefined;
  validAmount: number | undefined;
  firstOfferAmount: number | undefined;
  firstOfferDate: Dayjs | undefined;
  secondOfferAmount: number | undefined;
  secondOfferDate: Dayjs | undefined;
  thirdOfferAmount: number | undefined;
  thirdOfferDate: Dayjs | undefined;
  settlementAcceptanceDateToAmazon: Dayjs | undefined;
  finalAmount: number | undefined;
  finalAmountDate: Dayjs | undefined;
  recoveryRateCalc: number | undefined;
  paymentIds: string;
  payoutDates: string;
  notes: string;
}

export interface EscalationPaymentDetail {
  id: string;
  caseItemId: string;
  invoiceId: string;
  invoiceNumber: string;
  disputedInvoiceId: string;
  paidAmount: number;
  paymentDate: Dayjs | undefined;
  c6InvoiceDate: Dayjs | undefined;
  // TODO: figure out details on Payout Type
  // payoutType: string;
  customerInvoiceAmount: string | null;
  paymentId: string | null;
  createdAt: string | null;
}

export interface AccrualDispute {
  id: string;
  disputeAmount: number | undefined;
  disputeDate: string;
  originalDisputeId: string;
  consolidatedDisputeId: string;
  disputeStatus: string;
  invoiceStatus: string;

  // resolution
  approvedAmount: string;
  disputeResolutionDate: string;
  paidAmount: number | undefined;
  reversalInvoiceNumber: string;
}

export interface AccrualRemittance {
  paidAmount: string;
  reversalInvoiceNumber: string;
  paymentId: string;
  paymentDate: string;
}

export interface AccrualWorkPacket extends WorkPacketBase {
  recoveryStream: RecoveryStream.Accruals;
  obarOption: string;
  disputes: AccrualDispute[];
  remittances: AccrualRemittance[];
  subType: string; // recovery stream sub type
  agreementId: string;
  invoiceId: string;
  invoiceDate: string;
  monitoringDate?: Dayjs;
  currency: string;
  invoiceAmount: string;
  obarSuggestedDisputeAmount: string;
  sumOfAllReversalPayments: string;
  recoveryRate: string;

  // pseudo-properties for custom grid columns
  _generateObar?: undefined;
}

export type WorkPacket = ChargebackWorkPacket | ShortageWorkPacket | AccrualWorkPacket;

export interface ServerChargebackResource {
  ASIN_ID: string;
  CHARGEBACK_ISSUE_ID: string;
  CHARGE_INVOICE_NUMBER: string;
  CREATE_DATE: string;
  FINANCIAL_CHARGE: string;
  ISSUE_SUB_TYPE_DESC: string;
  NOTES: string;
  PRODUCT_NAME: string;
  RECOVERY_STREAM_SUB_TYPE: string;
  REVERSAL_INVOICE: string;
  STATUS: string;
  STORE_NAME: string;
  VC_PO_ID: string;
  VENDOR_CODE: string;
  VENDOR_NAME: string;
  FREIGHT_TERMS: string;
  REVERSED_AMOUNT: string;
  DISPUTE_BY_DATE: string;
  INBOUND_SHIPMENT_DELIVERY_ISD_ID: string;
}

export interface ServerShortageResource {
  DUE_DATE: string;
  ID: string;
  INVOICE_AMOUNT: `${number}`;
  INVOICE_CREATE_DATE: string;
  INVOICE_DATE: string;
  INVOICE_ID: string;
  INVOICE_STATUS: string;
  PAID_AMOUNT: string | null;
  PAYEE: string;
  SHORTAGE_AMOUNT: string | null;
  STORE_NAME: string;
  VENDOR: string;
  SHORTAGES_OSSR_WORK_PACKETS?: ServerShortagesOssrWorkPacket[];
}

export interface ServerShortagesOssrWorkPacket {
  CASE_ITEM_ID: string;
  CREATED_AT: string;
  DISPUTED_INVOICE_ID: string;
  ID: string;
  PARENT_INVOICE_DATE: string;
  PARENT_INVOICE_PAYMENT_DUE_DATE: string;
  PAYEE: string;
  REMAINING_BALANCE_OPEN: number;
  SHORTAGE_ID: string;
  VENDOR: string;
}

export interface ServerAccrualResource {
  AGREEMENT_ID: string;
  AGREEMENT_TITLE: string;
  DISPUTES: ServerAccrualDispute[];
  ID: string;
  INVOICE_DATE: string;
  MONITORING_DATE: string;
  INVOICE_ID: string;
  INVOICE_PAYMENTS_OSSR?: ServerInvoicePaymentOssr;
  STORE: string;
  TOTAL_AMOUNT_OWED: number;
}

export interface ServerAccrualDispute {
  ID: string;
  ACCRUAL_ID: string;
  AMAZON_DISPUTES: ServerAmazonDispute | null;
  CUSTOMER_INVOICE_STATUS: string | null;
  CONSOLIDATED_DISPUTE_ID: string | null;
  CREATED_AT: string;
  DISPUTE_AMOUNT: string | null;
  DISPUTE_ID: string | null;
  PAID_AMOUNT: string | null;
  REVERSAL_INVOICE_NUMBER: string | null;
}

export interface ServerInvoicePaymentOssr {
  AMOUNTPAID: string;
  DESCRIPTION: string;
  INVOICEAMOUNT: string;
  INVOICECURRENCY: string;
  INVOICEDATE: string;
  INVOICENUMBER: string;
  NATIVE_AMOUNTPAID: string;
  NATIVE_INVOICEAMOUNT: string;
  NATIVE_INVOICEDATE: string;
  NATIVE_REMAININGAMOUNT: string;
  NATIVE_TERMSDISCOUNTTAKEN: string;
  NATIVE_WITHOLDINGAMOUNT: string;
  PAYMENTNUMBER: string;
  REMAININGAMOUNT: string;
  STORE: string;
  TERMSDISCOUNTTAKEN: string;
  VENDOR_NAME: string;
  WITHOLDINGAMOUNT: string;
}

export interface ServerAccrualRemittance {
  UUID: string;
  ACCOUNT_ID: string;
  FILENAME: string;
  MARKETPLACE: string;
  INVOICE_DISPUTED: string;
  RECOVERED_AMOUNT: number;
  REPAID_INVOICE_NUMBER: string;
  PAYMENT_NUMBER: string;
  PAYMENT_DATE: string;
  CURRENCY: string;
  STATUS: string;
  INVOICED_AT: string | null;
  CREATED_AT: string;
  REVERSED_AT: string | null;
}

export type ServerWorkPacketResource = ServerChargebackResource | ServerShortageResource | ServerAccrualResource;

/** Server response for work packet */
export interface ServerWorkPacketBase<R extends ServerWorkPacketResource = ServerWorkPacketResource> {
  ACCOUNT: {
    ACTIVATION_DATE: string;
    ID: number;
    SCOPES: unknown[];
    SHORTAGE_LAG: string;
    STORE_NAME: string;
    TECH_USER: string;
    VC_FILING_USER: string | null;
    VENDOR_ID: string;
    VENDOR: ServerVendor;
    ACCOUNT_CONFIG: {
      ACCOUNT_ID: number;
      AMAZON_DISPUTE_FLG: number;
      CB_YEARS: string;
      CHARGEBACKS_STATUS: number;
      CLIENT_REGION: string;
      CLIENT_URL: string;
      COOP_MONTHS: number;
      COOP_STATUS: number;
      CREATED: string;
      CUSTOM_BRANDING: string;
      DATA_PLATFORM_EXTRACTION: boolean;
      DATA_PULL_YEARS: string;
      DISPUTE_YEARS: string;
      ENTITIES: string;
      ID: number;
      INSTANCEID: string;
      INV_LINE_LEVEL_DETAIL_STATUS?: unknown;
      IS_ACTIVE: number;
      IS_DISPUTE: boolean;
      PO_MONTHS: number;
      PO_STATUS: number;
      REMITTANCE_YEARS: string;
      REMMIT_STATUS: number;
      SHORTAGES_STATUS: number;
      SH_YEARS: string;
      VC_ID: string;
      VENDOR_NAME: string;
      WHITE_LABELED_ACCOUNT: string;
    } | null;
  };
  CHANGELOGS: ServerChangeLogEntry[];
  ACCOUNT_ID: number;
  STORE_NAME: string;
  CREATED_AT: string;
  CURRENT_ACTION: string;
  NOTES: string;
  SYSTEM_NOTES: string;
  PACKET_ID: string;
  PACKET_OWNER: {
    ID: string;
    EMAIL: string;
    FIRST_SEEN_AT: string;
    LAST_SEEN_AT: string;
    NAME: string;
    NICKNAME: string;
    STATUS: string;
    PROFILE_PIC: string;
  };
  PACKET_STAGE: string;
  RESOURCE_ID: string;
  VALID: boolean;
  IS_HISTORIC: boolean;
  RECOVERY_STREAM: ServerStream;
  RESOURCE: R;
  WORK_PACKET_PROPERTIES: (ServerWorkPacketProperty | ServerWorkPacketDisputeIdProperty)[];
  CASE_ITEM_COUNT: number;
  CASE_ITEMS: ServerCaseItem[];
}

export interface ServerVendor {
  AGREEMENT_SIGNED_DATE: string;
  BILLING_EMAIL: string | null;
  BILLING_ENTITY: string | null;
  BILLING_NAME: string | null;
  CURRENT_RECOVERY: string;
  ENGAGEMENT_FEE: string | null;
  FINAL_COMMISSION_RATE: string;
  INITIAL_COMMISSION_RATE: string;
  KICK_OFF: string;
  NOTES: string | null;
  PHONE: string | null;
  RECOVERY_THRESHOLD: string;
  REFERRALS: ServerVendorReferral[];
  SPECIFIC_POINT_OF_CONTACT_EMAIL: string;
  SPECIFIC_POINT_OF_CONTACT_NAME: string;
  TIER: VendorTier;
  VENDOR_CREATED: string;
  VENDOR_ID: number;
  VENDOR_NAME: string;
  VENDOR_TYPE: VendorType;
}

export enum VendorType {
  Standard = "Standard",
}

export enum VendorTier {
  SMB = "SMB",
  MidMarket = "Mid Market",
  Enterprise = "Enterprise",
}

export interface ServerVendorReferral {
  COMMISSION_ELIGIBILITY_END_AT: string;
  COMMISSION_RATE: number;
  CUSTOM_COMMISSION_RATE: string | null;
  PARTNER_ID: string;
  PARTNER_TYPE: string;
  PAYMENT_METHOD: string;
  REFERRAL_ID: string;
  REFERRAL_PARTNER_ID: number;
  REFERRAL_PARTNER_NAME: string;
}

export type ServerWorkPacketPropertyKey =
  | "PARENT_INVOICE_NUMBER"
  | "CHILD_INVOICE_NUMBER"
  | "DISPUTED_INVOICE_NUMBER"
  | "EVIDENCE_TYPE"
  | "EVIDENCE_ATTACHMENT"
  | "DISPUTE_AMOUNT"
  | "DISPUTE_ID"
  | "CLIENT_DISPUTED"
  // accruals:
  | "INVOICE_DATE"
  | "OBAR_OPTION"
  | "SUB_TYPE"
  | "TOTAL_AMOUNT_OWED"
  | "ARN"
  | "ASN";

export type ServerWorkPacketPropertyType = "string" | "number" | "date" | "float";

export interface ServerWorkPacketProperty {
  CREATED_AT: string;
  ID: string;
  KEY: ServerWorkPacketPropertyKey;
  TYPE: ServerWorkPacketPropertyType;
  VALUE: string;
  WORK_PACKET_ID: string;
}

export interface ServerAmazonDispute {
  APPROVED_AMOUNT: string;
  DISPUTE_DATE: string;
  DISPUTE_ID: string;
  DISPUTE_REASON: string;
  DISPUTE_STATUS: string;
  DISPUTE_TYPE: string;
  INVOICE_NUMBER: string;
  MARKETPLACE: string;
  STORE_NAME: string;
  TITLE: string;
  TOTAL_DISPUTED_AMOUNT: string;
  VENDOR_NAME: string;
}

export interface ServerWorkPacketDisputeIdProperty {
  CREATED_AT: string;
  ID: string;
  KEY: "DISPUTE_ID";
  TYPE: "string";
  VALUE: string;
  WORK_PACKET_ID: string;
  AMAZON_DISPUTE: ServerAmazonDispute;
}

export enum ServerStream {
  PQV = "PQV",
  PPV = "PPV",
  PRICE_CLAIMS = "PRICE_CLAIMS",
  ACCRUAL = "ACCRUAL",
  CHARGEBACKS = "CHARGEBACKS",
}

export interface ServerChargebackWorkPacket extends ServerWorkPacketBase {
  RECOVERY_STREAM: ServerStream.CHARGEBACKS;
  RESOURCE: ServerChargebackResource;
}

export interface ServerShortageWorkPacket extends ServerWorkPacketBase {
  RECOVERY_STREAM: ServerStream.PQV | ServerStream.PPV;
  RESOURCE: ServerShortageResource;
}

export interface ServerAccrualWorkPacket extends ServerWorkPacketBase {
  RECOVERY_STREAM: ServerStream.ACCRUAL;
  RESOURCE: ServerAccrualResource;
  REMITTANCES?: ServerAccrualRemittance[];
}

export type ServerWorkPacket = ServerChargebackWorkPacket | ServerShortageWorkPacket | ServerAccrualWorkPacket;

export interface WorkPacketServerResponse<T extends ServerWorkPacket> {
  pages: number;
  total_records: number;
  work_packets: T[];
}

export interface WorkPacketTypeMap {
  [WorkPacketType.CHARGEBACKS]: ChargebackWorkPacket;
  [WorkPacketType.SHORTAGES]: ShortageWorkPacket;
  [WorkPacketType.ACCRUALS]: AccrualWorkPacket;
}

export interface ServerWorkPacketTypeMap {
  [WorkPacketType.CHARGEBACKS]: ServerChargebackWorkPacket;
  [WorkPacketType.SHORTAGES]: ServerShortageWorkPacket;
  [WorkPacketType.ACCRUALS]: ServerAccrualWorkPacket;
}

/**
 * Represents a unified summary of work packets that can encompass any of the specific packet summary responses.
 */
export interface WorkPacketsSummaryAggregated {
  totalWorkPackets: number;
  totalStores?: number;
  totalRedFlags?: number;
  recoverableAmount?: {
    total: string;
    pending?: string;
    approved?: string;
  };
  workPackets?: {
    action: WorkPacketActionName;
    amount: number;
  }[];
  byStatus?: WorkPacketsSummaryByStatus[];
}

export interface WorkPacketsSummaryByStatus {
  status: string;
  count: number;
  remainingOpenBalance: number;
  financialCharge: number;
  chargebackCreateDate: string;
}

export interface ServerEscalationCaseDetails {
  APPROVED_AMOUNT: number;
  APPROVED_DATE: string;
  MONITORED_DATE: string;
}

export interface ChangeLogUpdates {
  PACKET_OWNER_ID?: string;
  CURRENT_ACTION?: string;
  NOTES?: string;
  SYSTEM_NOTES?: string;
  VALID?: string;
  CASE_ID?: string;
  AMAZON_CASE_ID?: string;
  CASE_OWNER_ID?: string;
  CASE_FILING_USER?: string;
  STATUS?: string;
  CHARGEBACKS_ESCALATION_CASE_DETAILS?: ServerEscalationCaseDetails;
  SHORTAGES_ESCALATION_CASE_DETAILS?: ServerEscalationCaseDetails;
  WORK_PACKETS?: string[];
  DISPUTE_ID?: string;
  CONSOLIDATED_DISPUTE_ID?: string;
  CORRESPONDENCE_WORK_FLAG?: string;
}

export interface ServerChangeLogEntry {
  ACTION: "ASSIGNED" | "IDENTIFIED" | "UPDATED" | "UNASSIGNED" | "LINKED" | "UNLINKED" | "FLAGGED" | "UNFLAGGED";
  ID: string;
  PACKET_OWNER?: ServerUser;
  RECORDED_AT: string;
  UPDATES: ChangeLogUpdates;
  USER?: ServerUser;
  WORK_PACKET_ID: string;
  CASE_ID?: string;
  CASE_OWNER?: ServerUser;
  CASE_FILING_USER?: string;
}

export interface ChangelogEntry {
  time: string;
  originalDate: Date;
  user: string;
  avatarUrl?: string;
  action: string;
  packetId?: string;
  to?: string;
  state?: string;
  nextAction?: string;
  isSystem?: boolean;
}

export interface ChangelogDate {
  date: string;
  entries: ChangelogEntry[];
}

export interface CommandCenterEntry {
  count: number;
  red_flag_count: number;
  total_amount: string | number; // server is known to return whole integers as numbers
  expired_count?: number;
  expired_amount?: string | number; // server is known to return whole integers as numbers
  child?: CommandCenterEntryMap;
}

export type CommandCenterEntryMap = Record<string, CommandCenterEntry>;

export interface CommandCenterResponse {
  data: CommandCenterEntryMap;
  message: string;
  status: number;
}

export interface CommandCenterGridRow {
  hierarchy: string[];
  openCount: number;
  openAmount: string;
  expiredCount?: number;
  expiredAmount?: string;
  redFlags: number;
  id: string;
}

export interface ServerModelAttachment {
  ATTACHMENT_ID: string;
  CREATED_AT: string;
  ID: string;
  MODEL: string;
  MODEL_ID: string;
  ATTACHMENT: {
    ID: string;
    TYPE: string;
    OBJECT_KEY: string;
    FILE_NAME: string;
    CREATED_AT: string;
  };
}

export interface WorkPacketDisputesInfo {
  client_region: string;
  invoice_ids: string[];
  dispute_ids: string[];
  manual_filing_user: string;
  store_name: string;
  child_invoices: string[];
  asns: string[];
  payee_codes: string[];
  submission_amount: number;
  invoice_end_due_date: string;
  invoice_start_due_date: string;
  po_numbers: string[];
}

export interface ServerChargebackRemittance {
  UUID: string;
  ACCOUNT_ID: string;
  FILENAME: string;
  MARKETPLACE: string;
  ISSUE_ID: string;
  FINANCIAL_CHARGE: number;
  ISSUE_TYPE: string;
  CREATION_DATE: string;
  CHARGE_INVOICE_NUMBER: string;
  REVERSAL_INVOICE_NUMBER: string;
  NOTES: string;
  AMAZON_STATUS: string;
  PAYMENT_ID: string;
  PAYMENT_DATE: string;
  CREATED_AT: string;
  REVERSED_AT: string;
  CURRENCY: string;
  STATUS: string;
  INVOICED_AT: string;
  WORK_PACKET_ID: string;
  STAGE: string;
  STAGE_NUMBER: number;
}

export interface ChargebackRemittance {
  paymentStatus: string;
  stage: string;
  remittanceInvoiceId: string;
  paymentId: string;
  paymentAmount: string;
  paymentDate: string;
  c6InvoicesClientAmount: string;
  c6InvoicedDate: string;
}

export interface ServerShortageRemittance {
  UUID: string;
  ACCOUNT_ID: string;
  FILENAME: string;
  MARKETPLACE: string;
  DISPUTE_ID: string;
  DISPUTE_TYPE: string;
  DISPUTE_REASON: string;
  TITLE: string;
  DISPUTE_DATE: string;
  DISPUTE_STATUS: string;
  TOTAL_DISPUTED_AMOUNT: number;
  APPROVED_AMOUNT: number;
  PAID_AMOUNT: number;
  INVOICE_NUMBER: string;
  PAYMENT_NUMBER: string;
  PAYMENT_DATE: string;
  CREATED_AT: string;
  REVERSED_AT: string;
  CURRENCY: string;
  STATUS: string;
  INVOICED_AT: string;
  WORK_PACKET_ID: string;
  STAGE: string;
  STAGE_NUMBER: number;
}

export interface ShortageRemittance {
  paymentStatus: string;
  stage: string;
  remittanceInvoiceId: string;
  paymentId: string;
  paymentAmount: string;
  paymentDate: string;
  c6InvoicesClientAmount: string;
  c6InvoicedDate: string;
}
