import { Box } from "@mui/material";
import { FC } from "react";
import { CaseType } from "src/pages/UserDashboard/WorkPackets/Cases/case-types";
import { CaseItemsTable } from "src/pages/UserDashboard/WorkPackets/Cases/tabs/CaseDetails/CaseItemsGrid";

interface SettlementCaseItemsProps {
  caseId: string;
  caseType: CaseType;
}

export const SettlementCaseItems: FC<SettlementCaseItemsProps> = ({ caseId, caseType }) => {
  return (
    <Box gridRow={"2"}>
      <CaseItemsTable caseId={caseId} caseType={caseType} />
    </Box>
  );
};
