import { FC } from "react";
import { DatePickerField } from "components/UI/Form";
import { Field } from "formik";
import { Box, TextField } from "@mui/material";
import { CaseDetailsKeys } from "../../mappers/mapServerToLocalCase";

// NOTE: to display formatted currency strings, the Field's component needs to as
// eg <Field as={TextField} ... /> NOT <Field component={TextField} ... />
// Don't know why

export const ChargebackEscalationInfo: FC = () => {
  return (
    <Box
      display="grid"
      rowGap={3}
      columnGap={2}
      sx={{
        gridTemplateColumns: {
          xs: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        },
      }}
    >
      <Field
        name={CaseDetailsKeys.CaseCreationDate}
        component={DatePickerField}
        variant="outlined"
        size="small"
        label="Case Created Date"
        placeholder="Case Created Date"
        disabled
      />
      <Field
        name={CaseDetailsKeys.DisputedAmount}
        label="Financial Charge"
        placeholder="Financial Charge"
        as={TextField}
        variant="outlined"
        size="small"
        disabled
      />
      <Field
        name={CaseDetailsKeys.ApprovedAmount}
        as={TextField}
        variant="outlined"
        size="small"
        label="Approved Amount"
        placeholder="Approved Amount"
        disabled
      />
      <Field
        name={CaseDetailsKeys.ApprovedDate}
        component={DatePickerField}
        label="Resolution Date"
        placeholder="Resolution Date"
        variant="outlined"
        size="small"
        disabled
      />
      <Field
        name={CaseDetailsKeys.PaidAmount}
        as={TextField}
        label="Paid Amount"
        placeholder="Paid Amount"
        variant="outlined"
        size="small"
      />
    </Box>
  );
};
