import { TextField } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { type FC, useEffect } from "react";
import { type ShortageWorkPacket, StagelessAction } from "src/types/work-packets";
import { FormFieldsGroup } from "../components";
import { createFieldFn } from "../field-util";
import { CheckboxField } from "components/UI/Form";
import { CurrencyField } from "components/UI/Form/CurrencyField.tsx";

const getField = createFieldFn<ShortageWorkPacket>();

export const DisputeInformation: FC = () => {
  const { initialValues, values, setFieldValue, errors } = useFormikContext<ShortageWorkPacket>();

  useEffect(() => {
    if (
      values.clientRegion !== "US" &&
      initialValues.clientDisputed !== values.clientDisputed &&
      values.isHistoric &&
      values.currentAction !== StagelessAction.ReadyForOssr
    ) {
      setFieldValue("currentAction", StagelessAction.ReadyForOssr);
    }
  }, [initialValues, values, setFieldValue]);

  return (
    <FormFieldsGroup title="Dispute Information" withDivider id="dispute-information">
      {values.clientRegion == "US" && values.isHistoric ? (
        <Field
          value={"N/A"}
          as={TextField}
          variant="outlined"
          placeholder="Dispute ID"
          size="small"
          label="Dispute ID"
          disabled
        />
      ) : (
        <Field
          name={getField("disputeId")}
          as={TextField}
          variant="outlined"
          placeholder="Dispute ID"
          size="small"
          label="Dispute ID"
          disabled={initialValues.disputeId}
        />
      )}

      <Field type="checkbox" name={getField("clientDisputed")} component={CheckboxField} label="Client Disputed" />

      <Field
        name={getField("vcParentInvoiceId")}
        as={TextField}
        variant="outlined"
        placeholder="VC Parent Invoice ID"
        size="small"
        label="VC Parent Invoice ID"
        disabled
      />

      <Field
        name={getField("vcDisputedInvoiceId")}
        as={TextField}
        variant="outlined"
        placeholder="VC Disputed Invoice ID"
        size="small"
        label="VC Disputed Invoice ID"
        error={!!errors.vcDisputedInvoiceId}
        helperText={errors.vcDisputedInvoiceId}
        disabled={(initialValues.clientRegion == "US" && initialValues.isHistoric) || initialValues.disputeId}
      />

      <Field
        name={getField("invoiceDate")}
        as={TextField}
        variant="outlined"
        placeholder="Invoice Date"
        size="small"
        label="Invoice Date"
        disabled
      />

      <Field
        name={getField("invoiceDueDate")}
        as={TextField}
        variant="outlined"
        placeholder="Invoice Due Date"
        size="small"
        label="Invoice Due Date"
        disabled
      />

      <Field
        name={getField("shortageLag")}
        as={TextField}
        variant="outlined"
        placeholder="Shortage Lag"
        size="small"
        label="Shortage Lag"
        disabled
      />

      <Field
        name={getField("disputeByDate")}
        as={TextField}
        variant="outlined"
        placeholder="Dispute by Date"
        size="small"
        label="Dispute by Date"
        disabled
      />

      <Field
        name={getField("disputeAmount")}
        component={CurrencyField}
        variant="outlined"
        placeholder="Dispute Amount"
        size="small"
        label="Dispute Amount"
        disabled={(initialValues.clientRegion == "US" && initialValues.isHistoric) || initialValues.disputeId}
      />

      <Field
        name={getField("disputeApprovedAmount")}
        as={TextField}
        variant="outlined"
        placeholder="Dispute - Approved Amount"
        size="small"
        label="Dispute - Approved Amount"
        disabled
      />

      <Field
        name={getField("disputeCreatedAt")}
        as={TextField}
        variant="outlined"
        placeholder="Dispute - Created At"
        size="small"
        label="Dispute - Created At"
        disabled
      />

      <Field
        name={getField("disputeResolvedAt")}
        as={TextField}
        variant="outlined"
        placeholder="Dispute - Resolved At"
        size="small"
        label="Dispute - Resolved At"
        disabled
      />
    </FormFieldsGroup>
  );
};
