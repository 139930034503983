import { Box } from "@mui/material";
import { type GridRenderCellParams } from "@mui/x-data-grid";
import { type FC } from "react";
import { getStatusOption } from "src/pages/UserDashboard/Cases/api/constants.ts";

export const CaseActionDisplay: FC<GridRenderCellParams> = ({ value }) => {
  const { color, title } = getStatusOption(value);
  return (
    <Box display="flex" alignItems="center">
      {!!color && <Box width="12px" minWidth="12px" height="12px" bgcolor={color} borderRadius="2px" />}
      <Box ml={1}>{title}</Box>
    </Box>
  );
};
