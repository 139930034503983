import { type Dayjs } from "dayjs";
import { dateOrUndefined } from "src/utils/date-parse";
import { ShortagesEscalationCaseItemStatus } from "../api/constants";

export const currencyFormatter = new Intl.NumberFormat(undefined, {
  currency: "USD",
  style: "currency",
  currencyDisplay: "symbol",
  currencySign: "accounting",
  signDisplay: "never",
});

export const formatTitleCase = (value: string): string => {
  return value
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const parseMaybeCurrency = (value: string | number | null | undefined): number | undefined => {
  if (typeof value === "string") return parseMaybeCurrency(parseFloat(value));
  if (value === null || value === undefined) return undefined;
  if (Number.isNaN(value)) return undefined;
  return Math.abs(value);
};

export const formatMaybeCurrency = (value: string | number | null | undefined): string => {
  if (value === null || value == undefined) return "";
  if (typeof value === "string") return formatMaybeCurrency(parseFloat(value));
  return currencyFormatter.format(value);
};

export function formatDateToISO(date?: Date): string | undefined {
  return date?.toISOString().split("T")[0] ?? undefined;
}

export function valueOrUndefined<T>(value: T): NonNullable<T> | undefined {
  if (value == null) {
    return undefined;
  }
  return value;
}

export function valueOrBlankString<T>(value: T): T | string {
  if (value) {
    return value;
  }
  return "";
}

export function valueOrZero(value?: number | null): number {
  if (value) {
    return value;
  }
  return 0;
}

export const dateOrUndefinedFilterByApprovedStatus = (
  status?: ShortagesEscalationCaseItemStatus,
  date?: string | null,
): Dayjs | undefined => {
  if (!status) return undefined;

  return [
    ShortagesEscalationCaseItemStatus.APPROVED.valueOf(),
    ShortagesEscalationCaseItemStatus.PARTIALLY_APPROVED.valueOf(),
  ].includes(status)
    ? dateOrUndefined(date)
    : undefined;
};
