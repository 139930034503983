import * as React from "react";
import { FC } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FieldProps } from "formik";
import { FormControl } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface Option {
  value: string;
  label: string;
}
type ListOptionProps = React.HTMLAttributes<HTMLLIElement> & { key: string };

interface MultiCheckboxFieldProps extends FieldProps<boolean[]> {
  label: string;
  options: Option[];
  setFieldValue: (field: string, value: string[], shouldValidate?: boolean) => void;
  disabled?: boolean;
}

const renderOption = (props: ListOptionProps, option: Option, state: { selected: boolean }) => (
  <li {...props} className={`autocomplete-menu-item ${props.className}`} key={props.key}>
    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={state.selected} />
    <span className="autocomplete-menu-item__text">{typeof option === "string" ? option : option.label}</span>
  </li>
);

/**
 * MultiCheckboxField component integrates Material-UI's autocomplete component with Formik to create a dropdown menu
 * with multiple checkboxes
 *
 * @param field - Formik field prop, automatically injected by Formik.
 * @param name - The name of the select field.
 * @param label - The label for the select field.
 * @param disabled - Whether the select field is disabled.
 * @param options - The options for the select dropdown.
 * @param onChange - The function to call when the select field value changes.
 */
const MultiCheckboxField: FC<MultiCheckboxFieldProps> = ({ label, disabled, options, field, setFieldValue }) => {
  return (
    <FormControl size="small" fullWidth disabled={disabled}>
      <Autocomplete
        multiple
        size="small"
        options={options}
        disableCloseOnSelect
        getOptionLabel={option => option.label}
        renderInput={params => <TextField {...params} variant="outlined" label={label} />}
        renderOption={renderOption}
        onChange={(_event, newOptions: Option[]) => {
          setFieldValue(
            field.name,
            newOptions.map(option => option.value),
          );
        }}
      />
    </FormControl>
  );
};

export default MultiCheckboxField;
